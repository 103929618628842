import { Component, Input, OnInit } from '@angular/core';
import { ChartTotalWithIcon } from '../models/chart-total-with-icon';
import { ChartColumnInterface } from '../models/chart-column.interface';

@Component({
  selector: 'sp-bar-chart-row',
  templateUrl: './bar-chart-row.component.html',
  styleUrls: ['./bar-chart-row.component.scss']
})
export class BarChartRowComponent implements OnInit {
  @Input() columns: ChartColumnInterface[];
  @Input() label: string;
  @Input() numberOfSteps: number;
  @Input() stepSize: number;
  @Input() totals?: ChartTotalWithIcon[];

  constructor() { }

  ngOnInit() {
  }
}
