import { createAction, props, union } from '@ngrx/store';
// tslint:disable: max-line-length

export const ADD_SINGLE = createAction('[Access Rights] Update Single', props<{ key: string }>());
export const CHECK_ACCESS_RIGHTS = createAction('[Access Rights] Check Access Rights', props<{ key: string }>());
export const ERROR = createAction('[Access Rights] Error', props<{ errorMessage: string, errorType: string }>());
export const RESET = createAction('[Access Rights] Reset', props<{}>());
export const REMOVE_SINGLE = createAction('[Access Rights] Remove Single', props<{ key: string }>());

const all = union({
    ADD_SINGLE,
    ERROR,
    CHECK_ACCESS_RIGHTS,
    RESET,
    REMOVE_SINGLE
});
export type Actions = typeof all;
