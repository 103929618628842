import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepotSelectorComponent } from './depot-selector.component';
import { MatTabsModule } from '@angular/material';

@NgModule({
  declarations: [
    DepotSelectorComponent,
  ],
  imports: [
    CommonModule,
    MatTabsModule,
  ],
  exports: [
    DepotSelectorComponent,
  ]
})
export class DepotSelectorModule { }
