import * as actions from './inspection.actions';
import { AppState } from '..';
import { createReducer } from '@ngrx/store';

const thisReducer = (state: AppState): InspectionState => state.inspection;

export interface InspectionState {
}

export const initialState: InspectionState = {
};

const handleReducer = createReducer(
    initialState
);

export function reducer(state: InspectionState, action: actions.InspectionsActions): InspectionState {
    return handleReducer(state, action);
}
