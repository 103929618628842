import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { ContainerUtil } from '../container-util';

export class ContainerIdErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: any | null): boolean {
    // When the container number is 11 characters, show any errors
    return !!(control && control.invalid && (control.touched || control.value &&
      ContainerUtil.sanitizeContainerId(control.value).length >= 11 ));
  }
}

export interface AbstractControlWarn extends AbstractControl { warnings: any; }

// A form field validator for container ID inputs
export function containerIdValidator(c: AbstractControlWarn) {
  c.warnings = null;

  if (c.value) {

    // Sanitize input
    const container = ContainerUtil.sanitizeContainerId(c.value);

    if (container.length !== 11) { // Must be 11 characters
      return { length: container.length };
    } else if (!ContainerUtil.validPattern(container)) { // Must match regex pattern [A-Z]{3}U[0-9]{7}
      return { pattern: true };
    } else { // Checkdigit warning
      if (!ContainerUtil.validCheckdigit(container)) {
        c.warnings = { checkdigit: ContainerUtil.calcCheckdigit(container.substr(0, 10)) };
      }
    }
    return null;
  }
}

// A form field validator for container number inputs (7 numbers only)
export function validateContainerNumber(c: FormControl) {
  if (c.value) {
    const container = c.value.toString();
    // Must be 7 numbers
    if (container.length !== 7) {
      return { validContainerNumber: true };
    }
    return null;
  }
}
