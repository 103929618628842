import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import * as bookingStatsActions from '../../../store/booking-stats/booking-stats.actions';
import { BookingStatsSettingsService } from 'src/app/services/settings-booking-stats.service';

@Component({
  selector: 'app-booking-stats-filters',
  templateUrl: './booking-stats-filters.component.html',
  styleUrls: ['./booking-stats-filters.component.scss']
})
export class BookingStatsFiltersComponent implements OnInit {
  @Input() show: boolean;
  public formgroup: FormGroup;
  public get ctrlSplitFullEmpty(): FormControl { return this.formgroup.get('splitFullEmpty') as FormControl; }
  public get ctrlSplitImportExport(): FormControl { return this.formgroup.get('splitImportExport') as FormControl; }
  public get ctrlFull(): FormControl { return this.formgroup.get('showFull') as FormControl; }
  public get ctrlEmpty(): FormControl { return this.formgroup.get('showEmpty') as FormControl; }
  public get ctrlImport(): FormControl { return this.formgroup.get('showImport') as FormControl; }
  public get ctrlExport(): FormControl { return this.formgroup.get('showExport') as FormControl; }
  public get ctrlExportEmpty(): FormControl { return this.formgroup.get('showExportEmpty') as FormControl; }
  public get ctrlExportFull(): FormControl { return this.formgroup.get('showExportFull') as FormControl; }
  public get ctrlImportEmpty(): FormControl { return this.formgroup.get('showImportEmpty') as FormControl; }
  public get ctrlImportFull(): FormControl { return this.formgroup.get('showImportFull') as FormControl; }
  public get ctrlShowHistory(): FormControl { return this.formgroup.get('showHistory') as FormControl; }

  constructor(private readonly formBuilder: FormBuilder,
              private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit() {
    this.formgroup = this.formBuilder.group({
      splitFullEmpty: [true, []],
      splitImportExport: [true, []],
      showFull: [true, []],
      showEmpty: [true, []],
      showExport: [true, []],
      showImport: [true, []],
      showImportFull: [true, []],
      showExportFull: [true, []],
      showImportEmpty: [true, []],
      showExportEmpty: [true, []],
      showHistory: [false, []],
    });

    const filtersFromStorage = BookingStatsSettingsService.getFilters();
    if (filtersFromStorage) {
      this.formgroup.setValue({
        splitFullEmpty: filtersFromStorage.splitFullEmpty !== undefined ? filtersFromStorage.splitFullEmpty : true,
        splitImportExport: filtersFromStorage.splitImportExport !== undefined ? filtersFromStorage.splitImportExport : true,
        showFull: filtersFromStorage.showFull !== undefined ? filtersFromStorage.showFull : true,
        showEmpty: filtersFromStorage.showEmpty !== undefined ? filtersFromStorage.showEmpty : true,
        showExport: filtersFromStorage.showExport !== undefined ? filtersFromStorage.showExport : true,
        showImport: filtersFromStorage.showImport !== undefined ? filtersFromStorage.showImport : true,
        showImportFull: filtersFromStorage.showImportFull !== undefined ? filtersFromStorage.showImportFull : true,
        showExportFull: filtersFromStorage.showExportFull !== undefined ? filtersFromStorage.showExportFull : true,
        showImportEmpty: filtersFromStorage.showImportEmpty !== undefined ? filtersFromStorage.showImportEmpty : true,
        showExportEmpty: filtersFromStorage.showExportEmpty !== undefined ? filtersFromStorage.showExportEmpty : true,
        showHistory: filtersFromStorage.showHistory !== undefined ? filtersFromStorage.showHistory : false,
      });
      this.appStore.dispatch(bookingStatsActions.UPDATE_FILTERING({ filters: filtersFromStorage }));
    }

    this.listenToSplitFullEmpty();
    this.listenToSplitImportExport();
    this.listenToFull();
    this.listenToEmpty();
    this.listenToExport();
    this.listenToImport();
    this.listenToOthers();
  }

  private listenToSplitFullEmpty(): void {
    this.ctrlSplitFullEmpty.valueChanges.subscribe(() => {
      this.ctrlFull.setValue(true);
      this.ctrlEmpty.setValue(true);
      this.updateFilters();
    });
  }

  private listenToSplitImportExport(): void {
    this.ctrlSplitImportExport.valueChanges.subscribe(() => {
      this.ctrlExport.setValue(true);
      this.ctrlImport.setValue(true);
      this.updateFilters();
    });
  }

  private listenToEmpty(): void {
    this.ctrlEmpty.valueChanges.subscribe(selected => {
      if (this.ctrlExport.value) {
        this.ctrlExportEmpty.setValue(selected);
      }
      if (this.ctrlImport.value) {
        this.ctrlImportEmpty.setValue(selected);
      }
      this.updateFilters();
    });
  }

  private listenToFull(): void {
    this.ctrlFull.valueChanges.subscribe(selected => {
      if (this.ctrlExport.value) {
        this.ctrlExportFull.setValue(selected);
      }
      if (this.ctrlImport.value) {
        this.ctrlImportFull.setValue(selected);
      }
      this.updateFilters();
    });
  }

  private listenToExport(): void {
    this.ctrlExport.valueChanges.subscribe(selected => {
      if (this.ctrlEmpty.value) {
        this.ctrlExportEmpty.setValue(selected);
      }
      if (this.ctrlFull.value) {
        this.ctrlExportFull.setValue(selected);
      }
      this.updateFilters();
    });
  }

  private listenToImport(): void {
    this.ctrlImport.valueChanges.subscribe(selected => {
      if (this.ctrlEmpty.value) {
        this.ctrlImportEmpty.setValue(selected);
      }
      if (this.ctrlFull.value) {
        this.ctrlImportFull.setValue(selected);
      }
      this.updateFilters();
    });
  }

  private listenToOthers(): void {
    this.ctrlExportEmpty.valueChanges.subscribe(() => {
      this.updateFilters();
    });
    this.ctrlExportFull.valueChanges.subscribe(() => {
      this.updateFilters();
    });
    this.ctrlImportEmpty.valueChanges.subscribe(() => {
      this.updateFilters();
    });
    this.ctrlImportFull.valueChanges.subscribe(() => {
      this.updateFilters();
    });
    this.ctrlShowHistory.valueChanges.subscribe(() => {
      this.updateFilters();
    });
  }

  private updateFilters(): void {
    this.appStore.dispatch(bookingStatsActions.UPDATE_FILTERING({ filters: {
      splitFullEmpty: this.ctrlSplitFullEmpty.value,
      splitImportExport: this.ctrlSplitImportExport.value,
      showFull: this.ctrlFull.value,
      showEmpty: this.ctrlEmpty.value,
      showExport: this.ctrlExport.value,
      showImport: this.ctrlImport.value,
      showImportFull: this.ctrlImportFull.value,
      showExportFull: this.ctrlExportFull.value,
      showImportEmpty: this.ctrlImportEmpty.value,
      showExportEmpty: this.ctrlExportEmpty.value,
      showHistory: this.ctrlShowHistory.value
    }}));
  }

}
