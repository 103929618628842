import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { STOR_KEY_BOOKING_STATS_FILTERS } from './models/storage-keys';
import { BookingStatsFiltersInterface } from '../booking-stats/models/booking-stats-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class BookingStatsSettingsService {
    public static setFilters(filters: BookingStatsFiltersInterface): void {
        SettingsService.setSetting(STOR_KEY_BOOKING_STATS_FILTERS, JSON.stringify(filters));
    }

    public static getFilters(): BookingStatsFiltersInterface {
        return JSON.parse(SettingsService.getSetting(STOR_KEY_BOOKING_STATS_FILTERS));
    }
}
