export class ContainerUtil {

  // Calculate checkdigit
  static calcCheckdigit(containerId): string {

    const mapping = {
      0: 0 , 1: 1 , 2: 2 , 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9,
      A: 10, B: 12, C: 13, D: 14, E: 15, F: 16, G: 17, H: 18, I: 19, J: 20, K: 21, L: 23, M: 24,
      N: 25, O: 26, P: 27, Q: 28, R: 29, S: 30, T: 31, U: 32, V: 34, W: 35, X: 36, Y: 37, Z: 38
    };

    let total = 0;

    for (let i = 0; i < containerId.length; i++) {
      const cChar = containerId.substr(i, 1);
      const value = mapping[cChar];
      const multiplier = Math.pow(2, i);
      total = total + (value * multiplier);
    }

    let outcome = total % 11;
    if (outcome === 10) {
      outcome = 0;
    }

    return outcome.toString();
  }

  // check if checkdigit checks out....
  static validCheckdigit(container): boolean {
    return (this.calcCheckdigit(container.substr(0, 10)) === container.substr(10, 1));
  }

  // Checks if the input is a valid container number pattern (No checkdigit check)
  static validPattern(containerId) {
    if (containerId.match('[A-Z]{4}[0-9]{7}')) {
      return true;
    } else {
      return false;
    }
  }

  // Cleanup container input. Remove non alphanumeric characters like spaces and slashes. Convert to Uppercase
  static sanitizeContainerId(dirtyInput) {
    if (dirtyInput) {
      return dirtyInput.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    } else {
      return dirtyInput;
    }
  }

}
