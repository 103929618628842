import { Component, OnInit } from '@angular/core';
import { ContainerInfoService } from '../services/jsdo-data/container-info.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-timeslot-terminal-select',
  templateUrl: './timeslot-terminal-select.component.html',
  styleUrls: ['./timeslot-terminal-select.component.scss']
})
export class TimeslotTerminalSelectComponent implements OnInit {

  terminalList = [];

  constructor(private containerService:ContainerInfoService,
              private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.setLabel('Terminal select');
    this.navigationService.enableBackButton(true);

    this.containerService.getBackendSettings().then((settings: any) => {
      if (settings.timeslotDepotList) {
        let list = settings.timeslotDepotList.split(",");
        for (let i = 0; i < list.length; i = i + 2) {
            let terminal = {
              depotcode: list[i], 
              label: list[i + 1]
            }
            this.terminalList.push(terminal);
        }

      }       
    });


    
  }

}
