import * as actions from './equipment-stats-detail.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';

const thisReducer = (state: AppState): EquipmentStatsDetailState => state.equipmentStatsDetail;

export interface EquipmentStatsDetailState {
    data: any[];
    error: { errorMessage: string, errorType: string };
    loading: boolean;
    receiveTime: string;
}

export const initialState: EquipmentStatsDetailState = {
    data: [],
    error: null,
    loading: false,
    receiveTime: null,
};

const handleReducer = createReducer(
    initialState,
    on(actions.ERROR, (state: EquipmentStatsDetailState, { errorMessage, errorType }): EquipmentStatsDetailState => {
        return {
            ...state,
            data: [],
            error: { errorMessage, errorType },
            loading: false,
            receiveTime: null,
        };
    }),
    on(actions.RESET, (state: EquipmentStatsDetailState): EquipmentStatsDetailState => {
        return {
            ...state,
            data: [],
            error: null,
            loading: false,
            receiveTime: null,
        };
    }),
    on(actions.LOAD_EQUIPMENT_STATS, (state: EquipmentStatsDetailState): EquipmentStatsDetailState => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.RECEIVED_EQUIPMENT_STATS, (state: EquipmentStatsDetailState, { equipmentStatsData }): EquipmentStatsDetailState => {
        return {
            ...state,
            data: equipmentStatsData,
            loading: false,
            receiveTime: new Date().toISOString(),
        };
    }),
);

export function reducer(state: EquipmentStatsDetailState, action: actions.EquipmentStatsDetailActions): EquipmentStatsDetailState {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: EquipmentStatsDetailState) => state
);
