import * as actions from './equipment-stats.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';
import { EquipmentStatsFiltersInterface } from 'src/app/equipment-stats/models/equipment-stats-filters.interface';
import { EquipmentStatsSettingsService } from 'src/app/services/settings-equipment-stats.service';

const thisReducer = (state: AppState): EquipmentStatsState => state.equipmentStats;

export interface EquipmentStatsState {
    data: any[];
    error: { errorMessage: string, errorType: string };
    loading: boolean;
    receiveTime: string;
    filters: EquipmentStatsFiltersInterface;
}

export const initialState: EquipmentStatsState = {
    data: null,
    error: null,
    loading: false,
    receiveTime: null,
    filters: {
        splitFullEmpty: true,
        splitImportExport: true,
        splitPerMachine: true,
    }
};

const handleReducer = createReducer(
    initialState,
    on(actions.ERROR, (state: EquipmentStatsState, { errorMessage, errorType }): EquipmentStatsState => {
        return {
            ...state,
            data: null,
            error: { errorMessage, errorType },
            loading: false,
            receiveTime: null,
        };
    }),
    on(actions.RESET, (state: EquipmentStatsState): EquipmentStatsState => {
        return {
            ...state,
            data: null,
            error: null,
            loading: false,
            receiveTime: null,
        };
    }),
    on(actions.LOAD_EQUIPMENT_STATS, (state: EquipmentStatsState): EquipmentStatsState => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.RECEIVED_EQUIPMENT_STATS, (state: EquipmentStatsState, { equipmentStatsData }): EquipmentStatsState => {
        return {
            ...state,
            data: equipmentStatsData,
            loading: false,
            receiveTime: new Date().toISOString(),
        };
    }),
    on(actions.UPDATE_FILTERING, (state: EquipmentStatsState, {
        filters
    }): EquipmentStatsState => {
        EquipmentStatsSettingsService.setFilters(filters);
        return {
            ...state,
            filters
        };
    }),
);

export function reducer(state: EquipmentStatsState, action: actions.EquipmentStatsActions): EquipmentStatsState {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: EquipmentStatsState) => state
);
