import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { STOR_KEY_EQUIPMENT_STATS_FILTERS } from './models/storage-keys';
import { EquipmentStatsFiltersInterface } from '../equipment-stats/models/equipment-stats-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class EquipmentStatsSettingsService {
    public static setFilters(filters: EquipmentStatsFiltersInterface): void {
        SettingsService.setSetting(STOR_KEY_EQUIPMENT_STATS_FILTERS, JSON.stringify(filters));
    }

    public static getFilters(): EquipmentStatsFiltersInterface {
        return JSON.parse(SettingsService.getSetting(STOR_KEY_EQUIPMENT_STATS_FILTERS));
    }
}
