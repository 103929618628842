import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { progress } from '../../progress/progress.jsdo';

import { JsdoSessionService } from '../../services/jsdo-session.service';
import { SettingsService } from '../../services/settings.service';
import { NavigationService } from '../../services/navigation.service';
import { DialogService } from '../../services/dialog.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private login;
  private password;
  currentLogin;

  constructor(private jsdoSessionService: JsdoSessionService,
              private settingsService: SettingsService,
              private location: Location,
              private navigationService: NavigationService,
              private dialogService: DialogService
              ) {
   }

  ngOnInit() {
      this.jsdoSessionService.sessionReady.then(() => {
        console.log(this.jsdoSessionService.userSource.value);
        if (this.jsdoSessionService.userSource.value && this.jsdoSessionService.userSource.value !== 'anonymous') {
          this.currentLogin = this.jsdoSessionService.userSource.value;
        }
      });
      this.navigationService.setLabel('Account');
  }

  onLogin() {
    this.jsdoSessionService.login(this.login, this.password).then(() => {
      this.currentLogin = this.login;
      setTimeout(() => {
        this.location.back();
      }, 1000);

    }, err => {
      if (err === progress.data.Session.AUTHENTICATION_FAILURE) {
        this.dialogService.alert('Invalid username or password', () => {}, 'Login failed');
      } else if (err === progress.data.Session.GENERAL_FAILURE) {
        this.dialogService.alert('An error occurred when authenticating', () => {}, 'Login failed');
      }
    });
  }

  onLogout() {
    this.settingsService.setCompanySetting('login', '');
    this.settingsService.setCompanySetting('password', '');
    this.jsdoSessionService.logout();
    this.currentLogin = '';
  }

}
