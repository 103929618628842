import * as actions from './access-rights.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';

const thisReducer = (state: AppState): State => state.accessRights;

export interface State {
    accessRights: string[];
    error: { errorMessage: string, errorType: string };
}

export const initialState: State = {
    accessRights: [],
    error: null,
};

const handleReducer = createReducer(
    initialState,
    on(actions.ADD_SINGLE, (state: State, { key }): State => {
        return {
            ...state,
            accessRights: state.accessRights.includes(key) ? state.accessRights : [...state.accessRights, key],
        };
    }),
    on(actions.ERROR, (state: State, { errorMessage, errorType }): State => {
        return {
            ...state,
            error: { errorMessage, errorType }
        };
    }),
    on(actions.RESET, (state: State, {}): State => {
        return {
            ...state,
            accessRights: [],
        };
    }),
    on(actions.REMOVE_SINGLE, (state: State, { key }): State => {
        return {
            ...state,
            accessRights: state.accessRights.filter(keyOfAccessRight => keyOfAccessRight !== key),
        };
    }),
);

export function reducer(state: State, action: actions.Actions): State {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: State) => state
);

export const selectAccessRights = createSelector(
    thisReducer, (state: State) => state.accessRights
);

export const selectError = createSelector(
    thisReducer, (state: State) => state.error
);
