import { createAction, props, union } from '@ngrx/store';
import { SuggestionInterface } from 'src/app/models/general/suggestion.interface';
import { EquipmentTypeInterface } from 'src/app/models';

// tslint:disable: max-line-length
export const CLEAR_ALL = createAction('[Suggestions] Clear All Suggestions', props<{}>());
export const LOADED_ASSEMBLY_CODES = createAction('[Suggestions] Loaded suggestions for *Assembly Codes*', props<{ items: SuggestionInterface[] }>());
export const LOADED_BILLING_TYPES = createAction('[Suggestions] Loaded suggestions for *Billing Types*', props<{ items: SuggestionInterface[] }>());
export const LOADED_BODY_MANUFACTURERS = createAction('[Suggestions] Loaded suggestions for *Body Manufacturers*', props<{ items: SuggestionInterface[] }>());
export const LOADED_CATEGORIES = createAction('[Suggestions] Loaded suggestions for *Categories*', props<{ items: SuggestionInterface[] }>());
export const LOADED_COMPONENTS = createAction('[Suggestions] Loaded suggestions for *Components*', props<{ items: SuggestionInterface[] }>());
export const LOADED_COMPONENT_LOCATIONS = createAction('[Suggestions] Loaded suggestions for *Component Locations*', props<{ items: SuggestionInterface[] }>());
export const LOADED_CURRENCIES = createAction('[Suggestions] Loaded suggestions for *Currencies*', props<{ items: SuggestionInterface[] }>());
export const LOADED_CUSTOMERS = createAction('[Suggestions] Loaded suggestions for *Customers*', props<{ items: SuggestionInterface[] }>());
export const LOADED_DAMAGE_CODES = createAction('[Suggestions] Loaded suggestions for *Damage Codes*', props<{ items: SuggestionInterface[] }>());
export const LOADED_DAMAGE_TYPES = createAction('[Suggestions] Loaded suggestions for *Damage Types*', props<{ items: SuggestionInterface[] }>());
export const LOADED_DEBTORS = createAction('[Suggestions] Loaded suggestions for *Debtors*', props<{ items: SuggestionInterface[] }>());
export const LOADED_DEPOTS = createAction('[Suggestions] Loaded suggestions for *Depots*', props<{ items: SuggestionInterface[] }>());
export const LOADED_DPP_TYPES = createAction('[Suggestions] Loaded suggestions for *DPP Types*', props<{ items: SuggestionInterface[] }>());
export const LOADED_EQUIPMENT_NUMBERS = createAction('[Suggestions] Loaded suggestions for *Equipment Numbers*', props<{ items: SuggestionInterface[] }>());
export const LOADED_EQUIPMENT_TYPES = createAction('[Suggestions] Loaded suggestions for *Equipment Types*', props<{ items: SuggestionInterface[] }>());
export const LOADED_ESTIMATE_CODES = createAction('[Suggestions] Loaded suggestions for *Estimate Codes*', props<{ items: SuggestionInterface[] }>());
export const LOADED_ESTIMATORS = createAction('[Suggestions] Loaded suggestions for *Estimators*', props<{ items: SuggestionInterface[] }>());
export const LOADED_LESSEES = createAction('[Suggestions] Loaded suggestions for *Lessees*', props<{ items: SuggestionInterface[] }>());
export const LOADED_MACHINERY_MANUFACTURERS = createAction('[Suggestions] Loaded suggestions for *Machinery Manufacturers*', props<{ items: SuggestionInterface[] }>());
export const LOADED_MACHINERY_MODELS = createAction('[Suggestions] Loaded suggestions for *Machinery Models*', props<{ items: SuggestionInterface[] }>());
export const LOADED_MATERIALS = createAction('[Suggestions] Loaded suggestions for *Materials*', props<{ items: SuggestionInterface[] }>());
export const LOADED_REPAIR_DEPOTS = createAction('[Suggestions] Loaded suggestions for *Repair Depots*', props<{ items: SuggestionInterface[] }>());
export const LOADED_REPAIR_METHODS = createAction('[Suggestions] Loaded suggestions for *Repair Methods*', props<{ items: SuggestionInterface[] }>());
export const LOADED_REPAIR_SITES = createAction('[Suggestions] Loaded suggestions for *Repair Sites*', props<{ items: SuggestionInterface[] }>());
export const LOADED_SERVICE_TYPES = createAction('[Suggestions] Loaded suggestions for *Service Types*', props<{ items: SuggestionInterface[] }>());
export const LOADED_STACK_CODES = createAction('[Suggestions] Loaded suggestions for *Stack Codes*', props<{ items: SuggestionInterface[] }>());
export const LOADED_STACK_LOCATIONS = createAction('[Suggestions] Loaded suggestions for *Stack Locations*', props<{ items: SuggestionInterface[] }>());
export const LOADED_TEMPLATE_NUMBERS = createAction('[Suggestions] Loaded suggestions for *Template Numbers*', props<{ items: SuggestionInterface[] }>());
export const LOADED_TONS_TESTED = createAction('[Suggestions] Loaded suggestions for *Tons Tested*', props<{ items: SuggestionInterface[] }>());
export const LOADED_TRANSPORT_COMPANIES = createAction('[Suggestions] Loaded suggestions for *Transport Companies*', props<{ items: SuggestionInterface[] }>());
export const LOAD_ASSEMBLY_CODES = createAction('[Suggestions] Suggest *Assembly Codes*', props<{ query: string }>());
export const LOAD_BILLING_TYPES = createAction('[Suggestions] Suggest *Billing Types*', props<{ query: string }>());
export const LOAD_BODY_MANUFACTURERS = createAction('[Suggestions] Suggest *Body Manufacturers*', props<{ query: string }>());
export const LOAD_CATEGORIES = createAction('[Suggestions] Suggest *Categories*', props<{ custAddressNumber: number, query: string }>());
export const LOAD_COMPONENTS = createAction('[Suggestions] Suggest *Components*', props<{ query: string}>());
export const LOAD_COMPONENT_LOCATIONS = createAction('[Suggestions] Suggest *Component Locations*', props<{ query: string}>());
export const LOAD_CURRENCIES = createAction('[Suggestions] Suggest *Currencies*', props<{}>());
export const LOAD_CUSTOMERS = createAction('[Suggestions] Suggest *Customers*', props<{ query: string }>());
export const LOAD_DAMAGE_CODES = createAction('[Suggestions] Suggest *Damage Codes*', props<{ query: string }>());
export const LOAD_DAMAGE_TYPES = createAction('[Suggestions] Suggest *Damage Types*', props<{ query: string }>());
export const LOAD_DEBTORS = createAction('[Suggestions] Suggest *Debtors*', props<{ query: string }>());
export const LOAD_DEPOTS = createAction('[Suggestions] Suggest *Depots*', props<{ query: string }>());
export const LOAD_DPP_TYPES = createAction('[Suggestions] Suggest *DPP Types*', props<{ query: string }>());
export const LOAD_EQUIPMENT_NUMBERS = createAction('[Suggestions] Suggest *Equipment Numbers*', props<{ equipmentNumber: string }>());
export const LOAD_EQUIPMENT_TYPES = createAction('[Suggestions] Suggest *Equipment Types*', props<{ custAddressNumber: number, query: string }>());
export const LOAD_ESTIMATE_CODES = createAction('[Suggestions] Suggest *Estimate Codes*', props<{ query: string }>());
export const LOAD_ESTIMATORS = createAction('[Suggestions] Suggest *Estimators*', props<{ query: string }>());
export const LOAD_LESSEES = createAction('[Suggestions] Suggest *Lessees*', props<{ custAddressNumber: number, query: string }>());
export const LOAD_MACHINERY_MANUFACTURERS = createAction('[Suggestions] Suggest *Machinery Manufacturers*', props<{ query: string }>());
export const LOAD_MACHINERY_MODELS = createAction('[Suggestions] Suggest *Machinery Models*', props<{ machineryManufacturerCode: string, query: string }>());
export const LOAD_MATERIALS = createAction('[Suggestions] Suggest *Materials*', props<{ query: string }>());
export const LOAD_REPAIR_DEPOTS = createAction('[Suggestions] Suggest *Repair Depots*', props<{ query: string }>());
export const LOAD_REPAIR_METHODS = createAction('[Suggestions] Suggest *Repair Methods*', props<{ query: string }>());
export const LOAD_REPAIR_SITES = createAction('[Suggestions] Suggest *Service Types*', props<{ query: string }>());
export const LOAD_SERVICE_TYPES = createAction('[Suggestions] Suggest *Repair Sites*', props<{ query: string }>());
export const LOAD_STACK_CODES = createAction('[Suggestions] Suggest *Stack Codes*', props<{ query: string }>());
export const LOAD_STACK_LOCATIONS = createAction('[Suggestions] Suggest *Stack Locations*', props<{ stackCode: string, query: string }>());
export const LOAD_TEMPLATE_NUMBERS = createAction('[Suggestions] Suggest *Template Numbers*', props<{ query: string, custAddressNr: number }>());
export const LOAD_TONS_TESTED = createAction('[Suggestions] Suggest *Tons Tested*', props<{ containerType: EquipmentTypeInterface, custAddressNr: number }>());
export const LOAD_TRANSPORT_COMPANIES = createAction('[Suggestions] Suggest *Transport Companies*', props<{ licensePlate: string }>());

const all = union({
    CLEAR_ALL,
    LOADED_ASSEMBLY_CODES,
    LOADED_BILLING_TYPES,
    LOADED_BODY_MANUFACTURERS,
    LOADED_CATEGORIES,
    LOADED_COMPONENTS,
    LOADED_COMPONENT_LOCATIONS,
    LOADED_CURRENCIES,
    LOADED_CUSTOMERS,
    LOADED_DAMAGE_CODES,
    LOADED_DAMAGE_TYPES,
    LOADED_DEBTORS,
    LOADED_DEPOTS,
    LOADED_DPP_TYPES,
    LOADED_EQUIPMENT_NUMBERS,
    LOADED_EQUIPMENT_TYPES,
    LOADED_ESTIMATE_CODES,
    LOADED_ESTIMATORS,
    LOADED_LESSEES,
    LOADED_MACHINERY_MANUFACTURERS,
    LOADED_MACHINERY_MODELS,
    LOADED_MATERIALS,
    LOADED_REPAIR_DEPOTS,
    LOADED_REPAIR_METHODS,
    LOADED_REPAIR_SITES,
    LOADED_SERVICE_TYPES,
    LOADED_STACK_CODES,
    LOADED_STACK_LOCATIONS,
    LOADED_TEMPLATE_NUMBERS,
    LOADED_TONS_TESTED,
    LOADED_TRANSPORT_COMPANIES,
    LOAD_ASSEMBLY_CODES,
    LOAD_BILLING_TYPES,
    LOAD_BODY_MANUFACTURERS,
    LOAD_CATEGORIES,
    LOAD_COMPONENTS,
    LOAD_COMPONENT_LOCATIONS,
    LOAD_CURRENCIES,
    LOAD_CUSTOMERS,
    LOAD_DAMAGE_CODES,
    LOAD_DAMAGE_TYPES,
    LOAD_DEBTORS,
    LOAD_DEPOTS,
    LOAD_DPP_TYPES,
    LOAD_EQUIPMENT_NUMBERS,
    LOAD_EQUIPMENT_TYPES,
    LOAD_ESTIMATE_CODES,
    LOAD_ESTIMATORS,
    LOAD_LESSEES,
    LOAD_MACHINERY_MANUFACTURERS,
    LOAD_MACHINERY_MODELS,
    LOAD_MATERIALS,
    LOAD_REPAIR_DEPOTS,
    LOAD_REPAIR_METHODS,
    LOAD_REPAIR_SITES,
    LOAD_SERVICE_TYPES,
    LOAD_STACK_CODES,
    LOAD_STACK_LOCATIONS,
    LOAD_TEMPLATE_NUMBERS,
    LOAD_TONS_TESTED,
    LOAD_TRANSPORT_COMPANIES,
});
export type SuggestionActions = typeof all;
