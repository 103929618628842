import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { VbsService } from '../services/jsdo-data/vbs.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-import-tar-added',
  templateUrl: './import-tar-added.component.html',
  styleUrls: ['./import-tar-added.component.scss']
})
export class ImportTarAddedComponent implements OnInit {

  vbsBooking;

  constructor(private navigationService: NavigationService,
              private router: Router,
              private route: ActivatedRoute,
              private vbsService: VbsService) { }

  ngOnInit() {
    this.navigationService.setLabel('Appointment added');
    this.navigationService.enableBackButton(true);

    this.route.params.pipe(switchMap((params: Params) => this.vbsService.getRecordByVbsBookingNr(params['vbsBookingNr'])))
        .subscribe(vbsBookings => {
          this.vbsBooking = vbsBookings[0];
        });
  }

}
