import { Injectable } from '@angular/core';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';
import { BookingStatsPeriod } from 'src/app/booking-stats/models/booking-stats-period.interface';
import { BookingStatsDay } from 'src/app/booking-stats/models/booking-stats-day.interface';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class BookingStatsService {
    private dataSource: any;
    private dataSourceReady;

    constructor(private jsdoSessionService: JsdoSessionService) {
        this.dataSourceReady = new Promise((resolve, reject) => {
            this.jsdoSessionService.sessionReady.then(() => {
                this.dataSource = new progress.data.JSDO({
                    name: 'dp_container_info'
                });
                resolve();
            });
        });
    }

    getBookingStats() {
        const t = this;
        return new Promise((resolve, reject) => {
            this.dataSourceReady.then(() => {
                this.dataSource.invoke('getVbsBookingStatistics', {
                    pdtFrom: moment().startOf('day').subtract(3, 'days').local().format('YYYY-MM-DD'),
                    pdtTill: moment().startOf('day').add(1, 'days').local().format('YYYY-MM-DD')
                }).done(function (jsdo, success, request) {
                    const data = t.convertBookingStats(request.response['tt-vbs-booking-statistics']['tt-vbs-booking-statistics']);
                    resolve(data);
                }).fail(function (error) {
                    reject(error);
                });
            });
        });
    }

    convertBookingStats(periods: BookingStatsPeriod[]): BookingStatsDay[] {
        const days: BookingStatsDay[] = [];
        let periodsInDay: BookingStatsPeriod[] = [];
        let previousDate: string;
        periods.forEach(period => {
            if (previousDate && period.dateTimeSlot !== previousDate) {
                days.push({ periods: periodsInDay });
                periodsInDay = [];
            }
            periodsInDay.push(period);
            previousDate = period.dateTimeSlot;
        });
        if (periodsInDay.length > 0) {
            days.push({ periods: periodsInDay });
        }
        return days;
    }

}

