import * as actions from './information.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';

const thisReducer = (state: AppState): State => state.information;

export interface State {
    loading: boolean;
    results: any;
    loaded: boolean;
    error: { errorMessage: string, errorType: string };
}

export const initialState: State = {
    loading: false,
    results: [],
    loaded: false,
    error: null,
};

const handleReducer = createReducer(
    initialState,
    on(actions.RECEIVED, (state: State, { result }): State => {
        return {
            ...state,
            loading: false,
            results: result,
            loaded: true,
        };
    }),
    on(actions.ERROR, (state: State, { errorMessage, errorType }): State => {
        return {
            ...state,
            error: { errorMessage, errorType }
        };
    }),
    on(actions.RESET, (state: State, {}): State => {
        return {
            ...state,
            error: null,
            loading: false,
            results: [],
            loaded: false,
        };
    }),
    on(actions.GET, (state: State, { }): State => {
        return {
            ...state,
            loading: true,
            results: [],
            loaded: false,
        };
    }),
);

export function reducer(state: State, action: actions.Actions): State {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: State) => state
);

export const selectError = createSelector(
    thisReducer, (state: State) => state.error
);

export const selectLoaded = createSelector(
    thisReducer, (state: State) => state.loaded
);

export const selectLoading = createSelector(
    thisReducer, (state: State) => state.loading
);

export const selectResults = createSelector(
    thisReducer, (state: State) => state.results
);
