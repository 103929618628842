import { Injectable } from '@angular/core';

import { SettingsService } from './settings.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  company;
  public build;

  private companySource = new BehaviorSubject<any>('');
  company$ = this.companySource.asObservable();

  constructor(private settingsService: SettingsService) {

    // Read environment variables from browser window object
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};

    this.build = browserWindowEnv.build;

    this.company = SettingsService.getSetting('company');
    if (this.company) {
      // Conversie: MRT -> MRTX  Mag weg na 01/04/2021
      if(this.company === 'MRT') {
        this.company = 'MRTX';
        SettingsService.setSetting('company', this.company);
      } 
      
      this.companySource.next(this.company);
    }

    this.settingsService.brandCode.then(brandCode => {
        if (!this.company) {
            if (brandCode === 'POC') {
                this.setCompany('POC');
            }
            if (brandCode === 'DFT') {
                this.setCompany('DFT');
            }
            if (brandCode === 'ZZC') {
              this.setCompany('ZZC');
          }
        }
    });
  }

  setCompany(company) {
    this.company = company;
    SettingsService.setSetting('company', company);
    this.companySource.next(company);
  }

}

