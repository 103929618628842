import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import * as fromRoot from '../store/index';
import * as fromBookingLookup from '../store/booking-lookup/booking-lookup.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DetailBlockStatus } from '../softpak/detail-block/detail-block-status.enum';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnDestroy, OnInit {
  bookings: any[];
  subBookingData: Subscription;

  constructor(private readonly navigationService: NavigationService,
              private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit(): void {
    this.navigationService.setLabel(`Booking`);
    this.navigationService.enableBackButton(true);

    this.subBookingData = this.appStore.select(fromBookingLookup.selectBookingsData).subscribe(bookings => {
      this.bookings = bookings;
    });
  }

  ngOnDestroy(): void {
    this.subBookingData.unsubscribe();
  }

  convertStatusCode(code: string): DetailBlockStatus {
    switch (code) {
      case 'EXPECTED': return DetailBlockStatus.AWAITING;
      case 'OPEN': return DetailBlockStatus.OK;
      case 'CLOSED': return DetailBlockStatus.CLOSED;
      case 'EXPIRED': return DetailBlockStatus.NOK;
      case 'CANCELLED': return DetailBlockStatus.NOK;
    }
  }

  convertStatusText(code: string): string {
    switch (code) {
      case 'EXPECTED': return 'Expected';
      case 'OPEN': return 'Open';
      case 'CLOSED': return 'Closed';
      case 'EXPIRED': return 'Expired';
      case 'CANCELLED': return 'Cancelled';
    }
  }

}
