import { Component, OnInit } from '@angular/core';
import { QuayjobService } from '../services/jsdo-data/quayjob.service';
import { NavigationService } from '../services/navigation.service';


@Component({
  selector: 'app-quayjob-list',
  templateUrl: './quayjob-list.component.html',
  styleUrls: ['./quayjob-list.component.scss']
})
export class QuayjobListComponent implements OnInit {

  quayjobs: Array<any>;

  constructor(private quayjobService: QuayjobService,
              private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.setLabel('Quayjobs');
    this.navigationService.enableBackButton(true);

    this.quayjobService.getQuayjobs().then((data: any) => {
      this.quayjobs = data;
    });

  }

  toggleDetails(quayjob) {
    quayjob.showDetails = !quayjob.showDetails;
  }

  // Safari needs full URL for SVG fill when BASE HREF is used...
  getFillUrl(fillCode) {
    if (fillCode) {
      return 'url(' + window.location.href + '#grad' + fillCode + ')';
    } else {
      return '';
    }
  }

}
