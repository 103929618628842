import { Component, OnInit, Input } from '@angular/core';
import { ChartColumnInterface } from '../models/chart-column.interface';
import { BookingStatsBar } from 'src/app/booking-stats/models/booking-stats-bar.class';

@Component({
  selector: 'sp-grid-chart',
  templateUrl: './grid-chart.component.html',
  styleUrls: ['./grid-chart.component.scss']
})
export class GridChartComponent implements OnInit {
  @Input() bars: BookingStatsBar[];
  @Input() labels: string[];
  @Input() labelWidth: number;
  @Input() minColumns = 0;
  @Input() values: ChartColumnInterface[];
  spacers: number[] = [];

  constructor() { }

  ngOnInit() {
    this.spacers = [];
    for (let i = 0; i < this.minColumns - this.bars.length; i++) {
      this.spacers.push(i);
    }
  }

}
