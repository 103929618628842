import { Component, OnInit, Input } from '@angular/core';
import * as fromRoot from '../../../store/index';
import * as fromBookingStats from '../../../store/booking-stats/booking-stats.reducer';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-booking-stats-grid-header',
  templateUrl: './booking-stats-grid-header.component.html',
  styleUrls: ['./booking-stats-grid-header.component.scss']
})
export class BookingStatsGridHeaderComponent implements OnInit {
  public splitImportExport: boolean;
  public splitFullEmpty: boolean;

  constructor(private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit() {
    this.appStore.pipe(select(fromBookingStats.selectBookingStatsFilters)).subscribe(filters => {
      this.splitFullEmpty = filters.splitFullEmpty;
      this.splitImportExport = filters.splitImportExport;
    });
  }

}
