import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StacklocationService } from '../services/jsdo-data/stacklocation.service';
import { ContainerService } from '../services/jsdo-data/container.service';
import { QuayjobBaplieService } from '../services/jsdo-data/quayjob-baplie.service';

@Component({
  selector: 'app-quayjob-bayplan',
  templateUrl: './quayjob-bayplan.component.html',
  styleUrls: ['./quayjob-bayplan.component.scss']
})
export class QuayjobBayplanComponent implements OnInit {

  private _selectedLocation;
  private _selectedCell;
  private _selectedBaynr;


  @Output() selectedLocationChange = new EventEmitter();

  @Input()
  get selectedLocation() {
    return this._selectedLocation;
  }

  set selectedLocation(value) {
    this._selectedLocation = value;
    this.selectedLocationChange.emit(this._selectedLocation);
  }

  get selectedCell() {
    return this._selectedCell;
  }
  set selectedCell(cell) {
    this._selectedCell = cell;
    this.selectedLocation = this.selectedBaynr + cell.row + cell.tier;
  }

  get selectedBaynr() {
    return this._selectedBaynr;
  }
  set selectedBaynr(baynr) {
    this._selectedBaynr = baynr;
    this.start();
    this.getContainerData();
    this.getQuayjobBaplieData();
  }

  stackLocations;
  scale = 100;
  x = 0;
  y = 0;
  cells = [];
  bays = [];
  containers: Array<any>;
  quayjobId = 101820;
  private quayjobBaplieData;

  layerShowContainers = true;
  layerShowPrestow = true;

  constructor(private stackLocationService: StacklocationService,
              private containerService: ContainerService,
              private quayjobBaplieService: QuayjobBaplieService) { }

  ngOnInit() {

    this.stackLocationService.getDataByStackCode('V93').then(data => {
      this.stackLocations = data;

      for (let i = 0; i < this.stackLocations.length; i++) {
        const bay = this.stackLocations[i].baynr;
        let foundBay;
        for (let i2 = 0; i2 < this.bays.length; i2++) {
          if (this.bays[i2] === bay) {
            foundBay = true;
          }
        }
        if (!foundBay) {
          this.bays.push(bay);
        }
      }

      this.selectedBaynr = 20;

      this.start();
    });
  }

  start() {
    this.cells = [];
    const rows = ['12', '10', '08', '06', '04', '02', '00', '01', '03', '05', '07', '09', '11'];
    const tiers = ['02', '04', '06', '08', '10', '12', '14', '16'];

    for (let ir = 0; ir < rows.length; ir++) {
      for (let it = 0; it < tiers.length; it++) {
        const cell = {
          row: rows[ir],
          tier: tiers[it],
          x: ir,
          y: tiers.length - it,
          ctr: null,
          active: false
        };

        for (let ia = 0; ia < this.stackLocations.length; ia++) {
          const loc = this.stackLocations[ia];
          // tslint:disable-next-line:max-line-length
          if (this.selectedBaynr && this.selectedBaynr === parseInt(loc.baynr, 10) && parseInt(cell.row, 10) === parseInt(loc.rownr, 10) && parseInt(cell.tier, 10) === parseInt(loc.tiernr, 10)) {
            cell.active = true;
          }
        }

        this.cells.push(cell);
      }
    }

  }

  getContainerData() {
    this.containers = null;
    const filter = {'logic': 'and', 'filters': [
      {'field': 'out_quayjobid', 'operator': 'eq', 'value': this.quayjobId}
    ]};
    this.containerService.getData(filter).then((records: Array<any>) => {
      this.containers = records;

      for (let i = 0; i < this.containers.length; i++) {
        const container = this.containers[i];
        if (container.stowlocation) {
          const baynr = parseInt(container.stowlocation.substr(0, 3), 10);
          const rownr = parseInt(container.stowlocation.substr(3, 2), 10);
          const tiernr = parseInt(container.stowlocation.substr(5, 2), 10);

          for (let i2 = 0; i2 < this.cells.length; i2++) {
            const cell = this.cells[i2];
            if (this.selectedBaynr === baynr && parseInt(cell.row, 10) === rownr && parseInt(cell.tier, 10) === tiernr) {
              cell.ctr = container;
            }
          }
        }
      }

    });
  }

  getQuayjobBaplieData() {
    this.quayjobBaplieData = null;

    const filter = {'logic': 'and', 'filters': [
      {'field': 'quayjobid', 'operator': 'eq', 'value': this.quayjobId}
    ]};
    this.quayjobBaplieService.getData(filter).then((records: Array<any>) => {
      this.quayjobBaplieData = records;

      for (let i = 0; i < this.quayjobBaplieData.length; i++) {
        const quayjobBaplieData = this.quayjobBaplieData[i];
        if (quayjobBaplieData.stowlocation) {
          const baynr = parseInt(quayjobBaplieData.stowlocation.substr(0, 3), 10);
          const rownr = parseInt(quayjobBaplieData.stowlocation.substr(3, 2), 10);
          const tiernr = parseInt(quayjobBaplieData.stowlocation.substr(5, 2), 10);

          for (let i2 = 0; i2 < this.cells.length; i2++) {
            const cell = this.cells[i2];
            if (this.selectedBaynr === baynr && parseInt(cell.row, 10) === rownr && parseInt(cell.tier, 10) === tiernr) {
              cell.preStow = quayjobBaplieData;
            }
          }
        }
      }

    });
  }

  logging(cell) {
    this.selectedCell = cell;
    console.log(cell);
  }

  calcTonnage(weight) {
    return Math.round(weight / 1000);
  }
}
