import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PullComponent } from './pull.component';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    PullComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    PullComponent
  ]
})
export class PullModule { }
