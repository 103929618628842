import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService, SearchHistoryType } from '../services/settings.service';
import { NavigationService } from '../services/navigation.service';
import { RecentSearchesEntityEnum } from './recent-searches-entity-enum.enum';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-recent-searches',
  templateUrl: './recent-searches.component.html',
  styleUrls: ['./recent-searches.component.scss']
})
export class RecentSearchesComponent implements OnInit {
  entity: RecentSearchesEntityEnum;
  items;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly snackbar: MatSnackBar) { }

  ngOnInit() {
    this.entity = this.activatedRoute.snapshot.params['entity'];
    this.navigationService.setLabel(`Recent ${this.entity}`);
    this.navigationService.enableBackButton(true);
    this.update();
  }

  onClearSearchHistory(): void {
    this.settingsService.clearSearchHistory();
    this.snackbar.open('The list with recent searches has been cleared', '', {
      duration: 3000
    });
    this.update();
  }

  update(): void {
    this.items = this.settingsService.getHistoryContainer().filter(item => {
      switch (this.entity) {
        case RecentSearchesEntityEnum.BOOKINGS:
          return item.booking;
        case RecentSearchesEntityEnum.CONTAINERS:
          return item.cntr;
      }
      return item;
    });
  }

  onClearSearchItem($event, item) {
    if (item.booking) {
      this.settingsService.removeFromSearchHistory(SearchHistoryType.BOOKING, item);
    }
    if (item.cntr) {
      this.settingsService.removeFromSearchHistory(SearchHistoryType.CONTAINER, item);
    }
    $event.stopPropagation();
    this.update();
  }

  showContainer(container) {
    this.navigationService.enableBackButton(true);
    this.router.navigate(['containerinfo', container]);
  }

  showBooking(booking) {
    this.navigationService.enableBackButton(true);
    this.router.navigate(['bookingdetails', booking]);
  }

}
