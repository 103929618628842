import { Injectable } from '@angular/core';

import { JsdoSessionService } from '../jsdo-session.service';
import { JsdoService } from '../jsdo.service';
import { getTreeNoValidDataSourceError } from '@angular/cdk/tree';

@Injectable({
  providedIn: 'root'
})
export class ReeferMonitoringService {

  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              public jsdoService: JsdoService) {
    this.dataSourceReady = new Promise((resolve, reject) => {

      this.jsdoSessionService.sessionReady.then(() => {
        this.jsdoService.prepare('dp_reefer_temp_registration');
          resolve();
        });
    });
  }

  getData(filter?) {
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.jsdoService.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
          console.log(request);
          resolve(this.jsdoService.dataSource.getData());
        });
      });
    });
  }

  getDataByStack(stackcode) {
    const filter = {'logic': 'and', 'filters': [{'field': 'stackcode', 'operator': 'eq', 'value': stackcode}]};
    return this.getData(filter);
  }

  getStacks() {
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.jsdoService.dataSource.invoke('getStacks', {})
          .done(function (jsdo, success, request) {
            console.log(request.response);
            resolve(request.response);
          }).fail(function (error) {
            reject(error);
          });
      });
    });
  }

}



