import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { SettingsService } from '../services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class RouterGuardService implements CanActivate {

  constructor(public router: Router,
              private settingsService: SettingsService) {
    // Conversie vanuit oude (unbranded) versie app. Te herkennen door: Company is gezet, maar welcomeDone nog niet.
    // We verwijderen de oude, niet meer gebruikte settings en zetten welcomeDone op YES
    this.settingsService.brandCode.then(brandCode => {
      if (brandCode === 'SOFTPAK' && SettingsService.getSetting('company') && !SettingsService.getSetting('welcomeDone')) {
        const company = SettingsService.getSetting('company');
        localStorage.clear();
        SettingsService.setSetting('company', company);
        SettingsService.setSetting('welcomeDone', 'YES');
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!SettingsService.getSetting('welcomeDone')) {
      this.router.navigate(['welcome']);
      return false;
    }

    /* Unreleased features */
    if (route.url && route.url[0]) {
      if (route.url[0].path === 'quayjob' && SettingsService.getSetting('company') !== 'SOFTPAK') {
        return false;
      }
    }
    return true;
  }

  canActivateQuayjob(): boolean {
    if (SettingsService.getSetting('company') === 'ROW') {
      return true;
    }
  }
}
