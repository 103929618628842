import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  label: Observable<String>;
  labelObserver: any;

  private labelSource = new BehaviorSubject<any>('');
  label$ = this.labelSource.asObservable();

  navType: Observable<String>;
  navTypeObserver: any;

  backButtonEnabled: boolean;
  backButton: Observable<Boolean>;
  backButtonObserver: any;

  hideNavigation: Observable<Boolean>;
  hideNavigationObserver: any;

  constructor(private location: Location,
              private titleService: Title) {
      this.backButton = new Observable(observer => {
        this.backButtonObserver = observer;
      });

      this.hideNavigation = new Observable(observer => {
        this.hideNavigationObserver = observer;
      });

      document.addEventListener('backbutton', () => {
        if (this.backButtonEnabled) {
          this.location.back();
        }
      }, false);

  }

  setLabel(newLabel) {
    this.labelSource.next(newLabel);
    this.titleService.setTitle( newLabel + ' – Softpak Container App' );
  }

  enableBackButton(enable) {
    this.backButtonEnabled = enable;
    this.backButtonObserver.next(enable);
  }

}
