import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromRoot from './store/index';
import * as bookingLookupActions from './store/booking-lookup/booking-lookup.actions';
import * as fromBookingLookup from './store/booking-lookup/booking-lookup.reducer';
import { throwError } from 'rxjs';
import { map, filter, take, switchMap } from 'rxjs/operators';
import * as toaster from 'softpak-frontend-toaster';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class BookingDetailResolver implements Resolve<any> {
  store: any;
  constructor(
        private readonly appStore: Store<fromRoot.AppState>,
        private readonly router: Router,
        private readonly matSnackBar: MatSnackBar) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    this.appStore.dispatch(toaster.CLEAR_ALL({}));
    this.appStore.dispatch(bookingLookupActions.RESET({}));
    this.appStore.dispatch(bookingLookupActions.LOAD_BOOKING_DATA({ bookingNumber: route.params.bookingNumber }));

    const t = this;

    return new Promise(function(resolve, reject) {
      t.appStore.select(fromBookingLookup.selectAll).pipe(
        map(all => {
          if ((all as any).bookingDataError) {
            t.router.navigate(['/']);
            t.matSnackBar.open((all as any).bookingDataError.errorMessage, '', {duration: 3000});
            throwError((all as any).bookingDataError.errorMessage);
          }
          return all;
        }),
        filter(all => !(all as any).bookingDataLoading),
        take(1),
        switchMap(() =>
          t.appStore.pipe(
            select(fromBookingLookup.selectBookingsData),
            take(1)
            ).toPromise()
          )
      ).subscribe(data => {
        if (data === null) {
          reject('Booking page not loaded.');
        } else {
          resolve(data as any);
        }
      });
    }).catch(error => { console.log(error); });

  }
}
