import { BookingDetailResolver } from './app-routing.booking.resolver';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingStatsHomeComponent } from './booking-stats/compounds/booking-stats-home/booking-stats-home.component';
import { BookingStatsResolver } from './app-routing.booking-stats.resolver';
import { ContainerComponent } from './container/container.component';
import { ContainerDetailResolver } from './app-routing.container.resolver';
import { ContainerInfoComponent } from './container-info/container-info.component';
import { EquipmentStatsDetailComponent } from './equipment-stats/compounds/equipment-stats-detail/equipment-stats-detail.component';
import { EquipmentStatsDetailResolver } from './app-routing.equipment-stats-detail.resolver';
import { EquipmentStatsHomeComponent } from './equipment-stats/compounds/equipment-stats-home/equipment-stats-home.component';
import { EquipmentStatsResolver } from './app-routing.equipment-stats.resolver';
import { EquipmentStatsSingleComponent } from './equipment-stats/compounds/equipment-stats-single/equipment-stats-single.component';
import { HomeComponent } from './softpak/home/home.component';
import { ImportTarAddedComponent } from './import-tar-added/import-tar-added.component';
import { ImportTarComponent } from './import-tar/import-tar.component';
import { InfoComponent } from './softpak/info/info.component';
import { KioskVisitComponent } from './kiosk-visit/kiosk-visit.component';
import { LoginComponent } from './softpak/login/login.component';
import { MainComponent } from './softpak/main/main.component';
import { NgModule } from '@angular/core';
import { PhotoComponent } from './photo/photo.component';
import { QuayjobComponent } from './quayjob/quayjob.component';
import { QuayjobListComponent } from './quayjob-list/quayjob-list.component';
import { RecentSearchesComponent } from './recent-searches/recent-searches.component';
import { ReeferConnectionComponent } from './reefer-connection/reefer-connection.component';
import { ReeferMonitoringDetailsComponent } from './reefer-monitoring/reefer-monitoring-details/reefer-monitoring-details.component';
import { ReeferMonitoringSelectComponent } from './reefer-monitoring/reefer-monitoring-select/reefer-monitoring-select.component';
import { ReeferMonitoringStackComponent } from './reefer-monitoring/reefer-monitoring-stack/reefer-monitoring-stack.component';
import { RouterGuardService } from './services/router-guard.service';
import { RouterModule, Routes } from '@angular/router';
import {
  ROUTING_KEY_APPOINTMENT_STATS,
  ROUTING_KEY_BOOKING_DETAILS,
  ROUTING_KEY_CONTAINER,
  ROUTING_KEY_CONTAINER_INFO,
  ROUTING_KEY_EQUIPMENT_STATS,
  ROUTING_KEY_EQUIPMENT_STATS_DETAIL,
  ROUTING_KEY_ESTIMATE_LINES,
  ROUTING_KEY_ESTIMATES,
  ROUTING_KEY_IMPORT_TAR,
  ROUTING_KEY_IMPORT_TAR_ADDED,
  ROUTING_KEY_INFORMATION,
  ROUTING_KEY_INSPECTIONS,
  ROUTING_KEY_KIOSKVISIT,
  ROUTING_KEY_LOGIN,
  ROUTING_KEY_PHOTO,
  ROUTING_KEY_QUAYJOB_DETAIL,
  ROUTING_KEY_QUAYJOBS,
  ROUTING_KEY_RECENT_SEARCHES,
  ROUTING_KEY_REDELIVERY,
  ROUTING_KEY_REEFER_MONITORING,
  ROUTING_KEY_REEFER_MONITORING_DETAILS,
  ROUTING_KEY_REEFER_MONITORING_SELECT,
  ROUTING_KEY_REEFER_ORDERS,
  ROUTING_KEY_SETTINGS,
  ROUTING_KEY_SETTINGS_2,
  ROUTING_KEY_TIMESLOT_DETAILS,
  ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION,
  ROUTING_KEY_TIMESLOTS,
  ROUTING_KEY_UPDATE,
  ROUTING_KEY_VBS_LINES,
  ROUTING_KEY_WELCOME,
  ROUTING_KEY_REEFER_INSPECTIONS,
  ROUTING_KEY_ABOUT,
  ROUTING_KEY_DASHBOARDS,
  ROUTING_KEY_LOGGING
  } from './softpak/menu/app-menu-items.const';
import { SettingsComponent } from './settings/settings.component';
import { TimeslotDetailsComponent } from './timeslot-details/timeslot-details.component';
import { TimeslotTerminalSelectComponent } from './timeslot-terminal-select/timeslot-terminal-select.component';
import { TimeslotsComponent } from './timeslots/timeslots.component';
import { UpdateComponent } from './softpak/update/update.component';
import { VbsLineComponent } from './vbs-line/vbs-line.component';
import { WelcomeComponent } from './softpak/welcome/welcome.component';
import { VBSBookingResolver } from './app-routing.vbs_booking.resolver';
import { AppLoggingComponent } from './app-logging/app-logging.component';

const routes: Routes = [
    {
      path: '',
      component: MainComponent,
      canActivate: [RouterGuardService],
      children: [
        {
          path: '',
          component: HomeComponent,
          canActivate: [RouterGuardService]
        },
        {
          path: ROUTING_KEY_APPOINTMENT_STATS,
          component: BookingStatsHomeComponent,
          resolve: {
            booking: BookingStatsResolver
          }
        },
        {
          path: `${ROUTING_KEY_BOOKING_DETAILS}/:bookingNumber`,
          component: BookingDetailsComponent,
          resolve: {
            booking: BookingDetailResolver
          }
        },
        {
          path: ROUTING_KEY_DASHBOARDS,
          loadChildren: './information/information.module#InformationModule',
        },
        {
          path: ROUTING_KEY_SETTINGS,
          loadChildren: './settings/settings.module#SettingsModule'
        },
        {
          path: ROUTING_KEY_LOGIN,
          component: LoginComponent,
        },
        {
          path: ROUTING_KEY_ABOUT,
          component: InfoComponent,
        },
        {
          path: ROUTING_KEY_INFORMATION,
          loadChildren: './information/information.module#InformationModule',
        },
        {
          path: ROUTING_KEY_SETTINGS_2,
          component: SettingsComponent,
        },
        {
          path: `${ROUTING_KEY_CONTAINER_INFO}/:container`,
          component: ContainerInfoComponent,
          resolve: {
            container: ContainerDetailResolver
          }
        },
        {
          path: ROUTING_KEY_EQUIPMENT_STATS,
          component: EquipmentStatsHomeComponent,
          resolve: {
            container: EquipmentStatsResolver
          }
        },
        {
          path: `${ROUTING_KEY_EQUIPMENT_STATS_DETAIL}/:equipmentGroup`,
          component: EquipmentStatsDetailComponent,
          resolve: {
            container: EquipmentStatsDetailResolver
          }
        },
        {
          path: `${ROUTING_KEY_EQUIPMENT_STATS_DETAIL}/:equipmentGroup/:equipment`,
          component: EquipmentStatsSingleComponent,
          resolve: {
            container: EquipmentStatsDetailResolver
          }
        },
        {
          path: `${ROUTING_KEY_RECENT_SEARCHES}/:entity`,
          component: RecentSearchesComponent
        },
        {
          path: `${ROUTING_KEY_TIMESLOTS}/:depotcode`,
          component: TimeslotsComponent,
        },
        {
          path: ROUTING_KEY_TIMESLOTS,
          component: TimeslotsComponent,
        },
        {
          path: ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION,
          component: TimeslotTerminalSelectComponent,
        },
        {
          path: `${ROUTING_KEY_TIMESLOT_DETAILS}/:timeslot`,
          component: TimeslotDetailsComponent,
        },
        {
          path: `${ROUTING_KEY_VBS_LINES}/:vbsbooking`,
          component: VbsLineComponent,
          resolve: {
            vbsBooking: VBSBookingResolver
          }
        },
        {
          path: ROUTING_KEY_REEFER_ORDERS,
          component: ReeferConnectionComponent,
        },
        {
          path: ROUTING_KEY_REEFER_MONITORING,
          component: ReeferMonitoringStackComponent,
        },
        {
          path: `${ROUTING_KEY_REEFER_MONITORING_SELECT}/:stack`,
          component: ReeferMonitoringSelectComponent,
        },
        {
          path: `${ROUTING_KEY_REEFER_MONITORING_DETAILS}/:recid`,
          component: ReeferMonitoringDetailsComponent,
        },
        {
          path: ROUTING_KEY_PHOTO,
          component: PhotoComponent,
        },
        {
          path: ROUTING_KEY_QUAYJOBS,
          component: QuayjobListComponent,
        },
        {
          path: `${ROUTING_KEY_QUAYJOB_DETAIL}/:quayjobid`,
          component: QuayjobComponent,
          canActivate: [RouterGuardService],
        },
        {
          path: ROUTING_KEY_IMPORT_TAR,
          component: ImportTarComponent,
        },
        {
          path: `${ROUTING_KEY_IMPORT_TAR_ADDED}/:vbsBookingNr`,
          component: ImportTarAddedComponent,
        },
        {
          path: ROUTING_KEY_KIOSKVISIT,
          component: KioskVisitComponent,
        },
        {
          path: ROUTING_KEY_CONTAINER,
          component: ContainerComponent,
        },
        {
          path: ROUTING_KEY_LOGGING,
          component: AppLoggingComponent,
        }
    ]},
    {
      path: ROUTING_KEY_WELCOME,
      component: WelcomeComponent
    },
    {
      path: ROUTING_KEY_UPDATE,
      component: UpdateComponent
    }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {useHash: false}) ],
  exports: [ RouterModule ],
  providers: [
    BookingDetailResolver,
    BookingStatsResolver,
    ContainerDetailResolver,
    EquipmentStatsDetailResolver,
    EquipmentStatsResolver,
    VBSBookingResolver
  ]
})

export class AppRoutingModule {

  constructor() { }

}
