import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { STOR_KEY_FAVOURITE_DEPOT } from './models/storage-keys';

@Injectable({
  providedIn: 'root'
})
export class DepotSettingsService {
    public static setFavouriteDepot(depotCode: string): void {
        SettingsService.setSetting(STOR_KEY_FAVOURITE_DEPOT, depotCode);
    }

    public static getFavouriteDepot(): string {
        return SettingsService.getSetting(STOR_KEY_FAVOURITE_DEPOT);
    }
}
