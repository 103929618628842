import { Component, OnInit, ViewChild } from '@angular/core';
import { ReeferMonitoringService } from 'src/app/services/jsdo-data/reefer-monitoring.service';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-reefer-monitoring-stack',
  templateUrl: './reefer-monitoring-stack.component.html',
  styleUrls: ['./reefer-monitoring-stack.component.scss']
})
export class ReeferMonitoringStackComponent implements OnInit {

  records: any;
  loadingState = 'INIT';

  columnsToDisplay = ['location', 'nr_todo', 'nr_done'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private reeferMonitoringService: ReeferMonitoringService,
              private navigationService: NavigationService) { }

  ngOnInit() {
    this.navigationService.setLabel('Reefer Monitoring');
    this.loadingState = 'LOADING';
    this.reeferMonitoringService.getStacks().then((data: any) => {
      this.loadingState = 'READY';
      const records = data.tt_location.tt_location;
      this.records = null;
      this.dataSource.data = records;
      this.records = records;
    });
  }

}
