import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NavigationService } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { ContainerInfoService} from '../services/jsdo-data/container-info.service';
import { AttachmentComponent } from '../attachment/attachment.component';
import { SessionService } from '../services/session.service';


@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit, OnDestroy {
  poll: false;
  container;

  private interval;
  @ViewChild('appAttContainer', { static: false }) private appAttContainer: AttachmentComponent;
  @ViewChild('appAttEstimate', { static: false }) private appAttEstimate: AttachmentComponent;

  constructor(private router: Router,
              private navigationService: NavigationService,
              private settingsService: SettingsService,
              private containerService: ContainerInfoService,
              public snackBar: MatSnackBar,
              public sessionService: SessionService) { }

  ngOnInit() {
    this.navigationService.setLabel('Photo');
    this.onGetContainer();
    this.navigationService.enableBackButton(true);
  }

  onPollChange() {
    if (this.poll) {
      this.interval = window.setInterval(() => this.onGetContainer(), 5000);
    } else {
      window.clearInterval(this.interval);
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }


  onGetContainer() {
    this.containerService.getContainer('CURRENT').then((container: any) => {
      this.container = container;
    }).catch(error => {
      if (error.errorType === 'CONTAINER_NOT_FOUND') {
        // Currently no container selected
      } else {
        console.error(error);
      }
    });
  }

  onAddContainerPhoto(source) {

    this.settingsService.addPhoto(source).then(imageData => {
      this.containerService.addPhoto(this.container.seqnr, imageData).then(() => {
        this.snackBar.open('Photo added!', '', {duration: 1000});
        this.appAttContainer.ngOnInit();
      });
    }, error => {
      alert('An error has occurred!');
    });
  }

  onAddEstimatePhoto(source) {
    this.settingsService.addPhoto(source).then(imageData => {
      this.containerService.addEstimatePhoto(this.container.estimatenr, imageData).then(() => {
        this.snackBar.open('Photo added!', '', {duration: 1000});
        this.appAttEstimate.ngOnInit();
      });
    }, error => {
      alert('An error has occurred!');
    });
  }

  onGotoContainerInfo() {
    this.navigationService.enableBackButton(true);
    this.router.navigate(['containerinfo', this.container.containerprefix + this.container.containernumber]);
  }

}
