import { Component, OnInit, OnDestroy } from '@angular/core';
import { BookingStatsBar } from 'src/app/booking-stats/models/booking-stats-bar.class';
import { Subscription } from 'rxjs';
import { ChartColumnInterface } from 'src/app/softpak/charts/models/chart-column.interface';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import * as fromEquipmentStatsDetail from '../../../store/equipment-stats-detail/equipment-stats-detail.reducer';
import * as fromSettings from '../../../store/settings/settings.reducer';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';
import { GraphService } from 'src/app/softpak/charts/services/graph.service';
import { ActivatedRoute } from '@angular/router';
import { unsubscribeSubscriptions } from 'src/app/softpak/functions/unsubscribe-subscriptions';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-equipment-stats-detail-graph',
  templateUrl: './equipment-stats-detail-graph.component.html',
  styleUrls: ['./equipment-stats-detail-graph.component.scss']
})
export class EquipmentStatsDetailGraphComponent implements OnDestroy, OnInit {
  public currentEquipmentGroupName: string;

  public highestValue: number;
  public stepSize: number;
  public numberOfSteps: number;
  public xAxisLines: number[];

  public currentDepot: string;
  private statsForAllDepots: any[] = [];
  public statsForCurrentDepot: any;
  private readonly subscriptions: {
    selectAll?: Subscription;
    selectDepot?: Subscription;
  } = {};

  constructor(
    private readonly appStore: Store<fromRoot.AppState>,
    private readonly activtatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    ) {
      this.activtatedRoute.params.subscribe( params => {
        this.currentEquipmentGroupName = params['equipmentGroup'];
        this.navigationService.setLabel(this.currentEquipmentGroupName);
      });
    }

  ngOnInit(): void {
    this.subscribeToFavouriteDepot();
    this.subscribeToStats();
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

  private subscribeToFavouriteDepot(): void {
    this.currentDepot = DepotSettingsService.getFavouriteDepot();
    this.subscriptions.selectDepot = this.appStore.pipe(select(fromSettings.selectDepot))
      .subscribe(() => {
        this.currentDepot = DepotSettingsService.getFavouriteDepot();
        this.statsForCurrentDepot = this.convertStats(this.filterStats(this.statsForAllDepots));
        this.determineGraphParameters();
      });
  }

  private subscribeToStats(): void {
    this.subscriptions.selectAll = this.appStore.pipe(select(fromEquipmentStatsDetail.selectAll))
      .subscribe((all) => {
        this.statsForAllDepots = all.data;
        this.statsForCurrentDepot = this.convertStats(this.filterStats(this.statsForAllDepots));
        this.determineGraphParameters();
      });
  }

  private determineGraphParameters(): void {
    this.statsForCurrentDepot.forEach(statsForEquipmentGroup => {
      this.highestValue = Math.max.apply(Math, statsForEquipmentGroup.map(statsForDay => {
        return Math.max.apply(Math, statsForDay.convertedValues.map(statsForTimeslot => {
          return statsForTimeslot.reduce((sum, column) => sum + column.value, 0);
        }));
      }));
    });
    this.stepSize = GraphService.determineStepSize(this.highestValue, this.highestValue, 4);
    this.numberOfSteps = GraphService.determineNumberOfSteps(this.highestValue, this.highestValue, 4);
    this.xAxisLines = GraphService.generateStepLines(this.numberOfSteps);
  }

  private convertStats(stats: any[]): any[] {
    return stats.map(statsForEquipmentGroup => {
      return statsForEquipmentGroup.map(statsForDay => {
        statsForDay.convertedValues = this.getValues(statsForDay.values, statsForDay.equipmentColors);
        statsForDay.bars = this.getBars(statsForDay.equipments, statsForDay.equipmentColors);
        statsForDay.grids = [];
        for (let i = 0; i < statsForDay.bars.length; i = i + 5) {
          statsForDay.grids.push({
            bars: statsForDay.bars.filter((item, index) => index >= i && index < i + 5),
            convertedValues: statsForDay.convertedValues
              .map(valueArray => valueArray.filter((item, index) => index >= i && index < i + 5)),
            equipments: statsForDay.equipments.filter((item, index) => index >= i && index < i + 5),
            timeslots: statsForDay.timeslots,
            unconvertedValues: statsForDay.unconvertedValues.filter((item, index) => index >= i && index < i + 5),
            values: statsForDay.values.map(valueArray => valueArray.filter((item, index) => index >= i && index < i + 5)),
          });
        }
        return statsForDay;
      });
    });
  }

  private filterStats(statsForDepots: any[]): any[] {
    // 1. Filter Depot
    const results = statsForDepots.filter(statsForDepot => {
      return this.currentDepot === statsForDepot[0][0].unconvertedValues[0].depotCode;
    });
    let stats = results.length === 1 ? results[0] : [];
    // 2. Filter Equipment Group
    stats = stats.map(statsForDepot => {
      return statsForDepot.filter(equipmentGroup => {
        return equipmentGroup.unconvertedValues[0].equipmentGroupcode.toUpperCase() === this.currentEquipmentGroupName.toUpperCase();
      });
    }).filter(days => days.length > 0);
    return stats;
  }

  public getValues(rows: any, equipmentColors: number[]): ChartColumnInterface[][] {
    return rows.map(row => {
      return row.map((column, index) => {
        return column === null ? {
          active: true,
          color: GraphService.getColorByIndex(equipmentColors[index]),
          value: 0
        } : {
          active: true,
          color: GraphService.getColorByIndex(equipmentColors[index]),
          value: column.pickupTotal + column.dropOffTotal
        };
      });
    });
  }

  public getBars(equipments: string[], equipmentColors: number[]): BookingStatsBar[] {
    return equipments.map((bar, index) => {
      return new BookingStatsBar({
        active: true,
        color: GraphService.getColorByIndex(equipmentColors[index]),
        secondName: bar,
      });
    });
  }
}
