import { Injectable } from '@angular/core';
import { progress } from '../../progress/progress.jsdo';

import { JsdoSessionService } from '../jsdo-session.service';
import { SettingsService } from '../settings.service';

@Injectable({
  providedIn: 'root'
})
export class VbsService {

  public dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              private settingsService: SettingsService) {
      this.dataSourceReady = new Promise((resolve, reject) => {
          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('dp_vbs_booking');
            resolve();
          });
      });
  }

  getData(filter) {
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
          resolve(this.dataSource.getData());
        });
      });
    });
  }

  getRecordsByTimeslot(timeslotSeqnr) {
    const filter = {'logic': 'and', 'filters': [{'field': 'timeslot_seqnr', 'operator': 'eq', 'value': timeslotSeqnr}]};
    const clientGuid = this.settingsService.getClientGuid(false);
    if (clientGuid) {
        filter.filters.push({'field': 'client_guid', 'operator': 'eq', 'value': clientGuid});
    }
    return this.getData(filter);
  }

  getRecordByVbsBookingNr(vbsBookingNr) {
    const filter = {'logic': 'and', 'filters': [{'field': 'vbs_bookingnr', 'operator': 'eq', 'value': vbsBookingNr}]};
    const clientGuid = this.settingsService.getClientGuid(false);
    if (clientGuid) {
        filter.filters.push({'field': 'client_guid', 'operator': 'eq', 'value': clientGuid});
    }
    return this.getData(filter);
  }

  addRecord(record) {

    return new Promise((resolve, reject) => {

      const newRecord = this.dataSource.add(record);
      const recId = newRecord.getId();
      this.dataSource.saveChanges(true).done((jsdo, success, request) => {
        if (success) {
          resolve(this.dataSource.findById(recId));
        }
      }).fail((jsdo, success, request) => {
        let errorString = '';
        const errors = jsdo.getErrors();
        const lenErrors = errors.length;
        let errorType;
        for (let idxError = 0; idxError < lenErrors; idxError++) {
            switch (errors[idxError].type) {
                case progress.data.JSDO.DATA_ERROR:
                    errorType = 'Server Data Error: ';
                    break;
                case progress.data.JSDO.RETVAL:
                    errorType = 'Server App Return Value: ';
                    break;
                case progress.data.JSDO.APP_ERROR:
                    errorType = 'Server App Error #' + errors[idxError].errorNum + ': ';
                    break;
                case progress.data.JSDO.ERROR:
                    errorType = 'Server General Error: ';
                    break;
                /* case default:
                    errorType = null; // Unexpected errorType value
                    break; */
            }
            if (errorType) { // log all error text
                console.log('ERROR: ' + errorType + errors[idxError].error);
                if (errors[idxError].id) { /* error with record object */
                    console.log('RECORD ID: ' + errors[idxError].id);
                    errorString = errorString + errors[idxError].error;
                }
                if (errors[idxError].responseText) {
                    console.log('HTTP FULL TEXT: '
                                  + errors[idxError].responseText);
                }
            } else { /* unexpected errorType */
                console.log('UNEXPECTED ERROR TYPE: '
                             + errors[idxError].type);
            }
        } /* for each error message */

        reject(errorString);
      });

    });
  }

  deleteRecord(Id) {
    const jsdoRecord = this.dataSource.findById(Id);
    jsdoRecord.remove();
    this.dataSource.saveChanges(true);
  }

  addLocalTar(tar, verificationCode, clientGuid) {
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.dataSource.invoke('addLocalTar', {pcTar: tar, pcVerificationCode: verificationCode, pcClientGuid: clientGuid})
            .done(function (jsdo, success, request) {
                resolve(request.response.piVBSBookingNr);
            }).fail(function(error) {
                reject(error);
            });
        });
    });
  }

}
