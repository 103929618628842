import { Injectable } from '@angular/core';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';

@Injectable({
  providedIn: 'root'
})
export class StacklocationService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('dp_stacklocation');
            resolve();
          });
      });
   }

  getData(filter) {
    console.log(filter);
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.dataSource.getData());
            });
        });
    });
  }

  getDataByStackCode(stackcode) {
    const filter = {'logic': 'and', 'filters': [{'field': 'stackcode', 'operator': 'eq', 'value': stackcode}]};
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
          this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
              resolve(this.dataSource.getData());
          });
      });
    });
  }

}
