import * as fromAccessRights from '../../store/access-rights/access-rights.reducer';
import * as fromRoot from '../../store/index';
import { DataResult, DataSource, DataSourceOptions } from '@progress/jsdo-angular';
import { Injectable } from '@angular/core';
import { JsdoSessionService } from '../jsdo-session.service';
import { progress } from '../../progress/progress.jsdo';
import { SessionService } from '../session.service';
import { Store, select } from '@ngrx/store';




@Injectable({
  providedIn: 'root'
})
export class ContainerInfoService {

  private cachedAccessRights: string[];
  private dataSource: any;
  private dataSourceReady;

  constructor(private appStore: Store<fromRoot.AppState>,
              private jsdoSessionService: JsdoSessionService,
              private sessionService: SessionService) {
    this.cacheAccessRights();

    this.dataSourceReady = new Promise((resolve, reject) => {
      this.jsdoSessionService.sessionReady.then(() => {

        // Name changed in JSDO schema as from Dec 11 2018.
        // This code can be modified to only use dp_container_info when old schema is replaced everywhere
        try {
          this.dataSource = new progress.data.JSDO({
            name: 'dp_container_info'
          });
        } catch (err) {
          this.dataSource = new progress.data.JSDO({
            name: 'dp_container'
          });
        }
        resolve();
      });
    });
  }

  private cacheAccessRights() {
    this.appStore.pipe(select(fromAccessRights.selectAccessRights)).subscribe(accessRights => {
      this.cachedAccessRights = accessRights;
    });
  }

  getContainer(containerNumber) {

    return new Promise((resolve, reject) => {
      containerNumber = containerNumber.replace(/\s/g, '');

      if ( containerNumber === 'TSTU1001015') {
        const mockedResponse = {
          'seqnr': 99999999,
          'containerprefix': 'TSTU',
          'containernumber': '1001015',
          'containertype': '40\' Dryvan',
          'terminalcode': 'TST',
          'statuscode': 'Discharged',
          'fullemptycode': 'Full',
          'cycluscode': 'Import',
          'vesselcode': 'ELBTRADER',
          'quayjob_status': '',
          'date_time_eta': '',
          'date_time_in': '10\/04\/2019 12:00',
          'date_time_out': '',
          'tim_eta': '',
          'releasecode': 'YES',
          'customsreleasecode': '',
          'availablecode': 'Yes',
          'status_color': 'GREEN',
          'estimatenr': 0,
          'attachmentnr': 0
        };
        resolve(mockedResponse);
      }


      const filter = {
        skip: 0,
        top: 0,
        containernumber: containerNumber,
        terminal: this.sessionService.company,
        name: '',
        cardId: 'null'
      };

      this.jsdoSessionService.sessionReady.then(() => {
        let dataSource;

        try {
          dataSource = new progress.data.JSDO({
            name: 'dp_container_info',
            mydata: filter
          });
        } catch (err) {
          dataSource = new progress.data.JSDO({
            name: 'dp_container',
            mydata: filter
          });
        }

        dataSource.fill(filter).done((jsdo, success, request) => {
          if (!dataSource.getData()[0].statuscode || dataSource.getData()[0].statuscode === 'Unknown container') {
            reject({ errorType: 'CONTAINER_NOT_FOUND', message: `Container ${containerNumber} was not found.` });
          }
          resolve(dataSource.getData()[0]);
        });
      });

    });

  }

  addPhoto(seqnr, data) {
    return new Promise((resolve, reject) => {
      this.dataSource.invoke('addPhoto', {piSeqnr: seqnr, pcData: data })
          .done(function (jsdo, success, request) {
              resolve(request.response);
          }).fail(function(error) {
              reject(error);
          });
    });
  }

  addEstimatePhoto(estimatenr, data) {
    return new Promise((resolve, reject) => {
      this.dataSource.invoke('addEstimatePhoto', {piEstimatenr: estimatenr, pcData: data })
          .done(function (jsdo, success, request) {
              resolve(request.response);
          }).fail(function(error) {
              reject(error);
          });
    });
  }

  // TODO: Refactor below functionality. Extract from container bussiness entity
  hasAccess(item) {
    return new Promise((resolve, reject) => {
      if (this.cachedAccessRights.includes(item)) {
        console.log('prevented another call');
        // Already access? No need to call backend again.
        resolve(true);
      } else {
        this.dataSourceReady.then(() => {
          this.dataSource.invoke('hasAccess', {pcSecurityItem: item})
            .done(function (jsdo, success, request) {
              resolve((request.response.plAccess === true || request.response.plAccess === 'true'));
            }).fail(function(error) {
                console.log('failure!');
                reject(error);
            });
        });
      }
    });
  }

  getBackendSettings() {
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.dataSource.invoke('getSettings')
          .done(function (jsdo, success, request) {
            resolve(JSON.parse(request.response.pcSettings));
          }).fail(function(error) {
              console.log('failure!');
              reject(error);
          });
      });
    });
  }


  /* Pass in a container number and get the list of matching containers on stock */
  /* used to select containers by inputting only container number. Autofill the prefix, or have the user choose the correct one */
  /* in the odd case multiple containers on stock with same number (but different prefix) */
  getStockContainersByNumber(containerNumber) {
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.dataSource.invoke('getStockContainersByNumber', {pcNumber: containerNumber.toString()})
          .done(function (jsdo, success, request) {
            const containers: Array<any> = JSON.parse(request.response.pcJsonContainers);
            resolve(containers);
          }).fail(function(error) {
              console.log('failure!');
              reject(error);
          });
      });
    });
  }

  getCodeList() {

    const paramObj = {
      pcTableName: 'dp-containertype_cust_tt',
      tt_keyvalues: {tt_keyvalues: [{fieldname: 'cust_addressnr', fieldValue: '6'}, {fieldname: 'containertype', fieldValue: '20'}]},
      cInputField: 'tons_tested',
      cInputFieldValue: '',
      cDescriptionFields: ''
    };

    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.dataSource.invoke('getCodeList', paramObj)
          .done(function (jsdo, success, request) {
            const resultList: Array<any> = JSON.parse(request.response.lcResult);
            resolve(resultList);
          }).fail(function(error) {
              console.log('failure!');
              reject(error);
          });
      });
    });
  }

}

