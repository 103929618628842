export const ACCESS_CODE_APPOINTMENT_STATS = 'appointment-stats';
export const ACCESS_CODE_DASHBOARDS = 'dashboards';
export const ACCESS_CODE_ESTIMATE = 'estimate';
export const ACCESS_CODE_EQUIPMENT_STATS = 'equipment-stats';
export const ACCESS_CODE_IMPORT_TAR = 'import-tar';
export const ACCESS_CODE_INFORMATION = 'information';
export const ACCESS_CODE_INSPECTION = 'inspection';
export const ACCESS_CODE_PHOTO = 'photo';
export const ACCESS_CODE_REDELIVERY = 'redelivery';
export const ACCESS_CODE_REEFER_INSPECTIONS = 'reefer-inspections';
export const ACCESS_CODE_REEFER_MONITORING = 'reefer-monitoring';
export const ACCESS_CODE_REEFER_ORDERS = 'reefer-orders';
export const ACCESS_CODE_QUAYJOB = 'quayjob';
