import * as actions from './container-lookup.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContainerInfoService } from 'src/app/services/jsdo-data/container-info.service';

@Injectable()
export class ContainerLookupEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly containerInfoService: ContainerInfoService,
    ) {}

    loadContainerData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.LOAD_CONTAINER_DATA),
            mergeMap(action => from(this.containerInfoService.getContainer(action.containerNumber))
                .pipe(
                    map(result => actions.RECEIVED_CONTAINER_DATA({ containerData: result })),
                    catchError((err) => of(this._containerDataErrorHandler(err)))
                )
        ))
    );

    private _containerDataErrorHandler(payload: any) {
        if (payload.errorType && payload.errorType === 'CONTAINER_NOT_FOUND') {
            return actions.ERROR({ errorType: payload.errorType, errorMessage: payload.message });
        }
        return actions.ERROR({ errorType: 'UNKNOWN', errorMessage: 'An unknown error happened' });
    }
}
