import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';

import { VbsLineService } from '../services/jsdo-data/vbs-line.service';
import { NavigationService } from '../services/navigation.service';
import { DialogService } from '../services/dialog.service';
import { ContainerInfoService } from '../services/jsdo-data/container-info.service';
import { ContainerIdErrorStateMatcher, containerIdValidator, validateContainerNumber } from '../softpak/form/container-input';
import { JsdoSessionService } from '../services/jsdo-session.service';

@Component({
  templateUrl: 'dialog-finish-appointment.component.html',
  styleUrls: ['./dialog-finish-appointment.component.scss']
})
export class DialogFinishAppointmentComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogFinishAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  templateUrl: 'dialog-container-select.component.html',
  styleUrls: ['./dialog-container-select.component.scss']
})
export class DialogContainerSelectComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogContainerSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-vbs-line',
  templateUrl: './vbs-line.component.html',
  styleUrls: ['./vbs-line.component.scss']
})
export class VbsLineComponent implements OnInit {

  @ViewChild('f', { static: false }) f;

  vbsLines;
  tabselect = 'delivery';
  vbsBookingnr;
  vbsBooking;
  kioskMode = false;

  collectionForm: FormGroup;
  collectionBookingForm: FormGroup;
  deliveryForm: FormGroup;

  containerStateErrorMatcher = new ContainerIdErrorStateMatcher();

  detailsOpen;
  collectionDetailsOpen;
  disabled = true;
  detailsReceived = false;
  deliveryContainer;
  private collectionContainer;
  enablecollectionOnBooking = false;
  enableOwnPrefix = true;
  private collectionOnBooking = false;
  readOnly = true;
  submitDisabled: Boolean;
  trailers = false;
  containerOrTrailer = "container";
  pinAlphaNumeric = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public snackBar: MatSnackBar,
              private vbsLineService: VbsLineService,
              private navigationService: NavigationService,
              private dialogService: DialogService,
              private containerService: ContainerInfoService,
              private dialog: MatDialog,
              private jsdoSessionService: JsdoSessionService) {}

  ngOnInit() {
    this.collectionForm = this.fb.group({
      container: new FormControl('', [Validators.required, validateContainerNumber]),
      pincode: ''
    });

    this.collectionBookingForm = this.fb.group({
      booking: new FormControl('', [Validators.required]),
      bookingType: new FormControl({value: '', disabled: true}),
      deliveryId: new FormControl({value: '', disabled: true}),
      fullEmpty: new FormControl({value: '', disabled: true}),
      customer: new FormControl({value: '', disabled: true}),
      containerType: new FormControl({value: '', disabled: true}),
      category: new FormControl({value: '', disabled: true})
    });

    this.navigationService.enableBackButton(true);

    this.route.paramMap.subscribe(params => {
      this.vbsBookingnr = params.get('vbsbooking');
    });

    if (this.jsdoSessionService.userSource.value && this.jsdoSessionService.userSource.value !== 'anonymous') {
      this.readOnly = false;
    }

    this.trailers = false;
    this.containerOrTrailer = "container";

    this.containerService.getBackendSettings().then((settings: any) => {
      if (settings.collectionOnBookingnumber) {
        this.enablecollectionOnBooking = true;
      }
      if (settings.enableOwnPrefix) {
        this.enableOwnPrefix = true;
      }
      if (settings.trailerDepotList) {
        let list = settings.trailerDepotList.split(",");
        if (list.includes(this.vbsBooking.depotcode)) {
          this.trailers = true;
          this.containerOrTrailer = "trailer";
          this.collectionForm.controls.container.setValidators(Validators.required);
        }
      }
      if (settings.pinAlphaNumeric)  
        this.pinAlphaNumeric = true;
    });
    
    this.route.data.subscribe(data => {
      console.log('Check route resolver data')
      console.log(data)
      this.vbsBooking = data.vbsBooking[0];
      this.navigationService.setLabel('TAR: ' + this.vbsBooking.vbs_reference);

    })

    this.route.params.pipe(switchMap((params: Params) => this.vbsLineService.getRecordsByBookingSeqnr(params['vbsbooking'])))
      .subscribe(vbsLines => this.vbsLines = vbsLines);
  }

  refreshLines() {
    this.vbsLineService.getRecordsByBookingSeqnr(this.vbsBookingnr).then(vbsLines => this.vbsLines = vbsLines);
  }

  collectionNumberChanged() {
    this.collectionContainer = null;
  }

  switchCollectionMode(mode) {
    if (mode === 'BOOKING') { this.collectionOnBooking = true; }
    if (mode === 'CONTAINER') { this.collectionOnBooking = false; }
  }

  onSubmitCollection(form) {
    // const container = this.collectionForm.value.container.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    const containerNumber = this.collectionForm.value.container;
    let container;

    this.submitDisabled = true;
    this.containerService.getStockContainersByNumber(containerNumber).then( (containers: Array<any>) => {

      if (containers.length === 1) {
        container = containers[0];
        this.saveCollection(container, form);
      } else if ( containers.length > 1) {
        // TODO: Show popup to select the right one
        const dialogRef = this.dialog.open(DialogContainerSelectComponent, {
          width: '300px',
          data: {containers: containers}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.collectionContainer = result;
            this.saveCollection(this.collectionContainer, form);
          }
        });

      } else {
        this.submitDisabled = false;
        if (this.trailers) {
          this.dialogService.alert('Trailer not on stock', () => {});
        } else {
          this.dialogService.alert('Container not on stock', () => {});
        }
      }

    });
  }

  checkCollectionBooking() {
    const collectionBooking = this.collectionBookingForm.value.booking;

    this.vbsLineService.getBookingDetails(collectionBooking, 'OUT').then((data: any) => {
      this.containerService.getBackendSettings().then(settings => {

        if (data.pcErrorMessage) {
          this.dialogService.alert(data.pcErrorMessage, () => {});
        } else {

          this.collectionDetailsOpen = true;
          this.collectionBookingForm.controls['booking'].disable();

          this.collectionBookingForm.controls['bookingType'].setValue(data.pcBookingTypeDes);
          this.collectionBookingForm.controls['fullEmpty'].setValue(data.pcFullEmptyDes);
          this.collectionBookingForm.controls.customer.setValue(data.pcCustomer);
          this.collectionBookingForm.controls.deliveryId.setValue(data.piDeliveryId);
          this.collectionBookingForm.controls.containerType.setValue(data.pcContainerTypeDes);
          this.collectionBookingForm.controls.category.setValue(data.pcCategoryDes);

        }
      });
    });
  }

  onSubmitBookingCollection(form) {

     const record = {
      companycode: '',
      vbs_bookingnr: this.vbsBookingnr,
      inoutcode: 'OUT',
      calc_container: '',
      input_pincode: '',
      container_seqnr: 0,
      deliveryid: this.collectionBookingForm.value.deliveryId
    };

    this.submitDisabled = true;
    this.vbsLineService.addRecord(record).then((newData) => {
      this.onResetPickup(form);
      this.collectionContainer = null;
      this.snackBar.open('Pick-up added!', '', {duration: 1000});
      this.vbsLines = newData;
    }, err => {
      this.dialogService.alert(err, () => {});
    }).then( () => {
      this.submitDisabled = false;
    });
  }

  onResetPickup(form) {
    form.resetForm();
    this.collectionBookingForm.controls['booking'].enable();
    this.collectionDetailsOpen = false;
  }

  saveCollection(container, form) {

    const record = {
      companycode: '',
      vbs_bookingnr: this.vbsBookingnr,
      inoutcode: 'OUT',
      calc_container: container,
      input_pincode: this.collectionForm.value.pincode
    };

    this.vbsLineService.addRecord(record).then((newData) => {

      this.collectionForm .reset();
      form.resetForm({
         container: {value: '', disabled: false},
         pincode: {value: '', disabled: false}
      });

      this.collectionContainer = null;
      this.snackBar.open('Pick-up added!', '', {duration: 1000});
      this.vbsLines = newData;
    }, err => {
      this.dialogService.alert(err, () => {});
    }).then( () => {
      this.submitDisabled = false;
    });
  }

  deleteLine(id) {
    this.vbsLineService.deleteRecordById(id);
  }

  setCollectionDelivery(choice) {
    this.tabselect = choice;
  }

  gotoContainerInfo(container) {
    if (container && !this.trailers) {
      this.router.navigate(['containerinfo', container]);
    }
  }

  confirmFinishBooking() {

    const dialogRef = this.dialog.open(DialogFinishAppointmentComponent, {
      width: '300px',
      data: {tar: '0000'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.finishBooking();
        // this.saveCollection(this.collectionContainer, form);
      }
    });

  }

  finishBooking() {
    // this.dialogService.
  }
}
