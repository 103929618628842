import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { SettingsService } from '../settings.service';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';

@Injectable({
  providedIn: 'root'
})
export class QuayjobService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              private http: HttpClient,
              private settingsService: SettingsService) {
    this.dataSourceReady = new Promise((resolve, reject) => {
      this.jsdoSessionService.sessionReady.then(() => {
        this.dataSource = new progress.data.JSDO('dp_quayjob_info');
        resolve();
      });
    });
  }

  /* "dsQuayjobInfo": {
      "tt-dp-quayjob" */

  getQuayjobs(filter?) {
    return new Promise<any>((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.dataSource.fill(filter).done((jsdo, success, request) => {
          let data = this.dataSource.getData();

          data.forEach(el => {
            el.calc_totalIn = el.calc_nr_preadvised + el.calc_nr_discharged;
            const perc = Math.floor((100 / (el.calc_totalIn + el.calc_nr_out)) * (el.calc_nr_loaded + el.calc_nr_discharged)) ;
            el.percentage_complete = perc;
            el.fill_color = 'green';

            if (el.overdue > (180 * 60)) {
              el.calc_fill_code = 'Severe';
            } else if (el.overdue > 0) {
              el.calc_fill_code = 'Warning';
            } else if (el.calc_nr_loaded + el.calc_nr_discharged > 0) {
              el.calc_fill_code = 'Ok';
            }
          });

          data = data.sort(function(a, b) {
            return a.priority - b.priority;
          });
          resolve(data);

        });
      });
    });
  }
}
