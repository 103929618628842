import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from '../services/settings.service';
import { NavigationService } from '../services/navigation.service';
import { SessionService } from '../services/session.service';
import { ConnectionService } from '../services/connection.service';
import { DevicePushService } from '../services/jsdo-data/device-push.service';
import { DialogService } from '../services/dialog.service';
import { ROUTING_KEY_ABOUT, ROUTING_KEY_LOGGING } from '../softpak/menu/app-menu-items.const';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public selectedCompany;
  public showAdvanced;
  public showDeveloper;
  public connections = [];
  public custom_connection_uri;
  public custom_connection_name;
  public custom_connection_company;
  public custom_connection_des;

  private advancedCounter = 0;
  private developerCounter = 0;
  private privateConnectionCode;
  private devServer;

  constructor(private settingsService: SettingsService,
              private navigationService: NavigationService,
              private sessionService: SessionService,
              private connectionService: ConnectionService,
              private devicePushService: DevicePushService,
              private dialogService: DialogService,
              private router: Router,
              private snackBar: MatSnackBar,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.navigationService.setLabel('Settings');
    this.selectedCompany = SettingsService.getSetting('company');
    this.showAdvanced = SettingsService.getSetting('advancedSettings');
    this.showDeveloper = SettingsService.getSetting('developerSettings');
    this.devServer = SettingsService.getSetting('updateServer');

    this.initConnections();
  }

  initConnections() {
    this.settingsService.brandCode.then(brandCode => {
      this.connections = this.connectionService.getConnectionList(brandCode);
    });
  }

  onSaveSettings() {
    this.sessionService.setCompany(this.selectedCompany);
    alert('Settings saved!');
  }

  clearWelcome() {
    if (confirm('This will clear all your settings! Are you sure?') && confirm('This will clear all your settings! Are you really sure?')) {
      localStorage.clear();
      location.reload();
    }
  }

  advanced() {
    this.advancedCounter++;
    console.log(this.advancedCounter);
    if (this.advancedCounter >= 3) {
      this.advancedCounter = 0;
      if (!this.showAdvanced) {
        this.showAdvanced = true;
        SettingsService.setSetting('advancedSettings', 'YES');
        this.snackBar.open('Advanced options enabled', '', {duration: 1000});
      } else {
        this.showAdvanced = false;
        SettingsService.setSetting('advancedSettings', '');
      }
    }
  }

  developer() {
    this.developerCounter++;
    console.log(this.developerCounter);
    if (this.developerCounter >= 3) {
      this.developerCounter = 0;
      if (!this.showDeveloper) {
        this.showDeveloper = true;
        SettingsService.setSetting('developerSettings', 'YES');
        this.snackBar.open('Developer options enabled', '', {duration: 1000});
      } else {
        this.showDeveloper = false;
        SettingsService.setSetting('developerSettings', '');
      }
    }
  }

  addPrivateConnection() {
    if (!this.privateConnectionCode) {
        alert('Please input a private connection code');
    } else {
      this.privateConnectionCode = this.privateConnectionCode.toUpperCase();
      if (this.privateConnectionCode === 'KB') {
          this.settingsService.putArraySetting('privateConnections', 'KB');
          alert('Connection now available');
          this.privateConnectionCode = '';
          this.initConnections();
      } else if (this.privateConnectionCode === 'RCC') {
          this.settingsService.putArraySetting('privateConnections', 'RCC');
          alert('Connection now available');
          this.privateConnectionCode = '';
          this.initConnections();
      } else if (this.privateConnectionCode === 'CETEM') {
          this.settingsService.putArraySetting('privateConnections', 'CETEM');
          alert('Connection now available');
          this.privateConnectionCode = '';
          this.initConnections();          
      } else if (this.privateConnectionCode === 'SP') {
        this.settingsService.putArraySetting('privateConnections', 'SP');
        alert('Connection now available');
        this.privateConnectionCode = '';
        this.initConnections();
      } else {
          alert('Invalid private connection code');
      }
    }
  }

  testPushMessage() {
    // tslint:disable-next-line:max-line-length
    const message = 'One push message will be send immediately, a second message will be processed by the scheduler\n\n\n' + 'Push token: ' + this.devicePushService.pushToken;
    this.dialogService.alert(message, () => {
      this.devicePushService.testPushNotification().then(() => {
        this.ngZone.run(() => {
          this.snackBar.open('Push notifications requested', '', {duration: 1000});
        });
      }, error => {
        alert(error);
      });
    }, 'Info');
  }

  subscribePushTopic() {
    try {
      let result = this.devicePushService.subscribeTopic("testtopic");
      alert( result);
    }
    catch(err) {
      alert(err);
    }
  }

  unSubscribePushTopic() {
    try {
      let result = this.devicePushService.unSubscribeTopic("testtopic");
      alert( result);
    }
    catch(err) {
      alert(err);
    }
  }

  onGetUpdate() {
    this.router.navigate(['update', { force: true}]);
  }

  onSaveUpdateServer() {
    SettingsService.setSetting('updateServer', this.devServer);
    SettingsService.setSetting('installedUpdate', 0);
    alert('Dev server saved!');
  }

  onTest() {
    this.devServer = 'https://softpak.info/download/app_update_test/proterm';
    this.onSaveUpdateServer();
  }

  onClear() {
    this.devServer = '';
    this.onSaveUpdateServer;
  }

  onAddCustomConnection() {
    if ( this.custom_connection_company &&
         this.custom_connection_uri &&
         this.custom_connection_name &&
         this.custom_connection_des) {

      this.connectionService.addCustomConnection(this.custom_connection_name,
                                                 this.custom_connection_uri,
                                                 this.custom_connection_des,
                                                 this.custom_connection_company);
      this.initConnections();
      alert('Connection added!');

    } else {
      alert('Please fill in all fields for custom connection!');
    }
  }

  goToAbout(): void {
    this.router.navigate(['/' + ROUTING_KEY_ABOUT]);
  }

  goToLogging(): void {
    this.router.navigate(['/' + ROUTING_KEY_LOGGING]);
  }
}
