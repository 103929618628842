import { createAction, props, union } from '@ngrx/store';
// tslint:disable: max-line-length

export const ERROR = createAction('[Booking Lookup] Error', props<{ errorMessage: string, errorType: string }>());
export const LOAD_BOOKING_DATA = createAction('[Booking Lookup] Load Booking Data', props<{ bookingNumber: string }>());
export const RECEIVED_BOOKING_DATA = createAction('[Booking Lookup] Received Booking Data', props<{ bookingsData: unknown[] }>());
export const RESET = createAction('[Booking Lookup] Reset', props<{}>());

const all = union({
    ERROR,
    LOAD_BOOKING_DATA,
    RECEIVED_BOOKING_DATA,
    RESET,
});
export type BookingLookupActions = typeof all;
