import { Injectable } from '@angular/core';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';
import { SettingsService } from '../settings.service';

@Injectable({
  providedIn: 'root'
})
export class TimeslotService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              private settingsService: SettingsService) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('dp_vbs_timeslot');
            resolve();
          });
      });
   }

  getTimeSlots(depotcode?) {
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            const filter = {'logic': 'and', 'filters': []};
            const clientGuid = this.settingsService.getClientGuid(false);
            if (clientGuid) {
                filter.filters.push({'field': 'client_guid', 'operator': 'eq', 'value': clientGuid});
            }
            if (depotcode) {
              filter.filters.push({'field': 'depotcode', 'operator': 'eq', 'value': depotcode});
            }
            this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.dataSource.getData());
            });
        });
    });
  }

  getTimeSlotByTimeslotSeqnr(timeslotSeqnr) {
    const filter = {'logic': 'and', 'filters': [{'field': 'timeslot_seqnr', 'operator': 'eq', 'value': parseInt(timeslotSeqnr, 10)}]};
    return new Promise<Array<any>>((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.dataSource.getData());
            });
        });
    });
  }
}
