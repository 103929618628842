import { AppComponent, GroupByPipe } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AttachmentComponent } from './attachment/attachment.component';
import { AttachmentPhotoComponent } from './attachment-photo/attachment-photo.component';
import { BlockTitleModule } from './softpak/block-title/block-title.module';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingLookupEffects } from './store/booking-lookup/booking-lookup.effects';
import { BookingStatsEffects } from './store/booking-stats/booking-stats.effects';
import { BookingStatsModule } from './booking-stats/booking-stats.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ContainerComponent } from './container/container.component';
import { ContainerInfoComponent } from './container-info/container-info.component';
import { ContainerLookupEffects } from './store/container-lookup/container-lookup.effects';
import { CoreModule } from './softpak/core/core.module';
import { DayTextPipeModule } from './softpak/day-text/day-text.module';
import { DepotSettingsEffects } from './store/settings/depot-settings/depot-settings.effects';
import { DetailBlockComponent } from './softpak/detail-block/detail-block.component';
import { DialogContainerSelectComponent, DialogFinishAppointmentComponent, VbsLineComponent } from './vbs-line/vbs-line.component';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { EquipmentStatsDetailEffects } from './store/equipment-stats-detail/equipment-stats-detail.effects';
import { EquipmentStatsEffects } from './store/equipment-stats/equipment-stats.effects';
import { EquipmentStatsModule } from './equipment-stats/equipment-stats.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './softpak/home/home.component';
import { HomeSearchComponent } from './home-search/home-search.component';
import { HttpClientModule } from '@angular/common/http';
import { ImportTarAddedComponent } from './import-tar-added/import-tar-added.component';
import { ImportTarComponent } from './import-tar/import-tar.component';
import { InfoComponent } from './softpak/info/info.component';
import { JsdoSessionService } from './services/jsdo-session.service';
import { KioskVisitComponent } from './kiosk-visit/kiosk-visit.component';
import { LoginComponent } from './softpak/login/login.component';
import { MainComponent } from './softpak/main/main.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MenuComponent } from './softpak/menu/menu.component';
import { metaReducers, reducers } from './store';
import { NavigationComponent } from './softpak/navigation/navigation.component';
import { NavigationService } from './services/navigation.service';
import { Injectable, NgModule } from '@angular/core';
import { PhotoComponent } from './photo/photo.component';
import { PullModule } from './softpak/pull/pull.module';
import { QuayjobBayplanComponent } from './quayjob-bayplan/quayjob-bayplan.component';
import { QuayjobComponent } from './quayjob/quayjob.component';
import { QuayjobListComponent } from './quayjob-list/quayjob-list.component';
import { RecentSearchesComponent } from './recent-searches/recent-searches.component';
import { ReeferConnectionComponent } from './reefer-connection/reefer-connection.component';
import { ReeferMonitoringDetailsComponent } from './reefer-monitoring/reefer-monitoring-details/reefer-monitoring-details.component';
import { ReeferMonitoringSelectComponent } from './reefer-monitoring/reefer-monitoring-select/reefer-monitoring-select.component';
import { ReeferMonitoringStackComponent } from './reefer-monitoring/reefer-monitoring-stack/reefer-monitoring-stack.component';
import { SessionService } from './services/session.service';
import { SettingsComponent } from './settings/settings.component';
import { SettingsService } from './services/settings.service';
import { SpinnerComponent } from './softpak/spinner/spinner.component';
import { SpinnerModuleV1 } from 'softpak-frontend-spinner';
import { SpinnerServiceModuleV1 } from 'softpak-frontend-spinner-service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { SuggestionEffects } from './store/suggestions/suggestions.effects';
import { TimeslotDetailsComponent } from './timeslot-details/timeslot-details.component';
import { TimeslotsComponent } from './timeslots/timeslots.component';
import { TimeslotService } from './services/jsdo-data/timeslot.service';
import { ToastsDisplayerModuleV1 } from 'softpak-frontend-toasts-displayer';
import { UpdateComponent } from './softpak/update/update.component';
import { VbsBookingCardComponent } from './vbs-booking-card/vbs-booking-card.component';
import { VbsLineDropoffComponent } from './vbs-line/vbs-line-dropoff.component';
import { WelcomeComponent } from './softpak/welcome/welcome.component';
import { WindWidgetComponent } from './wind-widget/wind-widget.component';
import { WrapperModule } from './softpak/wrapper/wrapper.module';
import { AccessRightsEffects } from './store/access-rights/access-rights.effects';
import { AlertModuleV1 } from 'softpak-frontend-alert';
import { ButtonModuleV1 } from 'softpak-frontend-button';
import { InformationEffects } from './store/information/information.effects';
import { TimeslotTerminalSelectComponent } from './timeslot-terminal-select/timeslot-terminal-select.component';
import { ErrorHandler } from '@angular/core';
import { AppLoggingComponent } from './app-logging/app-logging.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  handleError(error) {
    console.log('catched', error);
    // Retrieve from local storage
    let log = [];
    let logStr = localStorage.getItem("errorLog");
    if (logStr) {
      log = JSON.parse(logStr);
    }
  
    // Max length
    if (log.length > 100) {
     log.shift(); 
    }
    
    log.push(error.toString());
  
    localStorage.setItem('errorLog', JSON.stringify(log));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeSearchComponent,
    ContainerInfoComponent,
    TimeslotsComponent,
    NavigationComponent,
    GroupByPipe,
    RecentSearchesComponent,
    TimeslotDetailsComponent,
    VbsLineComponent,
    VbsLineDropoffComponent,
    LoginComponent,
    InfoComponent,
    SettingsComponent,
    WelcomeComponent,
    PhotoComponent,
    MainComponent,
    ReeferConnectionComponent,
    UpdateComponent,
    DialogContainerSelectComponent,
    DialogFinishAppointmentComponent,
    AttachmentComponent,
    AttachmentPhotoComponent,
    SpinnerComponent,
    MenuComponent,
    HomeComponent,
    QuayjobListComponent,
    WindWidgetComponent,
    QuayjobComponent,
    QuayjobBayplanComponent,
    KioskVisitComponent,
    ImportTarComponent,
    ImportTarAddedComponent,
    VbsBookingCardComponent,
    BookingDetailsComponent,
    DetailBlockComponent,
    ContainerComponent,
    ReeferMonitoringSelectComponent,
    ReeferMonitoringStackComponent,
    ReeferMonitoringDetailsComponent,
    TimeslotTerminalSelectComponent,
    AppLoggingComponent,
  ],
  imports: [
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 500, // Retains last 100 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    BlockTitleModule,
    BookingStatsModule,
    BrowserModule,
    DayTextPipeModule,
    EffectsModule.forRoot([
      AccessRightsEffects,
      BookingLookupEffects,
      BookingStatsEffects,
      ContainerLookupEffects,
      // DepotSettingsEffects,
      EquipmentStatsDetailEffects,
      EquipmentStatsEffects,
      InformationEffects,
      // SuggestionEffects,
    ]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatTabsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SpinnerModuleV1,
    SpinnerServiceModuleV1.forRoot(),
    ToastsDisplayerModuleV1,
    WrapperModule,
    CoreModule,
    PullModule,
    MatExpansionModule,
    EquipmentStatsModule,
    AlertModuleV1,
    ButtonModuleV1,
  ],
  entryComponents: [
    DialogContainerSelectComponent,
    DialogFinishAppointmentComponent,
    VbsLineComponent,
  ],
  providers: [
    JsdoSessionService,
    NavigationService,
    SessionService,
    SettingsService,
    TimeslotService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
