import { BookingStatsDayComponent } from './components/booking-stats-day/booking-stats-day.component';
import { BookingStatsFiltersComponent } from './compounds/booking-stats-filters/booking-stats-filters.component';
import { BookingStatsGridHeaderComponent } from './components/booking-stats-grid-header/booking-stats-grid-header.component';
import { BookingStatsHomeComponent } from './compounds/booking-stats-home/booking-stats-home.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../softpak/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core';
import { RefreshBarModule } from '../softpak/refresh-bar/refresh-bar.module';
import { SPChartModule } from '../softpak/charts/chart.module';
import { SpinnerModuleV1 } from 'softpak-frontend-spinner';
import { WrapperModule } from '../softpak/wrapper/wrapper.module';
import { DepotSelectorModule } from '../softpak/depot-selector/depot-selector.module';
import { BlockTitleModule } from '../softpak/block-title/block-title.module';
import { DayTextPipeModule } from '../softpak/day-text/day-text.module';

@NgModule({
    declarations: [
        BookingStatsDayComponent,
        BookingStatsFiltersComponent,
        BookingStatsGridHeaderComponent,
        BookingStatsHomeComponent,
    ],
    imports: [
        BlockTitleModule,
        CommonModule,
        CoreModule,
        DayTextPipeModule,
        DepotSelectorModule,
        FormsModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        RefreshBarModule,
        SPChartModule,
        SpinnerModuleV1,
        WrapperModule,
    ]
})
export class BookingStatsModule { }
