import { Injectable } from '@angular/core';
import { CordovaService } from './cordova.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private cordovaService: CordovaService) { }

  // Simple alert message with one button
  alert (message, callBack, title = 'Alert') {
    if (this.cordovaService.onCordova) {
       // Device native alert
      this.cordovaService.notification.alert(message, callBack, title);
    } else {
      // Javascript alert
      alert(message);
      if (callBack) {
        callBack();
      }
    }
  }

  // OK/Cancel dialog
  confirm(message, confirmCallback, title) {
    let lResult;
    if (this.cordovaService.onCordova) {
      this.cordovaService.notification.confirm(message, (buttonIndex) => {
        // buttonIndex = 1 based index . default = ['Ok', 'Cancel'] regardless of position on screen (Android/IOS)
        // so 1 = OK, 2 = Cancel, 0 = no selection!
        if (buttonIndex === 1) {
          lResult = true;
        } else {
          lResult = false;
        }
        confirmCallback(lResult);
      }, title
      );
    } else {
      lResult = window.confirm(message);
      confirmCallback(lResult);
    }
  }
}
