
import {fromEvent as observableFromEvent,  Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';



function _window(): any {
  // return the global native browser window object
  return window;
 }

 function _navigator(): any {
  // return the global native browser navigator object
  return navigator;
 }

@Injectable({
  providedIn: 'root'
})
export class CordovaService {

  FirebasePlugin;
  notification;
  splashscreen;
  camera;

  private resume: BehaviorSubject<boolean>;

  constructor(private zone: NgZone) {
      this.resume = new BehaviorSubject<boolean>(null);
      observableFromEvent(document, 'resume').subscribe(event => {
         this.zone.run(() => {
            this.onResume();
         });
      });

      if (this.onCordova) {
        
        this.FirebasePlugin = _window().FirebasePlugin;
        this.notification = _navigator().notification;
        this.splashscreen = _navigator().splashscreen;
        this.camera = _navigator().camera;
      }
  }

  get cordova(): any {
     return _window().cordova;
  }

  get onCordova(): Boolean {
    return !!_window().cordova;
  }

  public onResume(): void {
      this.resume.next(true);
  }
}
