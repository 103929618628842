import * as actions from './booking-stats.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';
import { BookingStatsFiltersInterface } from 'src/app/booking-stats/models/booking-stats-filters.interface';
import { BookingStatsSettingsService } from 'src/app/services/settings-booking-stats.service';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';

const thisReducer = (state: AppState): BookingStatsState => state.bookingStats;

export interface BookingStatsState {
    bookingStatsData: unknown;
    bookingStatsError: { errorMessage: string, errorType: string };
    bookingStatsLoading: boolean;
    bookingStatsReceiveTime: string;
    bookingStatsFilters: BookingStatsFiltersInterface;
}

export const initialState: BookingStatsState = {
    bookingStatsData: null,
    bookingStatsError: null,
    bookingStatsLoading: false,
    bookingStatsReceiveTime: null,
    bookingStatsFilters: {
        splitFullEmpty: true,
        splitImportExport: true,
        showFull: true,
        showEmpty: true,
        showExport: true,
        showImport: true,
        showImportFull: true,
        showExportFull: true,
        showImportEmpty: true,
        showExportEmpty: true,
        showHistory: false,
    }
};

const handleReducer = createReducer(
    initialState,
    on(actions.ERROR, (state: BookingStatsState, { errorMessage, errorType }): BookingStatsState => {
        return {
            ...state,
            bookingStatsData: null,
            bookingStatsError: { errorMessage, errorType },
            bookingStatsLoading: false,
            bookingStatsReceiveTime: null,
        };
    }),
    on(actions.RESET, (state: BookingStatsState): BookingStatsState => {
        return {
            ...state,
            bookingStatsData: null,
            bookingStatsError: null,
            bookingStatsLoading: false,
            bookingStatsReceiveTime: null,
        };
    }),
    on(actions.LOAD_BOOKING_STATS, (state: BookingStatsState): BookingStatsState => {
        return {
            ...state,
            bookingStatsLoading: true,
        };
    }),
    on(actions.RECEIVED_BOOKING_STATS, (state: BookingStatsState, { bookingStatsData }): BookingStatsState => {
        return {
            ...state,
            bookingStatsData,
            bookingStatsLoading: false,
            bookingStatsReceiveTime: new Date().toISOString(),
        };
    }),
    on(actions.UPDATE_FILTERING, (state: BookingStatsState, {
        filters
    }): BookingStatsState => {
        BookingStatsSettingsService.setFilters(filters);
        return {
            ...state,
            bookingStatsFilters: filters
        };
    }),
);

export function reducer(state: BookingStatsState, action: actions.BookingStatsActions): BookingStatsState {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: BookingStatsState) => state
);

export const selectBookingStatsData = createSelector(
    thisReducer, (state: BookingStatsState) => state.bookingStatsData
);

export const selectBookingStatsReceiveTime = createSelector(
    thisReducer, (state: BookingStatsState) => state.bookingStatsReceiveTime
);

export const selectBookingStatsLoading = createSelector(
    thisReducer, (state: BookingStatsState) => state.bookingStatsLoading
);

export const selectBookingStatsFilters = createSelector(
    thisReducer, (state: BookingStatsState) => state.bookingStatsFilters
);
