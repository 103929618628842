import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pull',
  templateUrl: './pull.component.html',
  styleUrls: ['./pull.component.scss']
})
export class PullComponent implements OnInit {

  validStart = null;
  tresholdReached = false;
  divStyle = 0;
  startX = null;
  startY = null;
  x = 0;
  y = 0;

  @Output() released = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  myTouchStart(event) {
    console.log(event);
    console.log(window.scrollY);
    // Track the start Y coordinate so we can measure the difference in the touchMove event
    if (window.scrollY === 0) {
      this.startY = Math.floor(event.targetTouches[0].clientY);
      this.x = 0;
      this.y = 0;
      this.validStart = true;
    }
  }

  myTouchMove(event) {
    if (this.validStart) {
      // console.log(event);
      const y = event.targetTouches[0].clientY;
      const dif = y - this.startY;
      // Non linear movement would be nice
      const move = (dif / 2);
      this.divStyle = Math.round(move);
      this.y = Math.round(move);

      // Treshold before considered tresholdReached
      if (this.y > 20) {
        this.tresholdReached = true;
      }
    }
  }

  myTouchEnd(event) {
    // console.log(event);
    this.x = 0;
    this.y = 0;
    this.divStyle = this.y;
    if (this.tresholdReached) {
      this.released.emit(true);
      this.tresholdReached = false;
    }
    this.validStart = null;
  }

}
