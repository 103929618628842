import { Component, Input, OnInit } from '@angular/core';
import { DetailBlockStatus } from './detail-block-status.enum';

@Component({
  selector: 'app-detail-block',
  templateUrl: './detail-block.component.html',
  styleUrls: ['./detail-block.component.scss']
})
export class DetailBlockComponent implements OnInit {
  @Input() label: string;
  @Input() status: DetailBlockStatus;

  constructor() { }

  ngOnInit() {
  }

}
