import * as fromAccessRights from './access-rights/access-rights.reducer';
import * as fromBookingLookup from './booking-lookup/booking-lookup.reducer';
import * as fromBookingStats from './booking-stats/booking-stats.reducer';
import * as fromContainerLookup from './container-lookup/container-lookup.reducer';
import * as fromDepotSettings from './settings/depot-settings/depot-settings.reducer';
import * as fromEquipmentStats from './equipment-stats/equipment-stats.reducer';
import * as fromEquipmentStatsDetail from './equipment-stats-detail/equipment-stats-detail.reducer';
import * as fromInformation from './information/information.reducer';
import * as fromInspection from './inspection/inspection.reducer';
import * as fromSettings from './settings/settings.reducer';
import * as fromSpinnerService from 'softpak-frontend-spinner-service';
import * as fromStorage from './local-storage/storage.reducer';
import * as fromSuggestions from './suggestions/suggestions.reducer';
import * as fromToaster from 'softpak-frontend-toaster';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

export interface AppState {
    accessRights: fromAccessRights.State;
    bookingLookup: fromBookingLookup.BookingLookupState;
    bookingStats: fromBookingStats.BookingStatsState;
    containerLookup: fromContainerLookup.ContainerLookupState;
    depotSettings: fromDepotSettings.DepotSettingsState;
    equipmentStats: fromEquipmentStats.EquipmentStatsState;
    equipmentStatsDetail: fromEquipmentStatsDetail.EquipmentStatsDetailState;
    information: fromInformation.State;
    inspection: fromInspection.InspectionState;
    settings: fromSettings.SettingsState;
    spinner: fromSpinnerService.SpinnerServiceState;
    storage: fromStorage.StorageState;
    suggestions: fromSuggestions.SuggestionsState;
    toaster: fromToaster.ToasterState;
}

export const reducers: ActionReducerMap<AppState> = {
    accessRights: fromAccessRights.reducer,
    bookingLookup: fromBookingLookup.reducer,
    bookingStats: fromBookingStats.reducer,
    containerLookup: fromContainerLookup.reducer,
    depotSettings: fromDepotSettings.reducer,
    equipmentStats: fromEquipmentStats.reducer,
    equipmentStatsDetail: fromEquipmentStatsDetail.reducer,
    information: fromInformation.reducer,
    inspection: fromInspection.reducer,
    settings: fromSettings.reducer,
    spinner: fromSpinnerService.reducer,
    storage: fromStorage.reducer,
    suggestions: fromSuggestions.reducer,
    toaster: fromToaster.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = [];
