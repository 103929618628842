import { createAction, props, union } from '@ngrx/store';
import { BookingStatsFiltersInterface } from 'src/app/booking-stats/models/booking-stats-filters.interface';
// tslint:disable: max-line-length

export const ERROR = createAction('[Booking Stats] Error', props<{ errorMessage: string, errorType: string }>());
export const LOAD_BOOKING_STATS = createAction('[Booking Stats] Load Booking Stats', props<{ }>());
export const RECEIVED_BOOKING_STATS = createAction('[Booking Stats] Received Booking Stats', props<{ bookingStatsData: unknown }>());
export const RESET = createAction('[Booking Stats] Reset', props<{}>());
export const UPDATE_FILTERING = createAction('[Booking Stats] Update Filtering', props<{
    filters: BookingStatsFiltersInterface
}>());

const all = union({
    ERROR,
    LOAD_BOOKING_STATS,
    RECEIVED_BOOKING_STATS,
    RESET,
    UPDATE_FILTERING
});
export type BookingStatsActions = typeof all;
