import { AlertModuleV1 } from 'softpak-frontend-alert';
import { BlockTitleModule } from '../softpak/block-title/block-title.module';
import { CommonModule } from '@angular/common';
import { DepotSelectorModule } from '../softpak/depot-selector/depot-selector.module';
import { EquipmentStatsFiltersComponent } from './components/equipment-stats-filters/equipment-stats-filters.component';
import { EquipmentStatsGraphComponent } from './components/equipment-stats-graph/equipment-stats-graph.component';
import { EquipmentStatsHomeComponent } from './compounds/equipment-stats-home/equipment-stats-home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule, MatCardModule, MatButtonModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { RefreshBarModule } from '../softpak/refresh-bar/refresh-bar.module';
import { SPChartModule } from '../softpak/charts/chart.module';
import { SpinnerModuleV1 } from 'softpak-frontend-spinner';
import { WrapperModule } from '../softpak/wrapper/wrapper.module';
import { EquipmentStatsDetailComponent } from './compounds/equipment-stats-detail/equipment-stats-detail.component';
import { EquipmentStatsDetailGraphComponent } from './components/equipment-stats-detail-graph/equipment-stats-detail-graph.component';
import { RouterModule } from '@angular/router';
import { EquipmentStatsSingleComponent } from './compounds/equipment-stats-single/equipment-stats-single.component';
import { EquipmentStatsSingleGraphComponent } from './components/equipment-stats-single-graph/equipment-stats-single-graph.component';
import { DayTextPipeModule } from '../softpak/day-text/day-text.module';
import { EquipmentStatsGridHeaderComponent } from './components/equipment-stats-grid-header/equipment-stats-grid-header.component';

@NgModule({
  declarations: [
    EquipmentStatsHomeComponent,
    EquipmentStatsFiltersComponent,
    EquipmentStatsGraphComponent,
    EquipmentStatsDetailComponent,
    EquipmentStatsDetailGraphComponent,
    EquipmentStatsGridHeaderComponent,
    EquipmentStatsSingleComponent,
    EquipmentStatsSingleGraphComponent,
  ],
  imports: [
    AlertModuleV1,
    BlockTitleModule,
    CommonModule,
    DayTextPipeModule,
    DepotSelectorModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RefreshBarModule,
    RouterModule,
    SPChartModule,
    SpinnerModuleV1,
    WrapperModule,
  ]
})
export class EquipmentStatsModule { }
