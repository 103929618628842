import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VbsLineService } from '../services/jsdo-data/vbs-line.service';
import { DialogService } from '../services/dialog.service';
import { ContainerInfoService } from '../services/jsdo-data/container-info.service';
import { ContainerIdErrorStateMatcher, containerIdValidator, validateContainerNumber } from '../softpak/form/container-input';

@Component({
  selector: 'app-vbs-line-dropoff',
  templateUrl: './vbs-line-dropoff.component.html',
  styleUrls: ['./vbs-line-dropoff.component.scss']
})
export class VbsLineDropoffComponent implements OnInit {

  @Input() vbsBookingnr;
  @Input() vbsBooking;

  @Output() lineAdded: EventEmitter<any> = new EventEmitter();

  @ViewChild('form', { static: true }) f;
  @ViewChild('bookingInput', { static: true }) bookingInput;

  enableOwnPrefix = false;
  dropoffFormGroup: FormGroup;
  containerChecked;
  fullEmptyChecked;
  bookingChecked;
  submitDisabled: Boolean;
  trailers = false;
  containerOrTrailer = "container";

  vbsLines;

  containerStateErrorMatcher = new ContainerIdErrorStateMatcher();

  disabled = true;
  detailsReceived = false;


  constructor(private fb: FormBuilder,
              public snackBar: MatSnackBar,
              private vbsLineService: VbsLineService,
              private dialogService: DialogService,
              private containerService: ContainerInfoService,
              private dialog: MatDialog) {}

  ngOnInit() {
    this.dropoffFormGroup = this.fb.group({
      container: new FormControl({value: '', disabled: false}, [Validators.required, containerIdValidator]),
      booking: new FormControl({value: '', disabled: true}, Validators.required),
      fullEmpty: new FormControl({value: '', disabled: true}),
      isotype: new FormControl({value: '', disabled: false}, Validators.required),
      grossweight: new FormControl({value: '', disabled: false}, Validators.required),
      sealnumber: new FormControl({value: '', disabled: false}, Validators.required),
      recordType: new FormControl({value: '', disabled: true}),
      deliveryId: new FormControl({value: 0, disabled: true})
    });

    this.containerOrTrailer = "container";

    this.containerService.getBackendSettings().then((settings: any) => {
      if (settings.enableOwnPrefix) {
        this.enableOwnPrefix = true;
      }
      if (settings.trailerDepotList) {
        let list = settings.trailerDepotList.split(",");
        if (list.includes(this.vbsBooking.depotcode)) {
          this.trailers = true;
          this.containerOrTrailer = "trailer";
          this.dropoffFormGroup.controls.container.setValidators([Validators.required]);
        }
      }  
    });

  }

  setDropOffContainer() {
    this.dropoffFormGroup.controls['container'].setValue(this.dropoffFormGroup.value.container.replace(/[^a-zA-Z0-9]/g, '').toUpperCase());
    this.checkContainerDetails();
  }

  setFullEmpty(value) {

    if (value === 'E') {
      this.dialogService.confirm('Are you sure this ' + this.containerOrTrailer + ' is empty?', (result: boolean) => {
        if (result) {
          this.checkContainerDetails(value);
        }
      }, 'Confirm Empty ' + this.containerOrTrailer);
    } else {
      this.checkContainerDetails(value);
    }
  }

  checkContainerDetails(FE?) {
    
    this.vbsLineService.getContainerDetails(this.dropoffFormGroup.getRawValue().container, FE).then((data: any) => {

      if (data.pcErrorMessage) {
        this.dialogService.alert(data.pcErrorMessage, () => {});
      } else {
        this.containerChecked = true;
        this.dropoffFormGroup.controls['container'].disable();

        if (FE) {
          this.fullEmptyChecked = true;
          this.dropoffFormGroup.controls.fullEmpty.setValue(FE);
        }

        if (data.pcBookingNumber) {
          this.dropoffFormGroup.controls['booking'].setValue(data.pcBookingNumber);
          this.dropoffFormGroup.controls['deliveryId'].setValue(data.piRedeliveryId);
          this.dropoffFormGroup.controls['booking'].disable();
          this.bookingChecked = true;

        } else {
          /* this.dropoffFormGroup.controls.booking.statusChanges.subscribe(status => {
            console.log(status);
            this.bookingInput.focus();
          }); */
          this.dropoffFormGroup.controls['booking'].enable();
        }
        if (data.pcIsoCode) {
          this.dropoffFormGroup.controls['isotype'].setValue(data.pcIsoCode);
        }
        if (data.pcSealNumber) {
          this.dropoffFormGroup.controls.sealnumber.setValue(data.pcSealNumber);
        }
        if (data.piGrossWeight) {
          this.dropoffFormGroup.controls.grossweight.setValue(data.piGrossWeight);
        }

        if (data.pcRecordtype === '23') {
          this.dropoffFormGroup.controls['booking'].disable();
          this.fullEmptyChecked = true;
          this.bookingChecked = true;
          this.dropoffFormGroup.controls['sealnumber'].disable();
          this.dropoffFormGroup.controls['grossweight'].disable();
          this.dropoffFormGroup.controls['isotype'].disable();
        }

      }
  });
  }


  checkBooking() {
    this.vbsLineService.getBookingDetails(this.dropoffFormGroup.value.booking, 'IN').then((data: any) => {

      if (data.pcErrorMessage) {
        this.dialogService.alert(data.pcErrorMessage, () => {});
      } else {

        if (data.piDeliveryId) {
          this.dropoffFormGroup.controls['deliveryId'].setValue(data.piDeliveryId);
          this.dropoffFormGroup.controls['booking'].disable();
          this.bookingChecked = true;
        }

      }
    });
  }

  ownPrefix() {
    this.bookingChecked = true;
    this.dropoffFormGroup.controls['booking'].disable();
    this.dropoffFormGroup.controls.fullEmpty.setValue('E');
  }

  onSubmitDelivery(form) {
    const values = this.dropoffFormGroup.getRawValue();

    console.log(values);

    const record = {
      companycode: '',
      vbs_bookingnr: this.vbsBookingnr,
      inoutcode: 'IN',
      calc_container: values.container,
      bookingnumber: values.booking,
      isocode: values.isotype,
      sealnumber: values.sealnumber,
      grossweight: values.grossweight,
      deliveryId: values.deliveryId
    };

    // alert(JSON.stringify(record));

    this.submitDisabled = true;
    this.vbsLineService.addRecord(record).then((newData) => {
      this.snackBar.open('Drop-off added!', '', {duration: 1000});
      this.resetDropOffForm();
      this.lineAdded.emit();
    }, err => {
        this.dialogService.alert(err, () => {});
    }).then( () => {
      this.submitDisabled = false;
    });
  }

  resetDropOffForm() {

    this.containerChecked = false;
    this.fullEmptyChecked = false;
    this.bookingChecked = false;

    this.f.resetForm();  // This resets the angular material error state

    this.dropoffFormGroup.reset({
      container: {value: '', disabled: false},
      booking: {value: '', disabled: true},
      fullEmpty: {value: '', disabled: true},
      isotype: {value: '', disabled: false},
      grossweight: {value: '', disabled: false},
      sealnumber: {value: '', disabled: false},
      recordType: {value: '', disabled: true},
      deliveryId: {value: 0, disabled: true}
    });

  }

}



