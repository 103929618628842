import * as actions from './information.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { InformationPagesService } from 'src/app/services/jsdo-data/information-pages.service';

@Injectable()
export class InformationEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly informationPagesService: InformationPagesService
    ) {}

    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GET),
            mergeMap(action => from(this.informationPagesService.getInformationPagesData(
                action.source
            ))
                .pipe(
                    map(result => {
                        return actions.RECEIVED({ result });
                    }),
                    catchError((err) => of(this._errorHandler(err)))
                )
        ))
    );

    private _errorHandler(payload: any) {
        console.log(payload);
        return actions.ERROR({ errorType: 'UNKNOWN', errorMessage: 'An unknown error happened' });
    }
}
