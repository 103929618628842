import { createAction, props, union } from '@ngrx/store';
import { InfoPageSource } from 'src/app/information/info-page-source.enum';
// tslint:disable: max-line-length

export const ERROR = createAction('[Information] Error', props<{ errorMessage: string, errorType: string }>());
export const GET = createAction('[Information] Get', props<{ source: InfoPageSource }>());
export const RECEIVED = createAction('[Information] Received', props<{ result: any }>());
export const RESET = createAction('[Information] Reset', props<{}>());

const all = union({
    ERROR,
    GET,
    RECEIVED,
    RESET,
});
export type Actions = typeof all;
