import { Injectable } from '@angular/core';
import { progress } from '../../progress/progress.jsdo';

import { JsdoSessionService } from '../jsdo-session.service';
import { JsdoService } from '../jsdo.service';

@Injectable({
  providedIn: 'root'
})
export class ReeferConnectionService {

  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              private jsdoService: JsdoService) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.jsdoService.prepare('dp_reefer_order'); //  new progress.data.JSDO('dp_reefer_order');
            resolve();
          });
      });
   }

  getData(filter) {

    
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            console.log(filter);
            this.jsdoService.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.jsdoService.dataSource.getData());
            });
        });
    });
  }

  commitReeferOrder(seqnr, action) {
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.jsdoService.dataSource.invoke('dpCommitReeferOrder', {piSeqnr: seqnr, pcAction: action})
            .done(function (jsdo, success, request) {
                resolve(request.response);
            }).fail(function(error) {
                reject(error);
            });
        });
    });
  }

}
