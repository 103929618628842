import { Injectable } from '@angular/core';
import { progress } from '../../progress/progress.jsdo';

import { JsdoSessionService } from '../jsdo-session.service';
import { JsdoService } from '../jsdo.service';

@Injectable({
  providedIn: 'root'
})
export class QuayjobBaplieService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              private jsdoService: JsdoService ) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('dp_quayjob_baplie');
            resolve();
          });
      });
   }

  getData(filter) {
    return new Promise((resolve, reject) => {
      this.jsdoSessionService.sessionReady.then(() => {
          this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.dataSource.getData());
            });
        });
    });
  }
}
