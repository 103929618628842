import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

import { SettingsService, SearchHistoryType } from '../services/settings.service';
import { SessionService } from '../services/session.service';
import { ContainerUtil } from '../softpak/container-util';
import { containerIdValidator } from '../softpak/form/container-input';
import { ConnectionService } from '../services/connection.service';
import { MatSnackBar } from '@angular/material';
import { RecentSearchesEntityEnum } from '../recent-searches/recent-searches-entity-enum.enum';

export class ContainerFormControl extends FormControl {
    warnings: any;
}

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit {
  bookingNumberForm: FormGroup;
  bookingFormSubmitted = false;
  containerForm: FormGroup;
  containerFormSubmitted = false;

  logo;
  connection;
  containernumber: string;
  test = false;

  constructor(private router: Router,
              private settingsService: SettingsService,
              private sessionService: SessionService,
              private connectionService: ConnectionService,
              private formBuilder: FormBuilder,
              private toaster: MatSnackBar) {

    this.settingsService.brandCode.then(brandCode => {
        const connection = this.connectionService.getConnection(this.sessionService.company);
        this.connection = connection;
        if (connection.logo) {
            this.logo = connection.logo;
        }
        if (this.sessionService.company.substr(-5) === '-TEST') {
            this.test = true;
        } else {
            this.test = false;
        }
    });
  }

  ngOnInit() {
    this.bookingNumberForm = this.formBuilder.group({
      bookingNumber: ['', [Validators.required]]
    });
    this.containerForm = this.formBuilder.group({
      containerNumber: new ContainerFormControl('', [
        Validators.required,
        containerIdValidator
      ])
    });
  }

  submitContainerForm(): void {
      this.containerFormSubmitted = true;
      if (this.containerForm.invalid) {
        if (this.containerFormControl.errors && this.containerFormControl.errors.required) {
          this.toaster.open('Container Number is required', null, {
            duration: 3000
          });
        } else if (this.containerFormControl.errors && this.containerFormControl.errors.length) {
          this.toaster.open(`Container must be 11 characters (currently: ${this.containerFormControl.value.length})`, null, {
            duration: 3000
          });
        } else if (this.containerFormControl.errors && this.containerFormControl.errors.pattern) {
          this.toaster.open(`Invalid container ID. (Should be 4 letters + 7 numbers)`, null, {
            duration: 3000
          });
        }
      } else {
        const container = ContainerUtil.sanitizeContainerId(this.containerFormControl.value);
        this.settingsService.addToSearchHistory(SearchHistoryType.CONTAINER, container);
        this.router.navigate(['containerinfo', container]);
      }
  }

  public gotoRecentSearches(entity: any): void {
      this.router.navigate([`recentsearches/${entity}`]);
  }

  get bookingNumberFormControl(): any {
    return <FormGroup>this.bookingNumberForm.get('bookingNumber');
  }

  get containerFormControl(): any {
    return <FormGroup>this.containerForm.get('containerNumber');
  }

  submitBookingForm(): void {
    this.bookingFormSubmitted = true;
    if (this.bookingNumberForm.invalid) {
      if (this.bookingNumberFormControl.errors.required) {
        this.toaster.open('Booking Number is required', null, {
          duration: 3000
        });
      }
    } else {
      this.settingsService.addToSearchHistory(SearchHistoryType.BOOKING, this.bookingNumberFormControl.value);
      this.router.navigate(['bookingdetails', this.bookingNumberFormControl.value]);
    }
  }

  maxLengthContainerNumber(): number {
    const sanitizedLength = ContainerUtil.sanitizeContainerId(this.containerFormControl.value).length;
    if (sanitizedLength === 11) {
      return this.containerFormControl.value.length;
    }
    return null;
  }
}
