import * as actions from './container-lookup.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';

const thisReducer = (state: AppState): ContainerLookupState => state.containerLookup;

export interface ContainerLookupState {
    containerData: unknown;
    containerDataError: { errorMessage: string, errorType: string };
    containerDataLoading: boolean;
    containerDataReceived: boolean;
}

export const initialState: ContainerLookupState = {
    containerData: null,
    containerDataError: null,
    containerDataLoading: false,
    containerDataReceived: false,
};

const handleReducer = createReducer(
    initialState,
    on(actions.ERROR, (state: ContainerLookupState, { errorMessage, errorType }): ContainerLookupState => {
        return {
            ...state,
            containerData: null,
            containerDataLoading: false,
            containerDataReceived: false,
            containerDataError: { errorMessage, errorType }
        };
    }),
    on(actions.RESET, (state: ContainerLookupState): ContainerLookupState => {
        return {
            ...state,
            containerData: null,
            containerDataLoading: false,
            containerDataReceived: false,
            containerDataError: null,
        };
    }),
    on(actions.LOAD_CONTAINER_DATA, (state: ContainerLookupState): ContainerLookupState => {
        return {
            ...state,
            containerData: null,
            containerDataLoading: true,
            containerDataReceived: false,
        };
    }),
    on(actions.RECEIVED_CONTAINER_DATA, (state: ContainerLookupState, { containerData }): ContainerLookupState => {
        return {
            ...state,
            containerData,
            containerDataLoading: false,
            containerDataReceived: true,
        };
    }),
);

export function reducer(state: ContainerLookupState, action: actions.ContainerLookupActions): ContainerLookupState {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: ContainerLookupState) => state
);

export const selectContainerData = createSelector(
    thisReducer, (state: ContainerLookupState) => state.containerData
);

export const selectContainerReceived = createSelector(
    thisReducer, (state: ContainerLookupState) => state.containerDataReceived
);

export const selectContainerLoading = createSelector(
    thisReducer, (state: ContainerLookupState) => state.containerDataLoading
);
