import { Component, OnInit, ViewChild, NgZone, Pipe, PipeTransform } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ReeferConnectionService } from '../services/jsdo-data/reefer-connection.service';
import { NavigationService } from '../services/navigation.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-reefer-connection',
  templateUrl: './reefer-connection.component.html',
  styleUrls: ['./reefer-connection.component.scss']
})
export class ReeferConnectionComponent implements OnInit {

  records;
  interval;

  columnsToDisplay = ['calc_action', 'location', 'calc_containernumber', 'calc_minutes_expire', 'temperature'];
  dataSource = new MatTableDataSource();
  
  showOptions = false;
  showOnlyReeferLocation = true;
  showDisconnectedReefers = false;
  selectedOrders = "ALL";

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private reeferConnectionService: ReeferConnectionService,
              private navigationService: NavigationService,
              private dialogService: DialogService,
              public snackBar: MatSnackBar,
              private zone: NgZone) { }

  ngOnInit() {
    this.getData();
    this.navigationService.setLabel('Reefer orders');
    this.dataSource.sort = this.sort;
    this.showOnlyReeferLocation = true;
    this.interval = window.setInterval(() => this.dataRefresh(), 30000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  getData() {

    const filter = {'logic': 'and', 'filters': [{'field': 'reefer_location', 'operator': 'eq', 'value': this.showOnlyReeferLocation},{'field': 'all_disconnected', 'operator': 'eq', 'value': this.showDisconnectedReefers}]};
    this.reeferConnectionService.getData(filter).then((records: Array<any>) => {
    
      // this.dataSource = new MatTableDataSource(records);
      this.dataSource.data = records;
      
      records.forEach(record => {
        if(record.cycluscode === 'I')  {record.cycluscode = 'Import'};      
        if(record.cycluscode === 'E')  {record.cycluscode = 'Export'};      
      });

      this.records = records.filter(item => {
        
        if(this.selectedOrders === "ALL") { return true}
        if(this.showDisconnectedReefers) { return true}
        
        return (item.calc_action === this.selectedOrders);
      });

    });
  }

  onOrderFinished(container, seqnr, action) {

    let cQuestion = action + ' ' + container + '\nMark this order as completed?';
    let cMessage  = action + ' ' + container + '\nOrder completed';
    if(action === "ALL-DISCONNECTED") {
      cQuestion = container + '\nMark this container as connected?';
      cMessage = container + '\nContainer connected';
      action = "CONNECT"
    }
       
    this.dialogService.confirm(cQuestion , (confirmed) => {
      if (confirmed) {
        this.reeferConnectionService.commitReeferOrder(seqnr, action).then((result: any) => {
          if (result.plOk === 'true' || result.plOk === true) {
              this.zone.run(() => {
                this.snackBar.open(cMessage, '', {duration: 1500});
              });
            this.getData();
          } else {
            alert('An error occurred!');
          }
        }, error => {
          alert(error);
        });
      }
    }, 'Please confirm');
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  dataRefresh() {
    console.log('refreshing');
    this.getData();
  }

}


