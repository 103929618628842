import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartRowComponent } from './bar-chart-row/bar-chart-row.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { GridChartRowComponent } from './grid-chart-row/grid-chart-row.component';
import { GridChartComponent } from './grid-chart/grid-chart.component';
import { ChartLabelComponent } from './chart-label/chart-label.component';

@NgModule({
  declarations: [
    BarChartComponent,
    BarChartRowComponent,
    ChartLabelComponent,
    GridChartComponent,
    GridChartRowComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    BarChartComponent,
    BarChartRowComponent,
    ChartLabelComponent,
    GridChartComponent,
    GridChartRowComponent,
  ]
})
export class SPChartModule { }
