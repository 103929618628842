import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromRoot from './store/index';
import * as containerLookupActions from './store/container-lookup/container-lookup.actions';
import * as fromContainerLookup from './store/container-lookup/container-lookup.reducer';
import { throwError } from 'rxjs';
import { map, filter, take, switchMap } from 'rxjs/operators';
import * as toaster from 'softpak-frontend-toaster';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ContainerDetailResolver implements Resolve<any> {
  store: any;
  constructor(
        private readonly appStore: Store<fromRoot.AppState>,
        private readonly router: Router,
        private readonly matSnackBar: MatSnackBar) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    this.appStore.dispatch(toaster.CLEAR_ALL({}));
    this.appStore.dispatch(containerLookupActions.RESET({}));
    this.appStore.dispatch(containerLookupActions.LOAD_CONTAINER_DATA({ containerNumber: route.params.container }));

    const t = this;

    return new Promise(function(resolve, reject) {
      t.appStore.select(fromContainerLookup.selectAll).pipe(
        map(all => {
          if ((all as any).containerDataError) {
            t.router.navigate(['/']);
            t.matSnackBar.open((all as any).containerDataError.errorMessage, '', {duration: 3000});
            throwError((all as any).containerDataError.errorMessage);
          }
          return all;
        }),
        filter(all => !(all as any).containerDataLoading),
        take(1),
        switchMap(() =>
          t.appStore.pipe(
            select(fromContainerLookup.selectContainerData),
            take(1)
            ).toPromise()
          )
      ).subscribe(data => {
        if (data === null) {
          reject('Container page not loaded.');
        } else {
          resolve(data as any);
        }
      });
    }).catch(error => { console.log(error); });

  }
}
