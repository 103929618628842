import * as actions from './booking-lookup.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';

const thisReducer = (state: AppState): BookingLookupState => state.bookingLookup;

export interface BookingLookupState {
    bookingsData: unknown[];
    bookingDataError: { errorMessage: string, errorType: string };
    bookingDataLoading: boolean;
    bookingDataReceived: boolean;
}

export const initialState: BookingLookupState = {
    bookingsData: null,
    bookingDataError: null,
    bookingDataLoading: false,
    bookingDataReceived: false,
};

const handleReducer = createReducer(
    initialState,
    on(actions.ERROR, (state: BookingLookupState, { errorMessage, errorType }): BookingLookupState => {
        return {
            ...state,
            bookingsData: null,
            bookingDataLoading: false,
            bookingDataReceived: false,
            bookingDataError: { errorMessage, errorType }
        };
    }),
    on(actions.RESET, (state: BookingLookupState): BookingLookupState => {
        return {
            ...state,
            bookingsData: null,
            bookingDataLoading: false,
            bookingDataReceived: false,
            bookingDataError: null,
        };
    }),
    on(actions.LOAD_BOOKING_DATA, (state: BookingLookupState): BookingLookupState => {
        return {
            ...state,
            bookingsData: null,
            bookingDataLoading: true,
            bookingDataReceived: false,
        };
    }),
    on(actions.RECEIVED_BOOKING_DATA, (state: BookingLookupState, { bookingsData }): BookingLookupState => {
        return {
            ...state,
            bookingsData,
            bookingDataLoading: false,
            bookingDataReceived: true,
        };
    }),
);

export function reducer(state: BookingLookupState, action: actions.BookingLookupActions): BookingLookupState {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: BookingLookupState) => state
);

export const selectBookingsData = createSelector(
    thisReducer, (state: BookingLookupState) => state.bookingsData
);

export const selectBookingReceived = createSelector(
    thisReducer, (state: BookingLookupState) => state.bookingDataReceived
);

export const selectBookingLoading = createSelector(
    thisReducer, (state: BookingLookupState) => state.bookingDataLoading
);
