import { Injectable } from '@angular/core';
import { progress } from '../progress/progress.jsdo';
import { JsdoSessionService } from './jsdo-session.service';

@Injectable({
  providedIn: 'root'
})
export class JsdoService {

  public dataSource;

  constructor(private jsdoSessionService: JsdoSessionService) { }

  prepare(table) {
    this.dataSource = new progress.data.JSDO(table);
    this.dataSource.subscribe('afterFill', (jsdo, success, request) => {
      // alert(request.response.status_code);
      if (request.response.status_code === 401) {
        console.log('logging in again');
        this.jsdoSessionService.login();
      }
    });
  }
}
