import { Injectable } from '@angular/core';
import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';
import * as moment from 'moment';
import {
    EquipmentStatsExternalInterface,
    EquipmentStatsExternalItemInterface
} from 'src/app/equipment-stats/models/equipment-stats-external.interface';
import {
    EqStSummaryEquipmentGroupInterface
} from 'src/app/equipment-stats/models/equipment-stats-summary.interface';

@Injectable({
    providedIn: 'root'
})
export class EquipmentStatsService {
    private dataSource: any;
    private dataSourceReady;
    private storedEquipments: string[] = [];

    constructor(private jsdoSessionService: JsdoSessionService) {
        this.dataSourceReady = new Promise((resolve, reject) => {
            this.jsdoSessionService.sessionReady.then(() => {
                this.dataSource = new progress.data.JSDO({
                    name: 'dp_container_info'
                });
                resolve();
            });
        });
    }

    getEquipmentStatsSummary() {
        const t = this;
        return new Promise((resolve, reject) => {
            this.dataSourceReady.then(() => {
                this.dataSource.invoke('getEquipmentStatistics', {
                }).done(function (jsdo, success, request: EquipmentStatsExternalInterface) {
                    const data = t.convertSummary(
                        request.response['tt-equipment-statistics']['tt-equipment-statistics']) as EqStSummaryEquipmentGroupInterface[];
                    resolve(data);
                }).fail(function (error) {
                    reject(error);
                });
            });
        });
    }

    getEquipmentStatsDetail() {
        const t = this;
        return new Promise((resolve, reject) => {
            this.dataSourceReady.then(() => {
                this.dataSource.invoke('getEquipmentStatistics', {
                    pdtFrom: moment().startOf('day').subtract(3, 'days').local().format('YYYY-MM-DD'),
                    pdtTill: moment().startOf('day').add(0, 'days').local().format('YYYY-MM-DD')
                }).done(function (jsdo, success, request: EquipmentStatsExternalInterface) {
                    const data =
                        t.convertDetails(request.response['tt-equipment-statistics']['tt-equipment-statistics']) as any[];
                    resolve(data);
                }).fail(function (error) {
                    reject(error);
                });
            });
        });
    }

    private convertSummary(items: EquipmentStatsExternalItemInterface[]): EqStSummaryEquipmentGroupInterface[] {
        // Group equipment stats by equipment group
        return this.groupByField(items, 'equipmentGroupcode').map((equipmentsInEquipmentsGroup: EquipmentStatsExternalItemInterface[]) => {
            return {
                equipments: equipmentsInEquipmentsGroup as EquipmentStatsExternalItemInterface[]
            };
        });
    }

    private convertDetails(items: EquipmentStatsExternalItemInterface[]): any[] {
        // 1. Turns timeslot labels into XXh-format.
        items = items.map(item => {
            item.startTimeTimeslot = item.startTimeTimeslot.substr(0, 2) + 'h';
            return item;
        });
        // 2. Make a nested JSON Array
        const depots = this.groupByField(items, 'depotCode');
        depots.forEach((depot, depotIndex) => {
            depots[depotIndex] = this.groupByField(depot, 'equipmentGroupcode');
            const equipmentGroups = depots[depotIndex];
            equipmentGroups.forEach((equipmentGroup, equipmentGroupIndex) => {
                depots[depotIndex][equipmentGroupIndex] = this.groupByField(equipmentGroup, 'dateTimeSlot');
                const days = depots[depotIndex][equipmentGroupIndex];
                days.forEach((day, dayIndex) => {
                    const equipments = this.getDistinct(day, 'equipmentCode');
                    const timeslots = this.getDistinct(day, 'startTimeTimeslot');
                    depots[depotIndex][equipmentGroupIndex][dayIndex] = {
                        date: day[0].dateTimeSlot,
                        equipments,
                        equipmentColors: this.setColorsForEquipments(equipments),
                        timeslots,
                        values: this.getDayValues(equipments, timeslots, day),
                        unconvertedValues: day,
                    };
                });
            });
        });
        return depots;
    }

    private setColorsForEquipments(equipments: string[]): number[] {
        return equipments.map(equipment => {
            if (!this.storedEquipments.includes(equipment)) {
                this.storedEquipments.push(equipment);
            }
            return this.storedEquipments.indexOf(equipment);
        });
    }

    // Loops through array, looks in every object for attribute, and then returns a distinct of values it found.
    getDistinct(items: any[], attributeName: string): string[] {
        return [...new Set(items.map(item => item[attributeName]))];
    }

    getDayValues(equipments, timeslots, day: any): any[][] {
        return timeslots.map(timeslot => {
            return equipments.map(equipment => {
                const filtered = day.filter(item => {
                    return item.equipmentCode === equipment && item.startTimeTimeslot === timeslot;
                });
                return filtered.length === 1 ? filtered[0] : null;
            });
        });
    }

    groupByField(items: any[], groupByField: string): any[] {
        const results: any[] = [];
        let previous: string;
        let group: any[] = [];
        if (items) {
            items.forEach(item => {
                if (previous && item[groupByField] !== previous) {
                    results.push(group);
                    group = [];
                }
                group.push(item);
                previous = item[groupByField];
            });
        }
        results.push(group);
        return results;
    }

}

