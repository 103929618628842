import { createAction, props, union } from '@ngrx/store';
// tslint:disable: max-line-length

export const LOAD_CURRENT_DEPOT = createAction('[Storage] Load *Current Depot*', props<{}>());
export const LOAD_RECENT_DAMAGE_TYPES = createAction('[Storage] Load Recent *Damage Types*', props<{}>());
export const LOAD_RECENT_EQUIPMENT_NUMBERS = createAction('[Storage] Load Recent *Equipment Numbers*', props<{}>());
export const LOAD_RECENT_EQUIPMENT_TYPES = createAction('[Storage] Load Recent *Equipment Types*', props<{}>());
export const LOAD_RECENT_MACHINERY_MANUFACTURERS = createAction('[Storage] Load Recent *Machinery Manufacturers*', props<{}>());
export const LOAD_RECENT_MACHINERY_MODELS = createAction('[Storage] Load Recent *Machinery Models*', props<{}>());
export const LOAD_RECENT_MANUFACTURERS = createAction('[Storage] Load Recent *Manufacturers*', props<{}>());
export const LOAD_RECENT_TEMPLATE_NUMBERS = createAction('[Storage] Load Recent *Template Numbers*', props<{}>());
export const SAVE_CURRENT_DEPOT = createAction('[Storage] Save *Current Depot* & Reload Items', props<{ depotCode: string, depotName: string }>());
export const SAVE_RECENT_DAMAGE_TYPE = createAction('[Storage] Save *Damage Type* & Reload Items', props<{ item: string }>());
export const SAVE_RECENT_EQUIPMENT_NUMBER = createAction('[Storage] Save *Equipment Number* & Reload Items', props<{ item: string }>());
export const SAVE_RECENT_EQUIPMENT_TYPES = createAction('[Storage] Save *Equipment Type* & Reload Items', props<{ item: string }>());
export const SAVE_RECENT_MACHINERY_MANUFACTURER = createAction('[Storage] Save *Machinery Manufacturer* & Reload Items', props<{ item: string }>());
export const SAVE_RECENT_MACHINERY_MODEL = createAction('[Storage] Save *Machinery Model* & Reload Items', props<{ item: string }>());
export const SAVE_RECENT_MANUFACTURER = createAction('[Storage] Save *Manufacturer* & Reload Items', props<{ item: string }>());
export const SAVE_RECENT_TEMPLATE_NUMBER = createAction('[Storage] Save *Template Number* & Reload Items', props<{ item: string }>());

const all = union({
    LOAD_CURRENT_DEPOT,
    LOAD_RECENT_DAMAGE_TYPES,
    LOAD_RECENT_EQUIPMENT_NUMBERS,
    LOAD_RECENT_EQUIPMENT_TYPES,
    LOAD_RECENT_MACHINERY_MANUFACTURERS,
    LOAD_RECENT_MACHINERY_MODELS,
    LOAD_RECENT_MANUFACTURERS,
    LOAD_RECENT_TEMPLATE_NUMBERS,
    SAVE_CURRENT_DEPOT,
    SAVE_RECENT_DAMAGE_TYPE,
    SAVE_RECENT_EQUIPMENT_NUMBER,
    SAVE_RECENT_EQUIPMENT_TYPES,
    SAVE_RECENT_MACHINERY_MANUFACTURER,
    SAVE_RECENT_MACHINERY_MODEL,
    SAVE_RECENT_MANUFACTURER,
    SAVE_RECENT_TEMPLATE_NUMBER
});
export type StorageActions = typeof all;
