import { Component } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RouterEvent } from '@angular/router';
import { DevicePushService } from './services/jsdo-data/device-push.service';
import { CordovaService } from './services/cordova.service';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    loading = true;

    constructor(private sessionService: SessionService,
                private devicePushService: DevicePushService,
                private cordovaService: CordovaService,
                private router: Router) {

        console.log('On Cordova: ', this.cordovaService.onCordova);
        if (this.cordovaService.onCordova) {
            this.cordovaService.splashscreen.hide();
            this.router.navigate(['update']);
        }

        this.devicePushService.registerDeviceForPush();

        router.events.subscribe((routerEvent: RouterEvent) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    checkRouterEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.loading = true;
        }

        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.loading = false;
        }
    }
}

@Pipe({
    name: 'groupBy',
    pure: false
})
export class GroupByPipe implements PipeTransform {
    transform(collection: Array<any>, property: string): Array<any> {

        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            if (current && current.hasOwnProperty(property)) {
                if (!previous[current[property]]) {
                    previous[current[property]] = [current];
                } else {
                    previous[current[property]].push(current);
                }
            }
            return previous;
        }, {});

        // this will make an array of objects, each object containing a group of objects
        const newArr = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));

        newArr.sort((a, b) => {
            if (a.key < b.key) {
                return -1;
            }
            if (a.key > b.key) {
                return 1;
            }
            return 0;
        });
        return newArr;
    }
}
