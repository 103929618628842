import * as actions from './booking-stats.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { BookingStatsService } from 'src/app/services/jsdo-data/booking-stats.service';
import { BookingStatsSettingsService } from 'src/app/services/settings-booking-stats.service';

@Injectable()
export class BookingStatsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly bookingStatsService: BookingStatsService,
        private readonly bookingStatsSettingsService: BookingStatsSettingsService
    ) {}

    loadBookingData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.LOAD_BOOKING_STATS),
            mergeMap(action => from(this.bookingStatsService.getBookingStats())
                .pipe(
                    map(result => actions.RECEIVED_BOOKING_STATS({ bookingStatsData: result })),
                    catchError((err) => of(this._bookingDataErrorHandler(err)))
                )
        ))
    );

    private _bookingDataErrorHandler(payload: any) {
        if (payload.errorType && payload.errorType === 'BOOKING_NOT_FOUND') {
            return actions.ERROR({ errorType: payload.errorType, errorMessage: payload.message });
        }
        return actions.ERROR({ errorType: 'UNKNOWN', errorMessage: 'An unknown error happened' });
    }
}
