import { Component, Input, OnInit } from '@angular/core';
import { ChartColumnInterface } from '../models/chart-column.interface';

@Component({
  selector: 'sp-grid-chart-row',
  templateUrl: './grid-chart-row.component.html',
  styleUrls: ['./grid-chart-row.component.scss']
})
export class GridChartRowComponent implements OnInit {
  @Input() index: number;
  @Input() label: string;
  @Input() labelWidth: number;
  @Input() minColumns = 0;
  @Input() values: ChartColumnInterface[];
  spacers: number[] = [];

  ngOnInit() {
    this.spacers = [];
    for (let i = 0; i < this.minColumns - this.values.length; i++) {
      this.spacers.push(i);
    }
  }
}
