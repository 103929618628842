import { Component, OnInit, Input } from '@angular/core';
import * as fromRoot from '../../../store/index';
import * as fromEquipmentStats from '../../../store/equipment-stats/equipment-stats.reducer';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-equipment-stats-grid-header',
  templateUrl: './equipment-stats-grid-header.component.html',
  styleUrls: ['./equipment-stats-grid-header.component.scss']
})
export class EquipmentStatsGridHeaderComponent implements OnInit {
  @Input() labelWidth: number;
  public splitImportExport: boolean;
  public splitFullEmpty: boolean;

  constructor(private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit() {
    this.appStore.pipe(select(fromEquipmentStats.selectAll)).subscribe(all => {
      this.splitFullEmpty = all.filters.splitFullEmpty;
      this.splitImportExport = all.filters.splitImportExport;
    });
  }

}
