import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'app-wind-widget',
  templateUrl: './wind-widget.component.html',
  styleUrls: ['./wind-widget.component.scss']
})
export class WindWidgetComponent implements OnInit {

  winds = [];

  constructor() { }

  ngOnInit() {
    this.getWindData();
  }

  getWindData() {
    const xmlhttp = new XMLHttpRequest();

    const company = SettingsService.getSetting('company');

    let loc = 'rotterdam,nl';
    if (company === 'POC') { loc = 'cork,ie'; }
    if (company === 'DFT') { loc = 'dublin,ie'; }

    const url = 'http://api.openweathermap.org/data/2.5/forecast?q=' + loc  + '&appid=a86bad23b68c0fde3cbd57636871d088';

    xmlhttp.onreadystatechange = () => {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        const myArr = JSON.parse(xmlhttp.responseText);
        this.processWindData(myArr);
      }
    };
    xmlhttp.open('GET', url, true);
    xmlhttp.send();

    // var t = setTimeout(getWindData, 60000);
  }

  processWindData(arr) {

    this.winds = [];

    for (let i = 0; i < 4; i++) {

      let wind: any;
      wind = {};

      const myDate    = new Date(arr.list[i].dt * 1000);
      wind.time      = myDate.getUTCHours() + ':00';
      wind.speed     = Math.round(arr.list[i].wind.speed * 3.6);  // m/s -> km/h
      wind.direction = Math.round(arr.list[i].wind.deg) - 180;

      wind.animate_duration = wind.direction * 15;
      if (wind.animate_duration < 0) {
        wind.animate_duration = wind.animate_duration * -1;
      }

      this.winds.push(wind);
    }

  }

}
