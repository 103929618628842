import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { VbsService } from '../services/jsdo-data/vbs.service';
import { NavigationService } from '../services/navigation.service';
import { DialogService } from '../services/dialog.service';
import { TimeslotService } from '../services/jsdo-data/timeslot.service';
import { JsdoSessionService } from '../services/jsdo-session.service';


@Component({
  selector: 'app-timeslot-details',
  templateUrl: './timeslot-details.component.html',
  styleUrls: ['./timeslot-details.component.scss']
})
export class TimeslotDetailsComponent implements OnInit {

  timeslotSeqnr;
  timeslot;
  vbsBookings;
  kioskMode = false;
  addDisabled: Boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private timeslotService: TimeslotService,
              private vbsService: VbsService,
              private navigationService: NavigationService,
              private dialogService: DialogService,
              private jsdoSessionService: JsdoSessionService
              ) { }

  ngOnInit() {
      this.navigationService.enableBackButton(true);

      this.route.params
      .subscribe((params: Params) => this.timeslotSeqnr = params['timeslot']);

      this.route.params.pipe(switchMap((params: Params) => this.timeslotService.getTimeSlotByTimeslotSeqnr(params['timeslot'])))
        .subscribe(timeslots => {
          timeslots.forEach(timeslot => {

          if (timeslot.timeslot_seqnr.toString() === this.timeslotSeqnr) {
            this.timeslot = timeslot;
            this.navigationService.setLabel(this.menuTitle());
            if (this.timeslot.type_timeslot === 'EXPIRED') {
              this.addDisabled = true;
            }
          }
        });
      });

      if ( !this.kioskMode ) {
        this.route.params.pipe(switchMap((params: Params) => this.vbsService.getRecordsByTimeslot(params['timeslot'])))
        .subscribe(vbsBookings => {
          this.vbsBookings = vbsBookings;
        });
      }
  }

  onAdd() {

    if (!this.jsdoSessionService.userSource.value || this.jsdoSessionService.userSource.value === 'anonymous') {
      this.dialogService.alert('You must be logged in to create a truck appointment', () => {});
      return;
    }

    const addRecord = {
      companycode: '',
      timeslot_seqnr: this.timeslotSeqnr,
      bookingtype: 'REGULAR',
      depotcode: '',
      dt_booking: '',
      dt_status_cancelled: '' // Het niet meegeven van datumvelden resulteert er in dat er toch een waarde in wordt gezet door de JSDO?
    };

    this.addDisabled = true;
    this.vbsService.addRecord(addRecord).then((jsdoRecord: any) => {
      this.router.navigate(['vbslines', jsdoRecord.data.vbs_bookingnr]);
    }, err => {
        this.dialogService.alert(err, () => {});
    }).then( () => {
      this.addDisabled = false;
    });
  }

  onDelete(event, RecId) {
    event.stopPropagation();
    this.dialogService.confirm('Are you sure you want to remove this appointment?', (lGo: Boolean) => {
      if (lGo) {
         this.vbsService.deleteRecord(RecId);
      }
    }, 'Confirm delete');
  }

  menuTitle() {
    const dateArr =  this.timeslot.dt_timeslot_start.split('-');
    const d: Date = new Date();
    d.setFullYear(dateArr[0], dateArr[1] - 1, dateArr[2]);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const timeslotDes = days[d.getDay()] + ' ' + d.getDate() + ' ' + months[d.getMonth()]
      + ' ' + this.timeslot.tim_timeslot_start.substring(0, 2) + ':'
      + this.timeslot.tim_timeslot_start.substring(2, 4) + ' - '
      + this.timeslot.tim_timeslot_end.substring(0, 2) + ':'
      + this.timeslot.tim_timeslot_end.substring(2, 4);
    return timeslotDes;
  }

}
