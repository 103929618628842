import * as equipmentStatsActions from './store/equipment-stats/equipment-stats.actions';
import * as fromEquipmentStats from './store/equipment-stats/equipment-stats.reducer';
import * as fromRoot from './store/index';
import * as toaster from 'softpak-frontend-toaster';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
  } from '@angular/router';
import {
  filter,
  map,
  switchMap,
  take
  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { throwError } from 'rxjs';

@Injectable()
export class EquipmentStatsResolver implements Resolve<any> {
  store: any;
  constructor(
        private readonly appStore: Store<fromRoot.AppState>,
        private readonly router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    this.appStore.dispatch(toaster.CLEAR_ALL({}));
    this.appStore.dispatch(equipmentStatsActions.RESET({}));
    this.appStore.dispatch(equipmentStatsActions.LOAD_EQUIPMENT_STATS({}));

    const t = this;

    return new Promise(function(resolve, reject) {
      t.appStore.select(fromEquipmentStats.selectAll).pipe(
        map((equipmentStatsState: fromEquipmentStats.EquipmentStatsState) => {
          if (equipmentStatsState.error) {
            t.router.navigate(['/']);
            t.appStore.dispatch(toaster.CREATE_ERROR({
              messageText: equipmentStatsState.error.errorMessage
            }));
            throwError(equipmentStatsState.error.errorMessage);
          }
          return equipmentStatsState;
        }),
        filter(equipmentStatsState => !equipmentStatsState.loading),
        take(1),
        switchMap(() =>
          t.appStore.pipe(
            select(fromEquipmentStats.selectAll),
            take(1)
            ).toPromise()
          )
      ).subscribe(data => {
        if (data === null) {
          reject('Equipment stats page not loaded.');
        } else {
          resolve(data as any);
        }
      });
    }).catch(error => { console.log(error); });

  }
}
