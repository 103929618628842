import { Injectable } from '@angular/core';

import { JsdoSessionService } from '../jsdo-session.service';
import { JsdoService } from '../jsdo.service';
import { InfoPageSource } from 'src/app/information/info-page-source.enum';

@Injectable({
  providedIn: 'root'
})
export class InformationPagesService {

  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
    public jsdoService: JsdoService) {
    this.dataSourceReady = new Promise((resolve, reject) => {
      this.jsdoSessionService.sessionReady.then(() => {
        this.jsdoService.prepare('dp_container_info');
        resolve();
      });
    });
  }

  public getInformationPagesData(source: InfoPageSource) {
    return new Promise((resolve, reject) => {
      this.dataSourceReady.then(() => {
        this.jsdoService.dataSource.invoke(this.getFunctionDependingOnDesiredDataSource(source), {})
          .done(function (jsdo, success, request) {
            resolve(JSON.parse(request.response.cExtraAppInfo));
          }).fail(function (error) {
            reject(error);
          });
      });
    });
  }

  private getFunctionDependingOnDesiredDataSource(source: InfoPageSource): string {
    switch (source) {
      case InfoPageSource.DASHBOARDS:
        return 'getExtraAppDashBoard';
      case InfoPageSource.INFORMATION:
        return 'getExtraAppInfo';
    }
  }

}



