import * as actions from './settings.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';

const thisReducer = (state: AppState): SettingsState => state.settings;

export interface SettingsState {
    depot: string;
}

export const initialState: SettingsState = {
    depot: null
};

const handleReducer = createReducer(
    initialState,
    on(actions.UPDATE_DEPOT, (state: SettingsState, {
        depot
    }): SettingsState => {
        DepotSettingsService.setFavouriteDepot(depot);
        return {
            ...state,
            depot
        };
    }),
);

export function reducer(state: SettingsState, action: actions.SettingsActions): SettingsState {
    return handleReducer(state, action);
}

export const selectAll = createSelector(
    thisReducer, (state: SettingsState) => state
);

export const selectDepot = createSelector(
    thisReducer, (state: SettingsState) => state.depot
);
