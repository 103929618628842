import { Injectable } from '@angular/core';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';


@Injectable({
  providedIn: 'root'
})
export class VbsLineService {

  dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('dp_vbs_booking_line');
            resolve();
          });
      });
   }


  getRecordsByBookingSeqnr(bookingSeqnr) {
    const filter = {'logic': 'and', 'filters': [{'field': 'vbs_bookingnr', 'operator': 'eq', 'value': parseInt(bookingSeqnr, 10)}]};
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                const data = this.dataSource.getData();
                resolve(this.calcFields(data));
            });
        });
    });
  }

  addRecord(record) {

    return new Promise((resolve, reject) => {

      this.dataSource.add(record);
      // this.dataSource.saveChanges(true).always(callBack);
      this.dataSource.saveChanges(true).done((jsdo, success, request) => {
        if (success) {
          resolve(this.calcFields(this.dataSource.getData()));
        }
      }).fail((jsdo, success, request) => {
        let errorString = '';
        const errors = jsdo.ttdp_vbs_booking_line.getErrors();
        const lenErrors = errors.length;
        let errorType;
        for (let idxError = 0; idxError < lenErrors; idxError++) {
            switch (errors[idxError].type) {
                case progress.data.JSDO.DATA_ERROR:
                    errorType = 'Server Data Error: ';
                    break;
                case progress.data.JSDO.RETVAL:
                    errorType = 'Server App Return Value: ';
                    break;
                case progress.data.JSDO.APP_ERROR:
                    errorType = 'Server App Error #' + errors[idxError].errorNum + ': ';
                    break;
                case progress.data.JSDO.ERROR:
                    errorType = 'Server General Error: ';
                    break;
                /* case default:
                    errorType = null; // Unexpected errorType value
                    break; */
            }
            if (errorType) { // log all error text
                console.log('ERROR: ' + errorType + errors[idxError].error);
                if (errors[idxError].id) { /* error with record object */
                    console.log('RECORD ID: ' + errors[idxError].id);
                    errorString = errorString + errors[idxError].error;
                }
                if (errors[idxError].responseText) {
                    console.log('HTTP FULL TEXT: '
                                  + errors[idxError].responseText);
                }
            } else { /* unexpected errorType */
                console.log('UNEXPECTED ERROR TYPE: '
                             + errors[idxError].type);
            }
        } /* for each error message */

        reject(errorString);
      });

    });
  }

  deleteRecordById(Id) {
    const jsdoRecord = this.dataSource.findById(Id);
    jsdoRecord.remove();
    this.dataSource.saveChanges(true);
  }

  getContainerDetails(container, fullEmpty) {
    return new Promise((resolve, reject) => {
        this.dataSource.invoke('getContainerDetailsFE', {cContainer: container, pcFullEmpty: fullEmpty})
        .done(function (jsdo, success, request) {
            resolve(request.response);
        }).fail(function(error) {
            reject(error);
        });
    });
  }

  getBookingDetails(booking, inOrOut) {
    return new Promise((resolve, reject) => {
        this.dataSource.invoke('getBookingDetails', {pcBooking: booking, pcInOrOut: inOrOut})
        .done(function (jsdo, success, request) {
            resolve(request.response);
        }).fail(function(error) {
            reject(error);
        });
    });
  }

  calcFields(data) {
    data.forEach(element => {
        if (element.calc_errors) {
          element.local_calc_errors =  element.calc_errors.split('\n');
        }
      });
    return data;
  }

}
