import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as settingsActions from '../../store/settings/settings.actions';
import * as fromSettings from '../../store/settings/settings.reducer';
import * as fromRoot from '../../store/index';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';
import { unsubscribeSubscriptions } from '../functions/unsubscribe-subscriptions';

@Component({
  selector: 'sp-depot-selector',
  templateUrl: './depot-selector.component.html',
  styleUrls: ['./depot-selector.component.scss']
})
export class DepotSelectorComponent implements OnChanges, OnDestroy, OnInit {
  @Input() availableDepots: { depotCode: string; depotName: string; }[] = [];
  private currentDepot: string;
  public tabIndex: number;
  private readonly subscriptions: {
    currentDepot?: Subscription;
    selectBookingStatsData?: Subscription;
    depotValueChanges?: Subscription;
  } = {};

  constructor(private readonly appStore: Store<fromRoot.AppState>) {
  }

  public ngOnInit(): void {
    this.subscribeToCurrentDepot();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.currentDepot = DepotSettingsService.getFavouriteDepot();
    if (changes.availableDepots) {
      this.calculateTabIndex();
    }
  }

  public ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

  private subscribeToCurrentDepot(): void {
    this.currentDepot = DepotSettingsService.getFavouriteDepot();
    this.calculateTabIndex();
    this.subscriptions.currentDepot = this.appStore.pipe(select(fromSettings.selectDepot)).subscribe(depot => {
      this.currentDepot = DepotSettingsService.getFavouriteDepot();
      this.calculateTabIndex();
    });
  }

  private calculateTabIndex(): void {
    let found = false;
    if (this.availableDepots) {
      this.availableDepots.forEach((depot, index) => {
        if (depot.depotCode === this.currentDepot) {
          this.tabIndex = index;
          found = true;
        }
      });
      if (this.availableDepots.length > 0 && !found) {
        this.tabIndex = 0;
        this.saveDepot(this.availableDepots[0].depotCode);
      }
    }
  }

  public onSelectDepot(tabIndex): void {
    this.saveDepot(this.availableDepots[tabIndex].depotCode);
  }

  private saveDepot(depotCode: string): void {
    this.appStore.dispatch(settingsActions.UPDATE_DEPOT({ depot: depotCode }));
  }
}
