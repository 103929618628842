import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

function _window(): any {
  // return the global native browser window object
  return window;
 }

// keep startup url (for Restarting app under Cordova)
window['initialHref'] = window.location.href;

// Error catching
console.log('init error logging');
window.onerror = function(event, souce, lineno, colon, error) {
  

}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
}

if (_window().cordova) {
  const onDeviceReady = () => {

      /* var Sentry = _window().Sentry;  
      
      console.log('Sentry', Sentry);
      Sentry.init({ dsn: 'https://c9a13f80c8dc40319c38cdc485edf0d7@o1183852.ingest.sentry.io/4505272549244928' }); */
      
      // Android back button disable
      document.addEventListener('backbutton', () => {}, false);
      bootstrap();
  };
  document.addEventListener('deviceready', onDeviceReady, false);
} else {
  bootstrap();
}
