import { createAction, props, union } from '@ngrx/store';

// tslint:disable: max-line-length
export const CLEAR_DEPOT_DATA = createAction('[Depot Settings] Clear', props<{ }>());
export const LOAD_DEPOT_DETAILS = createAction('[Depot Settings] Load Depot Details', props<{ depotCode: string }>());
export const RECEIVED_DEPOT_DATA = createAction('[Depot Settings] Received Depot Data', props<{ depotCode: string, depotName: string }>());
export const LOAD_DEPOT_SETTING = createAction('[Depot Settings] Load Depot Setting', props<{ userName: string }>());
export const RECEIVED_DEPOT_SETTING = createAction('[Depot Settings] Received Depot Setting', props<{ depotData: any }>());

const all = union({
    CLEAR_DEPOT_DATA,
    LOAD_DEPOT_DETAILS,
    LOAD_DEPOT_SETTING,
    RECEIVED_DEPOT_DATA,
    RECEIVED_DEPOT_SETTING
});
export type SuggestionActions = typeof all;
