import { Injectable } from '@angular/core';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('aa_attachment');
            resolve();
          });
      });
   }

  getData(filter) {
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.dataSource.getData());
            });
        });
    });
  }

  getDataByAttachmentNr(attachmentNr) {
    const filter = {'logic': 'and', 'filters': [{'field': 'attachmentnr', 'operator': 'eq', 'value': attachmentNr}]};
    return this.getData(filter);
  }


  getAttachment(attachmentNr, lineSeqnr) {
    return new Promise((resolve, reject) => {
        this.dataSourceReady.then(() => {
            this.dataSource.invoke('getAttachment', {piAttachmentNr: attachmentNr, piLineSeqnr: lineSeqnr})
            .done(function (jsdo, success, request) {
                resolve(request.response.plData);
            }).fail(function(error) {
                reject(error);
            });
        });
    });
  }

}
