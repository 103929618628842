import * as accessRightsActions from '../../store/access-rights/access-rights.actions';
import * as fromAccessRights from '../../store/access-rights/access-rights.reducer';
import * as fromRoot from '../../store/index';
import {
  ACCESS_CODE_APPOINTMENT_STATS,
  ACCESS_CODE_EQUIPMENT_STATS,
  ACCESS_CODE_ESTIMATE,
  ACCESS_CODE_IMPORT_TAR,
  ACCESS_CODE_INSPECTION,
  ACCESS_CODE_PHOTO,
  ACCESS_CODE_QUAYJOB,
  ACCESS_CODE_REDELIVERY,
  ACCESS_CODE_REEFER_MONITORING,
  ACCESS_CODE_REEFER_ORDERS,
  ACCESS_CODE_REEFER_INSPECTIONS,
  ACCESS_CODE_DASHBOARDS
  } from '../access-rights/access-rights.const';
import { Component, Input, OnInit } from '@angular/core';
import { ContainerInfoService } from '../../services/jsdo-data/container-info.service';
import { JsdoSessionService } from '../../services/jsdo-session.service';
import { SessionService } from '../../services/session.service';
import {
  ROUTING_KEY_APPOINTMENT_STATS,
  ROUTING_KEY_EQUIPMENT_STATS,
  ROUTING_KEY_ESTIMATES,
  ROUTING_KEY_IMPORT_TAR,
  ROUTING_KEY_INFORMATION,
  ROUTING_KEY_INSPECTIONS,
  ROUTING_KEY_LOGIN,
  ROUTING_KEY_PHOTO,
  ROUTING_KEY_QUAYJOBS,
  ROUTING_KEY_REDELIVERY,
  ROUTING_KEY_REEFER_MONITORING,
  ROUTING_KEY_REEFER_ORDERS,
  ROUTING_KEY_SETTINGS_2,
  ROUTING_KEY_TIMESLOTS,
  ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION,
  ROUTING_KEY_REEFER_INSPECTIONS,
  ROUTING_KEY_ABOUT,
  ROUTING_KEY_DASHBOARDS
  } from 'src/app/softpak/menu/app-menu-items.const';
import { select, Store } from '@ngrx/store';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent {
  @Input() menuStyle: string;

  menuItems = [
    {
      label: 'Redelivery',
      routerLink: ROUTING_KEY_REDELIVERY,
      icon: 'fa-truck',
      accessCode: ACCESS_CODE_REDELIVERY,
      accessCompanies: ['SOFTPAK']
    },
    {
      label: 'Inspection',
      routerLink: ROUTING_KEY_INSPECTIONS,
      icon: 'fa-search',
      accessCode: ACCESS_CODE_INSPECTION,
      accessCompanies: ['SOFTPAK']
    },
    {
      label: 'Reefer inspections',
      routerLink: ROUTING_KEY_REEFER_INSPECTIONS,
      icon: 'fa-search',
      accessCode: ACCESS_CODE_REEFER_INSPECTIONS,
      accessCompanies: ['SOFTPAK']
    },
    {
      label: 'Estimate',
      routerLink: ROUTING_KEY_ESTIMATES,
      icon: 'fa-file-exclamation',
      accessCode: ACCESS_CODE_ESTIMATE,
      accessCompanies: ['SOFTPAK']
    },
    {
      label: 'Account',
      routerLink: ROUTING_KEY_LOGIN,
      icon: 'fa-user',
      menuStyle: 'navBar',
      hasAccess: true
    },
    {
      label: 'TimeSlots',
      routerLink: ROUTING_KEY_TIMESLOTS,
      //routerLink: ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION,
      icon: 'fa-shipping-timed',
      hasAccess: true
    },
    {
      label: 'Photo',
      routerLink: ROUTING_KEY_PHOTO,
      icon: 'fa-camera',
      accessCode: ACCESS_CODE_PHOTO,
    },
    {
      label: 'Reefer orders',
      routerLink: ROUTING_KEY_REEFER_ORDERS,
      icon: 'fa-plug',
      accessCode: ACCESS_CODE_REEFER_ORDERS,
    },
    {
      label: 'Reefer monitoring',
      routerLink: ROUTING_KEY_REEFER_MONITORING,
      icon: 'fa-temperature-low',
      accessCode: ACCESS_CODE_REEFER_MONITORING,
    },
    {
      label: 'Quayjobs',
      routerLink: ROUTING_KEY_QUAYJOBS,
      icon: 'fa-ship',
      accessCode: ACCESS_CODE_QUAYJOB,
    },
    {
      label: 'Add TAR',
      routerLink: ROUTING_KEY_IMPORT_TAR,
      icon: 'fa-plus-square',
      accessCode: ACCESS_CODE_IMPORT_TAR,
    },
    {
      label: 'Appointment Stats',
      routerLink: ROUTING_KEY_APPOINTMENT_STATS,
      icon: 'fa-chart-bar',
      accessCode: ACCESS_CODE_APPOINTMENT_STATS,
    },
    {
      label: 'Equipment Stats',
      routerLink: ROUTING_KEY_EQUIPMENT_STATS,
      icon: 'fa-chart-bar',
      accessCode: ACCESS_CODE_EQUIPMENT_STATS
    },
    {
      label: 'Information',
      routerLink: ROUTING_KEY_INFORMATION,
      icon: 'fa-info',
      accessCompanies: [
        'SOFTPAK',
        'POC',
        'DFT'
      ]
    },
    {
      label: 'Dashboards',
      routerLink: ROUTING_KEY_DASHBOARDS,
      icon: 'fa-chart-pie',
      accessCode: ACCESS_CODE_DASHBOARDS
    },
    {
      label: 'Settings',
      routerLink: ROUTING_KEY_SETTINGS_2,
      icon: 'fa-cog',
      menuStyle: 'navBar',
      hasAccess: true
    },
  ];

  constructor(private readonly appStore: Store<fromRoot.AppState>,
              private readonly jsdoSessionService: JsdoSessionService,
              private readonly containerService: ContainerInfoService,
              private readonly sessionService: SessionService) {
    this.updateMenuBasedOnAccessRights();

    this.jsdoSessionService.user$.subscribe(user => {
      if (user) {
        this.checkAccess();
      }
    });
  }

  ngOnInit() {
    /* if (this.sessionService.company === 'POC') {
      this.menuItems
        .filter(m => m.routerLink === ROUTING_KEY_TIMESLOTS)
        .forEach(menuItem => menuItem.routerLink = ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION);
    } */

    this.containerService.getBackendSettings().then((settings: any) => {
      console.log(settings);
      this.menuItems
        .filter(m => m.routerLink === ROUTING_KEY_TIMESLOTS)
        .forEach(menuItem => {
          if (settings.timeslotDepotList) {
            console.log("TERMINALS");
            menuItem.routerLink = ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION
          }       
        });
    });
  }

  private updateMenuBasedOnAccessRights(): void {
    this.appStore.pipe(select(fromAccessRights.selectAccessRights)).subscribe(accessRights => {
      this.menuItems.filter(menuItem => menuItem.accessCode).forEach(menuItem => {
        menuItem.hasAccess = accessRights.includes(menuItem.accessCode);
      });

      this.checkAccessForAddTar();
    });
  }

  // Move away from menu-logic
  private checkAccess(): void {
    this.menuItems
        .filter(m => !m.accessCode && (!m.accessCompanies || m.accessCompanies.includes(SettingsService.getSetting('company'))))
        .forEach(menuItem => {
        menuItem.hasAccess = true;
      });
    if (this.jsdoSessionService.userSource.value && this.jsdoSessionService.userSource.value !== 'anonymous') {
      this.menuItems
        .filter(m => m.accessCode)
        .filter(m => !m.accessCompanies || m.accessCompanies.includes(SettingsService.getSetting('company')))
        .forEach(menuItem => {
        this.appStore.dispatch(accessRightsActions.CHECK_ACCESS_RIGHTS({ key: menuItem.accessCode }));
      });
    } else {
      this.appStore.dispatch(accessRightsActions.RESET({}));
    }
  }

  private checkAccessForAddTar(): void {
    if (this.jsdoSessionService.userSource.value === 'anonymous') {
      this.containerService.getBackendSettings().then((settings: any) => {
        this.menuItems.filter(m => m.routerLink === '/import-tar').forEach(menuItem => {
          if (settings.anonymousImportTar) {
            menuItem.hasAccess = true;
          }
        });
      });
    }
  }
}
