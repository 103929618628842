import { Injectable } from '@angular/core';
import { BookingStatsBar } from 'src/app/booking-stats/models/booking-stats-bar.class';
import { GraphService } from './graph.service';
import { ChartColumnInterface } from '../models/chart-column.interface';

@Injectable({
  providedIn: 'root'
})
export class ImportExportFullEmptyBarsService {
    public static determineBars(
        splitFullEmpty: boolean,
        splitImportExport: boolean,
        emptyActive: boolean = true,
        fullActive: boolean = true,
        dropOffActive: boolean = true,
        pickUpActive: boolean = true,
        dropOffEmptyActive: boolean = true,
        dropOffFullActive: boolean = true,
        pickUpEmptyActive: boolean = true,
        pickUpFullActive: boolean = true,
        ): BookingStatsBar[] {
        const bars: BookingStatsBar[] = [];
        bars.push(new BookingStatsBar({
            active: true,
            color: GraphService.Colors.TOTAL,
            countDropOffEmpty: true,
            countDropOffFull: true,
            countPickUpEmpty: true,
            countPickUpFull: true,
            isTotal: true,
            secondName: 'Total',
            showTotalInGraph: !splitFullEmpty && !splitImportExport,
        }));
        if (splitFullEmpty && splitImportExport) {
            bars.push(new BookingStatsBar({
                active: dropOffEmptyActive,
                color: GraphService.Colors.DROP_OFF_EMPTY,
                countDropOffEmpty: true,
                secondName: 'Empty',
            }));
            bars.push(new BookingStatsBar({
                active: dropOffFullActive,
                color: GraphService.Colors.DROP_OFF_FULL,
                countDropOffFull: dropOffFullActive,
                secondName: 'Full',
            }));
            bars.push(new BookingStatsBar({
                active: pickUpEmptyActive,
                color: GraphService.Colors.PICK_UP_EMPTY,
                countPickUpEmpty: true,
                secondName: 'Empty',
            }));
            bars.push(new BookingStatsBar({
                active: pickUpFullActive,
                color: GraphService.Colors.PICK_UP_FULL,
                countPickUpFull: true,
                secondName: 'Full',
            }));
        } else if (splitFullEmpty && !splitImportExport) {
            bars.push(new BookingStatsBar({
                active: emptyActive,
                color: GraphService.Colors.EMPTY,
                countDropOffEmpty: true,
                countPickUpEmpty: true,
                secondName: 'Empty',
            }));
            bars.push(new BookingStatsBar({
                active: fullActive,
                color: GraphService.Colors.FULL,
                countDropOffFull: true,
                countPickUpFull: true,
                secondName: 'Full',
            }));
        } else if (!splitFullEmpty && splitImportExport) {
            bars.push(new BookingStatsBar({
                active: dropOffActive,
                color: GraphService.Colors.DROP_OFF,
                countDropOffEmpty: true,
                countDropOffFull: true,
                secondName: 'Drop-off',
            }));
            bars.push(new BookingStatsBar({
                active: pickUpActive,
                color: GraphService.Colors.PICK_UP,
                countPickUpEmpty: true,
                countPickUpFull: true,
                secondName: 'Pick-up',
            }));
        }
        return bars;
    }

    public static convertRowsToValues(rows: any, bars: BookingStatsBar[], includeTotalsAtBottom = false): ChartColumnInterface[][] {
        return rows.map(row => {
          return bars.map(bar => {
            return {
              active: bar.active,
              color: bar.color,
              isTotal: bar.isTotal,
              showTotalInGraph: bar.showTotalInGraph,
              value:
                row === null ? 0 : (
                  (bar.countDropOffEmpty ? row.dropOffEmpty : 0) +
                  (bar.countDropOffFull ? row.dropOffFull : 0) +
                  (bar.countPickUpEmpty ? row.pickupEmpty : 0) +
                  (bar.countPickUpFull ? row.pickupFull : 0)
                )
            };
          });
        });
    }
}
