export class Sanitize {
    public static basic(input: string, regex: RegExp, toUppercase: boolean): string {
        if (input) {
            input = input.replace(regex, '');
            input = input.toUpperCase();
        }
        return input;
    }

    public static equipmentNumber = () => {
        return (input: string): string => {
            return Sanitize.basic(input, /[^a-zA-Z0-9]/g, true);
        };
    }
}
