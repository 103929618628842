import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-quayjob',
  templateUrl: './quayjob.component.html',
  styleUrls: ['./quayjob.component.scss']
})
export class QuayjobComponent implements OnInit {

  quayjobid;
  stowLocation;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private navigationService: NavigationService) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.quayjobid = params.get('quayjobid');
      this.navigationService.setLabel('Quayjob: ' + this.quayjobid);
    });
  }

}
