export const ROUTING_KEY_ABOUT = 'about';
export const ROUTING_KEY_APPOINTMENT_STATS = 'appointmentstats';
export const ROUTING_KEY_BOOKING_DETAILS = 'bookingdetails';
export const ROUTING_KEY_CONTAINER = 'container';
export const ROUTING_KEY_CONTAINER_INFO = 'containerinfo';
export const ROUTING_KEY_DASHBOARDS = 'dashboards';
export const ROUTING_KEY_ESTIMATES = 'est';
export const ROUTING_KEY_ESTIMATE_LINES = 'lne';
export const ROUTING_KEY_EQUIPMENT_STATS = 'equipmentstats';
export const ROUTING_KEY_EQUIPMENT_STATS_DETAIL = 'equipmentstatsdetail';
export const ROUTING_KEY_IMPORT_TAR = 'import-tar';
export const ROUTING_KEY_IMPORT_TAR_ADDED = 'import-tar-added';
export const ROUTING_KEY_INFORMATION = 'info';
export const ROUTING_KEY_INSPECTIONS = 'ins';
export const ROUTING_KEY_KIOSKVISIT = 'kioskvisit';
export const ROUTING_KEY_LOGIN = 'login';
export const ROUTING_KEY_REDELIVERY = 'rdl';
export const ROUTING_KEY_PHOTO = 'photo';
export const ROUTING_KEY_REEFER_INSPECTIONS = 'rfi';
export const ROUTING_KEY_REEFER_MONITORING = 'reefer-monitoring-stacks';
export const ROUTING_KEY_REEFER_MONITORING_DETAILS = 'reefer-monitoring-details';
export const ROUTING_KEY_REEFER_MONITORING_SELECT = 'reefer-monitoring-select';
export const ROUTING_KEY_REEFER_ORDERS = 'reefer-connection';
export const ROUTING_KEY_QUAYJOBS = 'quayjobList';
export const ROUTING_KEY_QUAYJOB_DETAIL = 'quayjob';
export const ROUTING_KEY_RECENT_SEARCHES = 'recentsearches';
export const ROUTING_KEY_SETTINGS = 'set';
export const ROUTING_KEY_SETTINGS_2 = 'settings';
export const ROUTING_KEY_TIMESLOTS = 'timeslots';
export const ROUTING_KEY_TIMESLOT_TERMINAL_SELECTION = 'timeslots-terminal-select';
export const ROUTING_KEY_TIMESLOT_DETAILS = 'timeslotDetails';
export const ROUTING_KEY_VBS_LINES = 'vbslines';
export const ROUTING_KEY_UPDATE = 'update';
export const ROUTING_KEY_WELCOME = 'welcome';
export const ROUTING_KEY_LOGGING = 'logging';
