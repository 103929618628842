import { Component, OnInit, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, AfterViewInit {

  private brandCode;
  buildNr;
  innerWidth;
  innerHeight;

  constructor(private elementRef: ElementRef,
              private settingsService: SettingsService,
              private navigationService: NavigationService,
              private sessionService: SessionService) { }

  ngOnInit() {
    this.brandCode = this.settingsService.brandCode;
    this.buildNr = this.sessionService.build;
    this.navigationService.setLabel('Information');
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#00457c';
  }

}
