import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import * as fromEquipmentStats from '../../../store/equipment-stats/equipment-stats.reducer';
import * as fromSettings from '../../../store/settings/settings.reducer';
import { Subscription } from 'rxjs';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';
import { BookingStatsBar } from 'src/app/booking-stats/models/booking-stats-bar.class';
import { ChartColumnInterface } from 'src/app/softpak/charts/models/chart-column.interface';
import { EquipmentStatsFiltersInterface } from '../../models/equipment-stats-filters.interface';
import { unsubscribeSubscriptions } from 'src/app/softpak/functions/unsubscribe-subscriptions';
import { ImportExportFullEmptyBarsService } from 'src/app/softpak/charts/services/import-export-full-empty-bars.service';

@Component({
  selector: 'app-equipment-stats-graph',
  templateUrl: './equipment-stats-graph.component.html',
  styleUrls: ['./equipment-stats-graph.component.scss']
})
export class EquipmentStatsGraphComponent implements OnDestroy, OnInit {
  private data;
  public bars: BookingStatsBar[] = [];
  public columns: ChartColumnInterface[][];
  public currentDepot: string;
  public equipmentGroups: any[] = [];
  public labels: string[] = [];
  private filters: EquipmentStatsFiltersInterface;
  private readonly subscriptions: {
    selectAll?: Subscription;
    selectDepot?: Subscription;
  } = {};
  public receiveTime: string;

  constructor(private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit() {
    this.subscribeToFavouriteDepot();
    this.subscribeToStats();
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
  }

  private subscribeToFavouriteDepot(): void {
    this.currentDepot = DepotSettingsService.getFavouriteDepot();
    this.subscriptions.selectDepot = this.appStore.pipe(select(fromSettings.selectDepot))
      .subscribe(() => {
        this.currentDepot = DepotSettingsService.getFavouriteDepot();
        this.recalculate();
      });
  }

  private subscribeToStats(): void {
    this.subscriptions.selectAll = this.appStore.pipe(select(fromEquipmentStats.selectAll))
      .subscribe((all) => {
        this.data = all.data;
        this.filters = all.filters;
        this.receiveTime = all.receiveTime;
        this.recalculate();
      });
  }

  private recalculate(): void {
    this.bars = ImportExportFullEmptyBarsService.determineBars(
      this.filters ? this.filters.splitFullEmpty : true,
      this.filters ? this.filters.splitImportExport : true
    );
    if (this.data) {
      this.equipmentGroups = this.data.map(equipmentGroupData => {
        return {
          depotCode: equipmentGroupData.equipments[0].depotCode,
          labels: this.getLabels(equipmentGroupData),
          title: equipmentGroupData.equipments[0].equipmentGroupcode,
          values: ImportExportFullEmptyBarsService.convertRowsToValues(equipmentGroupData.equipments, this.bars),
        };
      });

      // Add Totals Row to each Equipment Group
      this.equipmentGroups.forEach(equipmentGroup => {
        const totalsRow = [];
        equipmentGroup.values[0].forEach((column, columnIndex) => {
          let totalForColumn = 0;
          equipmentGroup.values.forEach(row => {
            totalForColumn += row[columnIndex].value;
          });
          totalsRow.push({ ...equipmentGroup.values[0][columnIndex], isTotal: true, value: totalForColumn });
        });
        equipmentGroup.labels.push('Totals');
        equipmentGroup.values.push(totalsRow);
      });
    }
  }

  getLabels(equipmentGroup: any): string[] {
    return equipmentGroup.equipments
      .filter(equipment => equipment.depotCode === this.currentDepot)
      .map(equipment => equipment.equipmentCode);
  }

  getValues(bars: BookingStatsBar[], equipmentGroup: any): ChartColumnInterface[][] {
    return ImportExportFullEmptyBarsService.convertRowsToValues(equipmentGroup.equipments, bars);
  }
}
