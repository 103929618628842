import { NgModule } from '@angular/core';
import { ToasterService, ToasterModuleV1 } from 'softpak-frontend-toaster';

@NgModule({
    declarations: [
    ],
    imports: [
        ToasterModuleV1
    ],
    providers: [
        ToasterService
    ],
    exports: [
        ToasterModuleV1
    ]
})
export class CoreModule { }
