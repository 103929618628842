import * as actions from './depot-settings.actions';
import { createReducer, createSelector, on } from '@ngrx/store';
import { AppState } from '../..';

const thisReducer = (state: AppState): DepotSettingsState => state.depotSettings;

export interface DepotSettingsState {
    savedDepotData: any;
    depotDetailData: {
        depotCode: string;
        depotName: string;
    };
    loadingDetailData: boolean;
    loadingDepotSetting: boolean;
}

export const initialState: DepotSettingsState = {
    savedDepotData: null,
    depotDetailData: {
        depotCode: null,
        depotName: null
    },
    loadingDetailData: false,
    loadingDepotSetting: false,
};

const handleReducer = createReducer(
    initialState,
    on(actions.CLEAR_DEPOT_DATA, (state: DepotSettingsState): DepotSettingsState => {
        return {
            ...state,
            savedDepotData: null,
            depotDetailData: null,
            loadingDetailData: false,
            loadingDepotSetting: false,
        };
    }),
    on(actions.LOAD_DEPOT_DETAILS, (state: DepotSettingsState): DepotSettingsState => {
        return Object.assign({}, state, {
            depotDetailData: null,
            loadingDetailData: true,
        });
    }),
    on(actions.RECEIVED_DEPOT_DATA, (state: DepotSettingsState, { depotCode, depotName }): DepotSettingsState => {
        return Object.assign({}, state, {
            depotDetailData: { depotCode, depotName },
            loadingDetailData: false,
        });
    }),
    on(actions.LOAD_DEPOT_SETTING, (state: DepotSettingsState): DepotSettingsState => {
        return Object.assign({}, state, {
            savedDepotData: null,
            loadingDepotSetting: true,
        });
    }),
    on(actions.RECEIVED_DEPOT_SETTING, (state: DepotSettingsState, { depotData }): DepotSettingsState => {
        return Object.assign({}, state, {
            savedDepotData: { depotData },
            loadingDepotSetting: false,
        });
    }),
);

export function reducer(state: DepotSettingsState, action: actions.SuggestionActions): DepotSettingsState {
    return handleReducer(state, action);
}

export const selectSavedDepotData = createSelector(
    thisReducer, (state: DepotSettingsState) => state.savedDepotData
);

export const selectDetailData = createSelector(
    thisReducer, (state: DepotSettingsState) => state.depotDetailData
);

export const selectLoadingDetailData = createSelector(
    thisReducer, (state: DepotSettingsState) => state.loadingDetailData
);
