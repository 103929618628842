import { Injectable } from '@angular/core';
import { progress } from '../../progress/progress.jsdo';

import { JsdoSessionService } from '../jsdo-session.service';
import { JsdoService } from '../jsdo.service';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;

  constructor(private jsdoSessionService: JsdoSessionService,
              private jsdoService: JsdoService) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.jsdoService.prepare('dp_container');
            resolve();
          });
      });
   }

  getData(filter) {
    return new Promise((resolve, reject) => {
      this.jsdoSessionService.sessionReady.then(() => {
          this.jsdoService.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                resolve(this.jsdoService.dataSource.getData());
            });
        });
    });
  }

}
