import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { SettingsService } from '../services/settings.service';
import { VbsService } from '../services/jsdo-data/vbs.service';
import { DialogService } from '../services/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-tar',
  templateUrl: './import-tar.component.html',
  styleUrls: ['./import-tar.component.scss']
})
export class ImportTarComponent implements OnInit {

  tarFormGroup: FormGroup;

  constructor(private navigationService: NavigationService,
              private fb: FormBuilder,
              private settingsService: SettingsService,
              private vbsService: VbsService,
              private dialogService: DialogService,
              private router: Router
              ) { }

  ngOnInit() {
    this.navigationService.setLabel('Add TAR');

    this.tarFormGroup = this.fb.group({
      tar: new FormControl({value: '', disabled: false}, Validators.required),
      code: new FormControl({value: '', disabled: false}, Validators.required),
    });
  }

  addTar() {
    console.log('addTar');
    const clientGuid = this.settingsService.getClientGuid(true);
    this.vbsService.addLocalTar(this.tarFormGroup.controls.tar.value, this.tarFormGroup.controls.code.value, clientGuid).then(accepted => {
      if (!accepted) {
        this.dialogService.alert('Invalid TAR or verification code', () => {
        });
      } else {
        this.router.navigate(['import-tar-added', this.tarFormGroup.controls.code.value]);
      }

    });
  }

}
