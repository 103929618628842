import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dayText'
})
export class DayTextPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const date = moment(value);
    let specialDay: string;
    if (date.startOf('day').unix() === moment().startOf('day').unix()) {
      specialDay = 'Today';
    } else if (date.startOf('day').unix() === moment().startOf('day').add(1, 'days').unix()) {
      specialDay = 'Tomorrow';
    } else if (date.startOf('day').unix() === moment().startOf('day').add(-1, 'days').unix()) {
      specialDay = 'Yesterday';
    }

    const text = moment(date).format('dddd') + ', ' + moment(date).format('D MMMM') +
    (specialDay ? ' (' + specialDay + ')' : '');
    return text;
  }
}
