import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CordovaService } from '../services/cordova.service';
import { UuidHelper } from './helpers/uuid.helper';

export enum SearchHistoryType {
    CONTAINER = 'CONTAINER',
    BOOKING = 'BOOKING'
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

    private brandCodeReject;
    private brandCodeResolve;

    public brandCode = new Promise((resolve, reject) => {
      this.brandCodeResolve = resolve;
      this.brandCodeReject = reject;
    });

    constructor(private http: HttpClient,
                private cordovaService: CordovaService) {
        this.http.get('assets/branding.json').subscribe((result: any) => {
            this.brandCodeResolve(result.brandCode);
         });
    }

    public static setSetting(settingName, settingValue): void {
        localStorage.setItem(settingName, settingValue);
    }

    public static getSetting(settingName): any {
        return localStorage.getItem(settingName);
    }

    getArraySetting(settingName) {
        const myArrStr = SettingsService.getSetting(settingName);
        let myArr = [];
        if (myArrStr) {
            myArr = JSON.parse(myArrStr);
        }

        return myArr;
    }

    putArraySetting(settingName, settingValue) {
        const myArr = this.getArraySetting(settingName);

        let found = false;
        const arrayLength = myArr.length;
        for (let i = 0; i < arrayLength; i++) {
            if (myArr[i] === settingValue) {
                found = true;
            }
        }
        if (found === false) {
            myArr.push(settingValue);
        }

        SettingsService.setSetting(settingName, JSON.stringify(myArr));
    }

    setCompanySetting(settingName, settingValue) {
        const company = SettingsService.getSetting('company');
        let storageObj;
        const storageObjStr = localStorage.getItem('companySettings');

        if (storageObjStr) {
            storageObj = JSON.parse(storageObjStr);
        } else {
            storageObj = {};
        }

        if (!storageObj[company]) {
            storageObj[company] = {};
        }

        storageObj[company][settingName] = settingValue;

        localStorage.setItem('companySettings', JSON.stringify(storageObj));

    }

    getCompanySetting(settingName) {
        const company = SettingsService.getSetting('company');
        let storageObj;
        const storageObjStr = localStorage.getItem('companySettings');

        if (storageObjStr) {
            storageObj = JSON.parse(storageObjStr);
            if (!storageObj[company]) {
                return undefined;
            }
            return storageObj[company][settingName];
        } else {
            return undefined;
        }
    }

    clearSearchHistory(): void {
        this.setCompanySetting('containerHistory', JSON.stringify([]));
    }

    removeFromSearchHistory(type: SearchHistoryType, itemToRemove): void {
        const history = JSON.parse(this.getCompanySetting('containerHistory') || '[]');
        const remove = [];
        let indexToRemove: number;
        switch (type) {
            case SearchHistoryType.BOOKING:
                indexToRemove = history.findIndex(item => item.booking === itemToRemove.booking);
                if (indexToRemove > -1) {
                    remove.push(indexToRemove);
                }
                break;
            case SearchHistoryType.CONTAINER:
                indexToRemove = history.findIndex(item => item.cntr === itemToRemove.cntr.substr(0, 4) + ' ' + itemToRemove.cntr.substr(4));
                if (indexToRemove > -1) {
                    remove.push(indexToRemove);
                }
                break;

        }

        // Remove items from list that were marked for deletion
        for (let i = remove.length; i > 0 ; i--) {
            history.splice(remove[i - 1], 1);
        }

        this.setCompanySetting('containerHistory', JSON.stringify(history));
    }

    addToSearchHistory(type: SearchHistoryType, query: string): void {
        const history = JSON.parse(this.getCompanySetting('containerHistory') || '[]');
        const remove = [];
        let itemToAdd: object;
        let indexToRemove: number;

        switch (type) {
            case SearchHistoryType.BOOKING:
                indexToRemove = history.findIndex(item => item.booking === query);
                if (indexToRemove > -1) {
                    remove.push(indexToRemove + 1);
                }
                itemToAdd = {booking: query};
                break;
            case SearchHistoryType.CONTAINER:
                indexToRemove = history.findIndex(item => item.cntr === query.substr(0, 4) + ' ' + query.substr(4));
                if (indexToRemove > -1) {
                    remove.push(indexToRemove + 1);
                }
                itemToAdd = {cntr: query.substr(0, 4) + ' ' + query.substr(4)};
                break;

        }

        // First, add the new item to the top of the list
        history.unshift(itemToAdd);

        // The limit to 15 bookings, and 15 bookings
        let numberOfBookingsSaved = 0;
        let numberOfContainersSaved = 0;
        history.forEach((item, index) => {
            if (item.cntr) {
                numberOfContainersSaved++;
                if (numberOfContainersSaved > 15) {
                    remove.push(index);
                }
            }
            if (item.booking) {
                numberOfBookingsSaved++;
                if (numberOfBookingsSaved > 15) {
                    remove.push(index);
                }
            }
        });

        // Remove items from list that were marked for deletion
        for (let i = remove.length; i > 0 ; i--) {
            history.splice(remove[i - 1], 1);
        }

        this.setCompanySetting('containerHistory', JSON.stringify(history));
    }

    getHistoryContainer () {
        return JSON.parse(this.getCompanySetting('containerHistory') || '[]');
    }

    addLocalTar(tar) {
        const tarList = JSON.parse(this.getCompanySetting('localTARList') || '[]');

        // Add item to top of the list
        tarList.unshift({tar: tar});

        // Only Keep 15 most recent items
        if (tarList.length >= 15) {
            tarList.splice(15);
        }

        this.setCompanySetting('localTARList', JSON.stringify(history));
    }

    getLocalTarList () {
        return JSON.parse(this.getCompanySetting('localTARList') || '[]');
    }

    addPhoto (source?) {
        const options: any = {
            quality: 50,
            destinationType: 0,
            targetWidth: 1024,
            targetHeight: 1024
        };

        if (source === 'library') {
            options.sourceType = this.cordovaService.camera.PictureSourceType.PHOTOLIBRARY;
        }

        return new Promise((resolve, reject) => {
            this.cordovaService.camera.getPicture(imageData => {
                resolve(imageData);
            }, error => {
                reject(error);
            }, options
            );
        });
    }

    getClientGuid(create) {
        let GUID = SettingsService.getSetting('clientGUID');
        if (!GUID && create) {
            GUID = UuidHelper.uuid();
            SettingsService.setSetting('clientGUID', GUID);
        }
        return GUID;
    }
}


