import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

// Service that holds list of connections, and helper methods to get connection info and get / set custom connections

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private fixedConnections = [
    {
      name: 'SOFTPAK',
      serviceURI: 'https://sp-vpasdev.softpak.nl:8931/DpApp/',
      companycode: 'ROW',
      description: 'Softpak demo terminal',
      logo: 'customer_logos/SOFTPAK.png',
      brandCode: null
    },
    {
      name: 'SOFTPAK-local',
      serviceURI: 'http://localhost:8830/DpApp/',  
      companycode: 'ROW',
      description: 'Softpak local',
      logo: 'customer_logos/SOFTPAK.png',
      brandCode: null
    },
    {
      name: 'POC',
      serviceURI: 'https://cctapp.portofcork.ie:8443/DpApp/',
      companycode: 'POC',
      description: 'Port of Cork',
      logo: 'customer_logos/POC.png',
      brandCode: 'POC'
    },
    {
      name: 'DFT',
      serviceURI: 'https://portal.dft.ie:8443/DpApp/',
      companycode: 'DFT',
      description: 'DFT (Dublin Ferryport Terminal)',
      logo: 'customer_logos/DFT.png',
      brandCode: 'DFT'
    },
    {
      name: 'BCT',
      serviceURI: 'https://www.bcterminal.com:8443/DpApp/',
      companycode: 'DFT',
      description: 'BCT (Belfast Container Terminal)',
      logo: 'customer_logos/BCT.png',
      brandCode: 'DFT'
    },
    {
      name: 'RCC',
      serviceURI: 'http://37.97.139.181:8888/DpApp-RCC/',
      companycode: 'CET',
      description: 'RCC',
      logo: 'customer_logos/RCC.png',
      privateCode: 'RCC'
    },
    {
      name: 'CETEM',
      serviceURI: 'http://37.97.139.181:8888/DpApp-CET/',
      companycode: 'CET',
      description: 'CETEM',
      logo: 'customer_logos/cetem.gif',
      privateCode: 'CETEM'
    },
    {
      name: 'BDP',
      serviceURI: 'https://softpakapp.broekmanlogistics.com:8443/DpApp/',
      companycode: 'BRM',
      description: 'Broekman Logistics',
      logo: 'customer_logos/BDP.jpg',
      brandCode: 'BDP'
    },
    {
      name: 'ZZC',
      serviceURI: 'https://app.zzcoldstores.com:8443/DpApp/',
      companycode: 'ZZC',
      description: 'ZZColdstores',
      logo: 'customer_logos/ZZC.png',
      brandCode: 'ZZC',
    },
    {
      name: 'KRA',
      serviceURI: 'https://spwebapi.kramergroup.nl:8443/DpApp/',
      companycode: 'KRA',
      description: 'Kramer',
      logo: 'customer_logos/KRAMER.png'
    },
    {
      name: 'MRTX',
      serviceURI: 'http://app.matranscustomers.nl:8443/DpApp/',
      companycode: 'PCS',
      description: 'Matrans Rotterdam Terminal',
      logo: 'customer_logos/MRT.png'
    },
    {
      name: 'TMA',
      serviceURI: 'https://sp-portal.tmalogistics.nl:8443/DpApp/',
      companycode: 'TMA',
      description: 'TMA',
      logo: 'customer_logos/TMA.png'
    },
    {
      name: 'CTL-ICS',
      serviceURI: 'http://37.48.105.21:9080/DpApp/',
      companycode: 'CTL',
      description: 'CTL-ICS',
      logo: 'customer_logos/CTL-ICS.png'
    },
    {
      name: 'UWT',
      serviceURI: 'https://apps.waalhaven-group.nl:8443/DpApp/',
      companycode: 'WHT',
      description: 'WHT',
      logo: 'customer_logos/UWT.jpg'
    },
    {
      name: 'UWT-TEST',
      serviceURI: 'https://apps.waalhaven-group.nl:8443/DpAppTest/',
      companycode: 'WHT',
      description: 'WHT TEST',
      logo: 'customer_logos/UWT.jpg'
    },
    {
      name: 'DFT-TEST',
      serviceURI: 'https://portal.dft.ie:8443/DpAppTest/',
      companycode: 'DFT',
      description: 'DFT TEST',
      logo: 'customer_logos/DFT.png',
      brandCode: 'DFT'
    },
    {
      name: 'STT', // Stateline 
      serviceURI: 'https://app.stateline.ie:8543/DpApp_NONSSO/',
      companycode: 'STT',
      description: 'Stateline',
      logo: 'customer_logos/stateline.jpg'
    },
    {
      name: 'SOFTPAK-OLD',
      serviceURI: 'https://vpas.softpak.nl:8814/DpApp/',  // 8813 = HTTP, 192.168.117.235 = intern
      companycode: 'ROW',
      description: 'Softpak-OLD',
      logo: 'customer_logos/SOFTPAK.png',
      brandCode: null
    },

  ];
  private customConnections = [];

  constructor(private settingsService: SettingsService) {
    this.customConnections = this.settingsService.getArraySetting('custom_connections');
  }

  getConnection(name) {
    const connections = this.fixedConnections.concat(this.customConnections);
    for (let i = 0; i < connections.length; i++) {
      const connection = connections[i];
      if (connection.name === name) {
        return connection;
      }
    }
  }

  getConnectionList(brandCode) {
    const connectionList = [];
    const connections = this.fixedConnections.concat(this.customConnections);
    for (let i = 0; i < connections.length; i++) {
      const connection = connections[i];
      if (brandCode === 'SOFTPAK' || connection.brandCode === brandCode) {
        if (brandCode !== 'SOFTPAK' || !connection.privateCode || this.hasPrivateConnection(connection.privateCode)) {
          connectionList.push(connection);
        }
      }
    }
    return connectionList;
  }

  hasPrivateConnection(privateCode) {
    const myArr = this.settingsService.getArraySetting('privateConnections');
    const arrayLength = myArr.length;
      for (let i = 0; i < arrayLength; i++) {
          if (myArr[i] === privateCode) {
              return true;
          }
      }
      return false;
  }

  addCustomConnection(name, serviceURI, description, companycode) {
    const connection = {
      name: name,
      serviceURI: serviceURI,
      companycode: companycode,
      description: description
    };

    this.settingsService.putArraySetting('custom_connections', connection);
    this.customConnections.push(connection);
  }

}
