import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-app-logging',
  templateUrl: './app-logging.component.html',
  styleUrls: ['./app-logging.component.scss']
})
export class AppLoggingComponent implements OnInit {

  errors;

  constructor(private navigationService: NavigationService) { }

  ngOnInit() {
    this.errors = [];
    this.navigationService.setLabel('Logging');

    let logStr = localStorage.getItem("errorLog");
    if (logStr) {
      this.errors = JSON.parse(logStr);
    }
  }

  clearLogging() {
    localStorage.removeItem('errorLog');
    this.errors = [];
  }

}
