import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import * as equipmentStatsActions from '../../../store/equipment-stats/equipment-stats.actions';
import { EquipmentStatsSettingsService } from 'src/app/services/settings-equipment-stats.service';

@Component({
  selector: 'app-equipment-stats-filters',
  templateUrl: './equipment-stats-filters.component.html',
  styleUrls: ['./equipment-stats-filters.component.scss']
})
export class EquipmentStatsFiltersComponent implements OnInit {
  @Input() canSplitPerMachine = false;
  @Input() show: boolean;
  public formgroup: FormGroup;
  public get ctrlSplitFullEmpty(): FormControl { return this.formgroup.get('splitFullEmpty') as FormControl; }
  public get ctrlSplitImportExport(): FormControl { return this.formgroup.get('splitImportExport') as FormControl; }
  public get ctrlSplitPerMachine(): FormControl { return this.formgroup.get('splitPerMachine') as FormControl; }

  constructor(private readonly formBuilder: FormBuilder,
              private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit() {
    this.formgroup = this.formBuilder.group({
      splitFullEmpty: [true, []],
      splitImportExport: [true, []],
      splitPerMachine: [true, []],
    });

    const filtersFromStorage = EquipmentStatsSettingsService.getFilters();
    this.formgroup.setValue({
      splitFullEmpty: (filtersFromStorage !== null && filtersFromStorage.splitFullEmpty !== undefined) ?
        filtersFromStorage.splitFullEmpty : true,
      splitImportExport: (filtersFromStorage !== null && filtersFromStorage.splitImportExport !== undefined) ?
        filtersFromStorage.splitImportExport : true,
      splitPerMachine: (filtersFromStorage !== null && filtersFromStorage.splitPerMachine !== undefined) ?
        filtersFromStorage.splitPerMachine : true,
    });
    this.appStore.dispatch(equipmentStatsActions.UPDATE_FILTERING({ filters: this.formgroup.getRawValue() }));
    this.listenToSwitches();
  }

  private listenToSwitches(): void {
    this.ctrlSplitFullEmpty.valueChanges.subscribe(() => {
      this.updateFilters();
    });
    this.ctrlSplitImportExport.valueChanges.subscribe(() => {
      this.updateFilters();
    });
    this.ctrlSplitPerMachine.valueChanges.subscribe(() => {
      this.updateFilters();
    });
  }

  private updateFilters(): void {
    this.appStore.dispatch(equipmentStatsActions.UPDATE_FILTERING({ filters: {
      splitFullEmpty: this.ctrlSplitFullEmpty.value,
      splitImportExport: this.ctrlSplitImportExport.value,
      splitPerMachine: this.ctrlSplitPerMachine.value,
    }}));
  }
}
