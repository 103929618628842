import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GraphService } from '../services/graph.service';

@Component({
  selector: 'sp-chart-label',
  templateUrl: './chart-label.component.html',
  styleUrls: ['./chart-label.component.scss']
})
export class ChartLabelComponent implements OnChanges, OnInit {
  public colorPallet: any;
  @Input() color: number;

  constructor(private readonly graphService: GraphService) { }

  ngOnChanges(changes): void {
    if (changes.color) {
      this.colorPallet = GraphService.getColorByIndex(changes.color.currentValue);
    }
  }

  ngOnInit() {
  }

}
