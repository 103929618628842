import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { BookingStatsDay } from '../../models/booking-stats-day.interface';
import * as fromRoot from '../../../store/index';
import * as fromBookingStats from '../../../store/booking-stats/booking-stats.reducer';
import { Store, select } from '@ngrx/store';
import { BookingStatsBar } from '../../models/booking-stats-bar.class';
import { ChartTotalWithIcon } from 'src/app/softpak/charts/models/chart-total-with-icon';
import { ChartColumnInterface } from 'src/app/softpak/charts/models/chart-column.interface';
import { ImportExportFullEmptyBarsService } from 'src/app/softpak/charts/services/import-export-full-empty-bars.service';

@Component({
  selector: 'app-booking-stats-day',
  templateUrl: './booking-stats-day.component.html',
  styleUrls: ['./booking-stats-day.component.scss']
})
export class BookingStatsDayComponent implements OnChanges, OnInit {
  @Input() data: BookingStatsDay;
  @Input() highestValue: number;
  @Input() stepSize: number;
  @Input() numberOfSteps: number;
  @Input() stepLines: number[];
  public bars: BookingStatsBar[] = [];
  public columns: ChartColumnInterface[][];
  public labels: string[] = [];
  public totals: ChartTotalWithIcon[][] = [];

  constructor(private readonly appStore: Store<fromRoot.AppState>) { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.determineBars();
    this.columns = this.getValues(this.bars, this.data);
    this.labels = this.getLabels(this.data);
    this.totals = this.getTotals(this.data);
  }

  getValues(bars: BookingStatsBar[], day: BookingStatsDay): ChartColumnInterface[][] {
    const result: ChartColumnInterface[][] = [];
    day.periods.forEach(period => {
      const periodResult: ChartColumnInterface[] = [];
      bars.forEach((bar) => {
        periodResult.push({ value:
          ((bar.countDropOffEmpty === true ? 1 : 0) * period.dropOffEmpty +
          (bar.countDropOffFull === true ? 1 : 0) * period.dropOffFull +
          (bar.countPickUpEmpty === true ? 1 : 0) * period.pickupEmpty +
          (bar.countPickUpFull === true ? 1 : 0) * period.pickupFull),
          active: bar.active,
          color: bar.color,
          isTotal: bar.isTotal,
          showTotalInGraph: bar.showTotalInGraph,
        });
      });
      result.push(periodResult);
    });
    return result;
  }

  getLabels(day: BookingStatsDay): string[] {
    const labels: string[] = [];
    day.periods.forEach(period => {
      labels.push(`${period.startTimeTimeslot.substr(0, 2)}<sup>${(parseInt(period.startTimeTimeslot.substr(2, 2), 10) === 0) ? `h` : `${period.startTimeTimeslot.substr(2, 2)}`}</sup>`);
    });
    return labels;
  }

  getTotals(day: BookingStatsDay): ChartTotalWithIcon[][] {
    const result: ChartTotalWithIcon[][] = [];
    day.periods.forEach(period => {
      const periodResult: ChartTotalWithIcon[] = [];
      periodResult.push({
        icon: 'container-storage',
        value: period.dropOffEmpty + period.dropOffFull + period.pickupFull + period.pickupEmpty
      });
      periodResult.push({
        icon: 'truck',
        value: period.appointmentTotal
      });
      result.push(periodResult);
    });
    return result;
  }

  private determineBars(): void {
    this.appStore.pipe(select(fromBookingStats.selectBookingStatsFilters)).subscribe(filters => {
      this.bars = ImportExportFullEmptyBarsService.determineBars(
        filters ? filters.splitFullEmpty : true,
        filters ? filters.splitImportExport : true,
        filters ? filters.showEmpty : true,
        filters ? filters.showFull : true,
        filters ? filters.showExport : true,
        filters ? filters.showImport : true,
        filters ? filters.showExportEmpty : true,
        filters ? filters.showExportFull : true,
        filters ? filters.showImportEmpty : true,
        filters ? filters.showImportFull : true
      );
    });
  }
}
