import { Component, EventEmitter, OnInit, Input, Output, OnChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-refresh-bar',
  templateUrl: './refresh-bar.component.html',
  styleUrls: ['./refresh-bar.component.scss']
})
export class RefreshBarComponent implements OnChanges, OnInit {
  @Input() autoUpdate: number;
  @Input() filterButton = true;
  @Input() refreshing: boolean;
  @Input() refreshed: string;
  @Output() spRefresh: EventEmitter<void> = new EventEmitter<void>();
  @Output() spToggleFilters: EventEmitter<boolean> = new EventEmitter<boolean>();
  minutesSinceRefresh: number;
  timeSinceRefresh: number;
  showFilters = false;

  constructor() { }

  ngOnChanges() {
    this.update();
  }

  ngOnInit() {
    const t = this;
    setInterval(function() {
      t.update();
    }, 5000);
    this.timeSinceRefresh = this.getTimeSinceRefresh();
    this.minutesSinceRefresh = Math.ceil(this.getTimeSinceRefresh() / 60000);
  }

  checkAutoUpdate(): void {
    if (this.timeSinceRefresh > this.autoUpdate && !this.refreshing) {
      this.spRefresh.emit();
    }
  }

  getTimeSinceRefresh(): number {
    const duration = moment().diff(moment(this.refreshed));
    return duration;
  }

  onClickRefresh(): void {
    if (!this.refreshing) {
      this.spRefresh.emit();
    }
  }

  update(): void {
    this.timeSinceRefresh = this.getTimeSinceRefresh();
    this.minutesSinceRefresh = Math.ceil(this.getTimeSinceRefresh() / 60000);
    this.checkAutoUpdate();
  }

  onToggleFilters(): void {
    this.showFilters = !this.showFilters;
    this.spToggleFilters.emit(this.showFilters);
  }

}
