import * as actions from './equipment-stats.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { EquipmentStatsService } from 'src/app/services/jsdo-data/equipment-stats.service';

@Injectable()
export class EquipmentStatsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly equipmentStatsService: EquipmentStatsService,
    ) {}

    loadEquipmentStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.LOAD_EQUIPMENT_STATS),
            mergeMap(action => from(this.equipmentStatsService.getEquipmentStatsSummary())
                .pipe(
                    map((result: any[]) => actions.RECEIVED_EQUIPMENT_STATS({ equipmentStatsData: result })),
                    catchError((err) => of(this._equipmentDataErrorHandler(err)))
                )
        ))
    );

    private _equipmentDataErrorHandler(payload: any) {
        // if (payload.errorType && payload.errorType === 'BOOKING_NOT_FOUND') {
        //     return actions.ERROR({ errorType: payload.errorType, errorMessage: payload.message });
        // }
        return actions.ERROR({ errorType: 'UNKNOWN', errorMessage: 'An unknown error happened' });
    }
}
