import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SettingsService } from '../../services/settings.service';
import { NavigationService } from '../../services/navigation.service';
import { JsdoSessionService } from '../../services/jsdo-session.service';
import { SessionService } from '../../services/session.service';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('mid', style({ transform: 'translateX(-33.333%)' })),
      state('right', style({ transform: 'translateX(-66.666%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class WelcomeComponent implements OnInit {

  constructor(private settingsService: SettingsService,
              private router: Router,
              private navigationService: NavigationService,
              private jsdoSessionService: JsdoSessionService,
              private sessionService: SessionService,
              private connectionService: ConnectionService) { }

  private selectedCompany;
  public connections;
  activePane;
  login;
  password;
  branded;

  ngOnInit() {
    this.settingsService.brandCode.then(brandCode => {
      if (brandCode !== 'SOFTPAK') {
          this.branded = true;
      }

      this.connections = this.connectionService.getConnectionList(brandCode);
    });

    this.selectedCompany = this.sessionService.company;
    if (this.selectedCompany) {
      this.activePane = 'mid';
    }
  }

  onConfirmCompany() {
    if (this.selectedCompany) {
      this.sessionService.setCompany(this.selectedCompany);
      this.activePane = 'mid';
    } else {
      alert('Please select a company!'); // TODO: native dialog
    }
  }

  onConfirmIntro() {
    this.activePane = 'mid';
  }

  onLogin() {
    this.jsdoSessionService.login(this.login, this.password).then(() => {
      this.activePane = 'right';
    }, err => {
      alert('login failed');
    });
  }

  onSkipLogin() {
    this.activePane = 'right';
  }

  onFinish() {
    SettingsService.setSetting('welcomeDone', 'YES');
    this.router.navigate(['']);
  }

}
