import {
    STOR_KEY_RECENT_EQUIPMENTTYPES,
    STOR_KEY_RECENT_EQUIPMENTNUMBERS,
    STOR_KEY_RECENT_MANUFACTURERS,
    STOR_KEY_RECENT_DAMAGETYPES,
    STOR_KEY_RECENT_TEMPLATENUMBERS,
    STOR_KEY_RECENT_MACHINERY_MANUFACTURERS,
    STOR_KEY_RECENT_MACHINERY_MODELS,
    STOR_KEY_CURRENT_DEPOT
} from './storage-keys';
import {
    StorRecentEquipmentInterface,
    StorRecentEquipmentTypeInterface,
    StorRecentManufacturerTypeInterface,
    StorRecentTemplateNumberInterface,
    StorRecentDamageTypeInterface,
    StorRecentMachineryManufacturerInterface,
    StorRecentMachineryModelInterface
} from '../models/storage-interfaces';

export class FromStorage {
    public static currentDepot(): { depotCode: string, depotName: string } {
        return this.get(STOR_KEY_CURRENT_DEPOT) ? this.get(STOR_KEY_CURRENT_DEPOT)[0] as { depotCode: string, depotName: string } : null;
    }

    public static recentDamageTypes(): StorRecentDamageTypeInterface[] {
        return this.get(STOR_KEY_RECENT_DAMAGETYPES) as StorRecentDamageTypeInterface[] || [];
    }

    public static recentEquipmentNumbers(): StorRecentEquipmentInterface[] {
        return this.get(STOR_KEY_RECENT_EQUIPMENTNUMBERS) as StorRecentEquipmentInterface[] || [];
    }

    public static recentEquipmentTypes(): StorRecentEquipmentTypeInterface[] {
        return this.get(STOR_KEY_RECENT_EQUIPMENTTYPES) as StorRecentEquipmentTypeInterface[] || [];
    }

    public static recentMachineryManufacturers(): StorRecentMachineryManufacturerInterface[] {
        return this.get(STOR_KEY_RECENT_MACHINERY_MANUFACTURERS) as StorRecentMachineryManufacturerInterface[] || [];
    }

    public static recentMachineryModels(): StorRecentMachineryModelInterface[] {
        return this.get(STOR_KEY_RECENT_MACHINERY_MODELS) as StorRecentMachineryModelInterface[] || [];
    }

    public static recentManufacturers(): StorRecentManufacturerTypeInterface[] {
        return this.get(STOR_KEY_RECENT_MANUFACTURERS) as StorRecentManufacturerTypeInterface[] || [];
    }

    public static recentTemplateNumbers(): StorRecentTemplateNumberInterface[] {
        return this.get(STOR_KEY_RECENT_TEMPLATENUMBERS) as StorRecentTemplateNumberInterface[] || [];
    }

    public static get(key: string): any[] {
        const data = JSON.parse(localStorage.getItem(key));
        return data;
    }
}
