import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../services/dialog.service';
import { VbsService } from '../services/jsdo-data/vbs.service';
import { JsdoSessionService } from '../services/jsdo-session.service';

@Component({
  selector: 'app-vbs-booking-card',
  templateUrl: './vbs-booking-card.component.html',
  styleUrls: ['./vbs-booking-card.component.scss']
})
export class VbsBookingCardComponent implements OnInit {

  @Input() booking;

  canDelete = false;

  constructor(private router: Router,
              private dialogService: DialogService,
              private vbsService: VbsService,
              private jsdoSessionService: JsdoSessionService) { }

  ngOnInit() {
    if (this.jsdoSessionService.userSource.value && this.jsdoSessionService.userSource.value !== 'anonymous') {
      this.canDelete = true;
    }
  }

  gotoVbsLines(vbsBookingnr) {
    this.router.navigate(['vbslines', vbsBookingnr]);
  }

  onDelete(event, RecId) {
    event.stopPropagation();
    this.dialogService.confirm('Are you sure you want to remove this appointment?', lGo => {
      if (lGo) {
         this.vbsService.deleteRecord(RecId);
      }
    }, 'Confirm delete');
  }

}
