import { ArrayUtils } from './array-utils';
import { FromStorage } from './from-storage';
import { Sanitize } from './sanitize';
import {
    STOR_KEY_CURRENT_DEPOT,
    STOR_KEY_RECENT_DAMAGETYPES,
    STOR_KEY_RECENT_EQUIPMENTNUMBERS,
    STOR_KEY_RECENT_EQUIPMENTTYPES,
    STOR_KEY_RECENT_MACHINERY_MANUFACTURERS,
    STOR_KEY_RECENT_MACHINERY_MODELS,
    STOR_KEY_RECENT_MANUFACTURERS,
    STOR_KEY_RECENT_TEMPLATENUMBERS
    } from './storage-keys';

export class ToStorage {
    public static currentDepot(value: { depotCode: string, depotName: string }): void {
        this.save(STOR_KEY_CURRENT_DEPOT, [value], {
            maxItems: 1
        });
    }

    public static recentDamageType(input: string): void {
        const storedItems = FromStorage.recentDamageTypes();
        if (storedItems.filter(obj =>
                obj.damageType === input).length === 0) {
            storedItems.unshift({
                damageType: input
            });
        }
        this.save(STOR_KEY_RECENT_DAMAGETYPES, storedItems, {
            maxItems: 1000
        });
    }

    public static recentEquipmentNumber(input: string): void {
        input = Sanitize.equipmentNumber()(input);
        const storedItems = FromStorage.recentEquipmentNumbers();
        if (storedItems.filter(obj =>
                obj.equipmentNumber === input).length === 0) {
            storedItems.unshift({
                equipmentNumber: input
            });
        }
        this.save(STOR_KEY_RECENT_EQUIPMENTNUMBERS, storedItems, {
            maxItems: 1000
        });
    }

    public static recentEquipmentType(input: string): void {
        const storedItems = FromStorage.recentEquipmentTypes();
        if (storedItems.filter(obj => obj.equipmentType === input).length === 0) {
            storedItems.unshift({
                equipmentType: input
            });
        }
        this.save(STOR_KEY_RECENT_EQUIPMENTTYPES, storedItems, {
            maxItems: 1000
        });
    }

    public static recentMachineryManufacturer(input: string): void {
        const storedItems = FromStorage.recentMachineryManufacturers();
        if (storedItems.filter(obj => obj.machineryManufacturer === input).length === 0) {
            storedItems.unshift({
                machineryManufacturer: input
            });
        }
        this.save(STOR_KEY_RECENT_MACHINERY_MANUFACTURERS, storedItems, {
            maxItems: 1000
        });
    }

    public static recentMachineryModel(input: string): void {
        const storedItems = FromStorage.recentMachineryModels();
        if (storedItems.filter(obj => obj.machineryModel === input).length === 0) {
            storedItems.unshift({
                machineryModel: input
            });
        }
        this.save(STOR_KEY_RECENT_MACHINERY_MODELS, storedItems, {
            maxItems: 1000
        });
    }

    public static recentManufacturer(input: string): void {
        const storedItems = FromStorage.recentManufacturers();
        if (storedItems.filter(obj => obj.manufacturer === input).length === 0) {
            storedItems.unshift({
                manufacturer: input
            });
        }
        this.save(STOR_KEY_RECENT_MANUFACTURERS, storedItems, {
            maxItems: 1000
        });
    }

    public static recentTemplateNumber(input: string): void {
        const storedItems = FromStorage.recentTemplateNumbers();
        if (storedItems.filter(obj =>
                obj.templateNumber === input).length === 0) {
            storedItems.unshift({
                templateNumber: input
            });
        }
        this.save(STOR_KEY_RECENT_TEMPLATENUMBERS, storedItems, {
            maxItems: 1000
        });
    }

    private static save(key: string, array: any[], options: { maxItems?: number} = {}): void {
        if (options.maxItems !== undefined) {
            array = ArrayUtils.limitArray(array, options.maxItems);
        }
        localStorage.setItem(key,
            JSON.stringify(array));
    }
}
