import { Component, OnInit, Input } from '@angular/core';
import { AttachmentService } from '../services/jsdo-data/attachment.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-attachment-photo',
  templateUrl: './attachment-photo.component.html',
  styleUrls: ['./attachment-photo.component.scss']
})
export class AttachmentPhotoComponent implements OnInit {

  @Input() public attachmentNr: number;
  @Input() public seqnr: number;
  public imagePath;

  constructor(private attachmentService: AttachmentService,
              private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getAttachment();
  }

  getAttachment() {
    return new Promise((resolve, reject) => {
      this.attachmentService.getAttachment(this.attachmentNr, this.seqnr).then(data => {
        this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + data);
      });
    });
  }


}
