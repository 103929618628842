import { Component, OnInit } from '@angular/core';
import { ReeferMonitoringService } from 'src/app/services/jsdo-data/reefer-monitoring.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reefer-monitoring-details',
  templateUrl: './reefer-monitoring-details.component.html',
  styleUrls: ['./reefer-monitoring-details.component.scss']
})
export class ReeferMonitoringDetailsComponent implements OnInit {

  record;
  containerDetails;
  
  containerForm: FormGroup;

  constructor(private reeferMonitoringService: ReeferMonitoringService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public snackBar: MatSnackBar,
              public location: Location) { }

  ngOnInit() {
    this.containerForm = this.fb.group({
      stacklocation: new FormControl({value: '', disabled: true}),
      setpoint: new FormControl({value: '', disabled: true}),
      CA: new FormControl({value: '', disabled: true}),
      humidity: new FormControl({value: '', disabled: true}),
      ventilation: new FormControl({value: '', disabled: true}),
      vent_value: new FormControl({value: '', disabled: true}),
      vent_unit: new FormControl({value: '', disabled: true}),
      temperature: new FormControl('', [Validators.required]),
      ind_defrosting: new FormControl(''),
      remarks: new FormControl('')
    });

    this.route.paramMap.subscribe(params => {
      
      this.record = this.reeferMonitoringService.jsdoService.dataSource.findById(params.get('recid'));
      this.containerDetails = this.record.data;
      
      if(this.containerDetails.ind_CA === true) this.containerDetails.ind_CA = "Yes";
      if(this.containerDetails.ind_CA === false) this.containerDetails.ind_CA = "No";
            
      this.containerForm.controls['stacklocation'].setValue(this.record.data.stacklocation);
      this.containerForm.controls['setpoint'].setValue(this.record.data.temperature);
      this.containerForm.controls['CA'].setValue(this.record.data.CA);
      this.containerForm.controls['humidity'].setValue(this.record.data.humidity);
      this.containerForm.controls['ventilation'].setValue(this.record.data.humidity);
      this.containerForm.controls['vent_value'].setValue(this.record.data.boxref4);
      this.containerForm.controls['vent_unit'].setValue(this.record.data.boxref5);
    });
  }

  onSave() {
    const updatedValues = {
      temperature : this.containerForm.value.temperature,
      remarks: this.containerForm.value.remarks
    };
    console.log(updatedValues);
    this.record.assign(updatedValues);
    this.reeferMonitoringService.jsdoService.dataSource.saveChanges(true).done((jsdo, success, request) => {
      if (success) {
        this.snackBar.open('Data saved succesfully', '', {duration: 1500});
        this.location.back();
      }
    }).fail((jsdo, success, request) => {
      alert('save failed!');
    });
  }

}
