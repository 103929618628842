import { Injectable } from '@angular/core';
import { VbsService } from './services/jsdo-data/vbs.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class VBSBookingResolver implements Resolve<any> {
   
    constructor(public vbsService: VbsService) { }
   
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.vbsService.getRecordByVbsBookingNr(route.paramMap.get('vbsbooking'));
   }
   
}