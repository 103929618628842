import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';
import { CordovaService } from '../cordova.service';
import { SettingsService } from '../settings.service';

@Injectable({
  providedIn: 'root'
})
export class DevicePushService {

  private dataSource: any;
  private dataSourceReady: Promise<any>;
  public pushToken;

  constructor(private jsdoSessionService: JsdoSessionService,
              private cordova: CordovaService,
              private settingsService: SettingsService,
              private router: Router) {
      this.dataSourceReady = new Promise((resolve, reject) => {

          this.jsdoSessionService.sessionReady.then(() => {
            this.dataSource = new progress.data.JSDO('dp_device_push');
            resolve();
          });
      });
   }

   registerDeviceForPush() {
    if (this.cordova.onCordova) {
        if (true) {
            this.cordova.FirebasePlugin.hasPermission(permission => {
                console.log('Firebase permission: ' + permission);
                if (!permission) {
                    console.log('Firebase: asking permission');
                    this.cordova.FirebasePlugin.grantPermission((permissionGranted) => {
                        console.log('Firebase: Permission granted', permissionGranted);
                      }, (error) => {
                        console.error('Firebase: unable to grant permission or permission declined', error);
                      });
                }
            });

            this.cordova.FirebasePlugin.getToken( token => {
                // save this server-side and use it to push notifications to this device
                this.pushToken = token;
            }, error => {
                alert(error);
            });

            this.cordova.FirebasePlugin.onMessageReceived(notification => {
                console.log(JSON.stringify(notification));

                if (notification.tap) {
                    // alert(JSON.stringify(notification));
                    if (notification.container) {
                        this.router.navigate(['containerinfo', notification.container]);
                    }
                } else {
                    if (notification.aps) { // IOS
                        this.cordova.notification.alert(notification.aps.alert.body, () => {}, 'Notification');
                    } else { // Android
                        this.cordova.notification.alert(notification.body, () => {}, 'Notification');
                    }
                }

            }, function(error) {
                console.error(error);
            }); 

            this.cordova.FirebasePlugin.subscribe('terminal');
        }
    } else {
      this.pushToken = 'TestingFromTheBrowser';
    }
  }

    registerContainerPush(containerSeqnr) {
        // Update local push parameters
        let pushParameters;
        const parString = this.settingsService.getCompanySetting('pushParameters');
        if (parString) {
            let found = false;
            pushParameters = JSON.parse(parString);
            if (pushParameters.containerSeqnrs) {
                pushParameters.containerSeqnrs.forEach(function (item, index) {
                    if (item === containerSeqnr) {
                        found = true;
                    }
                });
                if (!found) {
                    pushParameters.containerSeqnrs.push(containerSeqnr);
                    // Array niet oneindig lang laten worden
                    while (pushParameters.containerSeqnrs > 250) {
                        pushParameters.containerSeqnrs.shift();
                    }
                }
            } else {
                pushParameters.containerSeqnr = [containerSeqnr];
            }
        } else {
            pushParameters = {
                containerSeqnrs: [containerSeqnr]
            };
        }
        this.settingsService.setCompanySetting('pushParameters', JSON.stringify(pushParameters));

        // Register on backend
        return this.registerOnBackend(containerSeqnr);
    }

    registerOnBackend(containerSeqnr) {
        // Register on backend
        return new Promise((resolve, reject) => {
            this.dataSource.invoke('addContainerSubscription', {pcDeviceToken: this.pushToken, piSeqnr: containerSeqnr})
            .done(function (jsdo, success, request) {
                console.log('success!');
                resolve(request.response);
            }).fail(function(error) {
                console.log('failure!');
                reject(error);
            });
        });
    }

    testPushNotification() {
        return this.registerOnBackend(99999999);
    }

    isContainerPushRegistered(containerSeqnr) {
        let found = false;
        const parString = this.settingsService.getCompanySetting('pushParameters');
        if (parString) {
            const pushParameters = JSON.parse(parString);
            if (pushParameters.containerSeqnrs) {
                pushParameters.containerSeqnrs.forEach(function (item, index) {
                    if (item === containerSeqnr) {
                        found = true;
                    }
                });
            }
        }
        return found;
    }

    subscribeTopic(topic) {
        this.cordova.FirebasePlugin.subscribe(topic);
    }

    unSubscribeTopic(topic) {
        this.cordova.FirebasePlugin.unsubscribe(topic);
    }

}