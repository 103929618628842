import * as bookingStatsActions from './store/booking-stats/booking-stats.actions';
import * as fromBookingStats from './store/booking-stats/booking-stats.reducer';
import * as fromRoot from './store/index';
import * as toaster from 'softpak-frontend-toaster';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
  } from '@angular/router';
import {
  filter,
  map,
  switchMap,
  take
  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { throwError } from 'rxjs';

@Injectable()
export class BookingStatsResolver implements Resolve<any> {
  store: any;
  constructor(
        private readonly appStore: Store<fromRoot.AppState>,
        private readonly router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    this.appStore.dispatch(toaster.CLEAR_ALL({}));
    this.appStore.dispatch(bookingStatsActions.RESET({}));
    this.appStore.dispatch(bookingStatsActions.LOAD_BOOKING_STATS({}));

    const t = this;

    return new Promise(function(resolve, reject) {
      t.appStore.select(fromBookingStats.selectAll).pipe(
        map((bookingStatsState: fromBookingStats.BookingStatsState) => {
          if (bookingStatsState.bookingStatsError) {
            t.router.navigate(['/']);
            t.appStore.dispatch(toaster.CREATE_ERROR({
              messageText: bookingStatsState.bookingStatsError.errorMessage
            }));
            throwError(bookingStatsState.bookingStatsError.errorMessage);
          }
          return bookingStatsState;
        }),
        filter(bookingStatsState => !bookingStatsState.bookingStatsLoading),
        take(1),
        switchMap(() =>
          t.appStore.pipe(
            select(fromBookingStats.selectBookingStatsData),
            take(1)
            ).toPromise()
          )
      ).subscribe(data => {
        if (data === null) {
          reject('Booking stats page not loaded.');
        } else {
          resolve(data as any);
        }
      });
    }).catch(error => { console.log(error); });

  }
}
