import { createAction, props, union } from '@ngrx/store';
import { EquipmentStatsFiltersInterface } from 'src/app/equipment-stats/models/equipment-stats-filters.interface';
// tslint:disable: max-line-length

export const ERROR = createAction('[Equipment Stats] Error', props<{ errorMessage: string, errorType: string }>());
export const LOAD_EQUIPMENT_STATS = createAction('[Equipment Stats] Load Equipment Stats', props<{ }>());
export const RECEIVED_EQUIPMENT_STATS = createAction('[Equipment Stats] Received Equipment Stats', props<{ equipmentStatsData: any[] }>());
export const RESET = createAction('[Equipment Stats] Reset', props<{}>());
export const UPDATE_FILTERING = createAction('[Equipment Stats] Update Filtering', props<{
    filters: EquipmentStatsFiltersInterface
}>());

const all = union({
    ERROR,
    LOAD_EQUIPMENT_STATS,
    RECEIVED_EQUIPMENT_STATS,
    RESET,
    UPDATE_FILTERING
});
export type EquipmentStatsActions = typeof all;
