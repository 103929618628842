import { ChartColorInterface } from 'src/app/softpak/charts/models/chart-color.interface';

export class BookingStatsBar {
    active = false;
    color: ChartColorInterface;
    countDropOffEmpty = false;
    countDropOffFull = false;
    countPickUpEmpty = false;
    countPickUpFull = false;
    isTotal = false;
    secondName: string;
    showTotalInGraph = false;
    constructor(options: {
      active: boolean;
      color?: ChartColorInterface;
      countDropOffEmpty?: boolean;
      countDropOffFull?: boolean;
      countPickUpEmpty?: boolean;
      countPickUpFull?: boolean;
      isTotal?: boolean;
      secondName: string;
      showTotalInGraph?: boolean;
    }) {
     this.active = options.active;
     this.color = options.color ? options.color : undefined;
     this.countDropOffEmpty = options.countDropOffEmpty ? options.countDropOffEmpty : false;
     this.countDropOffFull = options.countDropOffFull ? options.countDropOffFull : false;
     this.countPickUpEmpty = options.countPickUpEmpty ? options.countPickUpEmpty : false;
     this.countPickUpFull = options.countPickUpFull ? options.countPickUpFull : false;
     this.isTotal = options.isTotal ? options.isTotal : false;
     this.secondName = options.secondName;
     this.showTotalInGraph = options.showTotalInGraph ? options.showTotalInGraph : false;
    }
    get isDropOff() {
      return this.countDropOffEmpty || this.countDropOffFull;
    }
    get isPickUp() {
      return this.countPickUpEmpty || this.countPickUpFull;
    }
    get isEmpty() {
      return this.countDropOffEmpty || this.countPickUpEmpty;
    }
    get isFull() {
      return this.countDropOffFull || this.countPickUpFull;
    }
}
