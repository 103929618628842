import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AttachmentService } from '../services/jsdo-data/attachment.service';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit, OnChanges {

  @Input() public attachmentNr: number;
  @Input() public thumbnail: boolean;
  public data: Array<any>;
  public imagePath;

  constructor(private attachmentService: AttachmentService) { }

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.attachmentService.getDataByAttachmentNr(this.attachmentNr).then((data: Array<any>) => {
      this.data = data;
    });
  }
}

