import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import * as equipmentStatsDetailActions from '../../../store/equipment-stats-detail/equipment-stats-detail.actions';
import * as fromEquipmentStatsDetail from '../../../store/equipment-stats-detail/equipment-stats-detail.reducer';
import { NavigationService } from 'src/app/services/navigation.service';
import { unsubscribeSubscriptions } from 'src/app/softpak/functions/unsubscribe-subscriptions';

@Component({
  selector: 'app-equipment-stats-detail',
  templateUrl: './equipment-stats-detail.component.html',
  styleUrls: ['./equipment-stats-detail.component.scss']
})
export class EquipmentStatsDetailComponent implements OnDestroy, OnInit {
  public loading: boolean;
  public receiveTime: string;
  public showFilters = false;
  private readonly subscriptions: {
    selectAll?: Subscription;
  } = {};
  public availableDepots: { depotCode: string; depotName: string; }[] = [];

  constructor(private readonly appStore: Store<fromRoot.AppState>,
    private navigationService: NavigationService) {
      this.navigationService.setLabel('Equipment Stats Detail');
      this.navigationService.enableBackButton(true);
  }

  public ngOnInit(): void {
    this.subscribeToStats();
  }

  public ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

  public onClickRefresh(): void {
    this.appStore.dispatch(equipmentStatsDetailActions.LOAD_EQUIPMENT_STATS({}));
  }

  public onToggleFilters(showFilters: boolean): void {
    this.showFilters = showFilters;
  }

  private subscribeToStats(): void {
    this.subscriptions.selectAll = this.appStore.pipe(select(fromEquipmentStatsDetail.selectAll))
      .subscribe((all) => {
        this.loading = all.loading;
        this.receiveTime = all.receiveTime;
        this.availableDepots = this.getDepots(all.data);
      });
  }

  private getDepots(depots: any[]): { depotCode: string; depotName: string; }[] {
    return depots.map(depot => {
      return { depotCode: depot[0][0].unconvertedValues[0].depotCode, depotName: depot[0][0].unconvertedValues[0].depotCode };
    });
  }
}
