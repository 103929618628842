import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { SessionService } from '../../services/session.service';
import { SettingsService } from '../../services/settings.service';
import { CordovaService } from '../../services/cordova.service';


@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  fileTransfer;
  _status: string;
  statusHistory: Array<string> = [];
  store: string;
  appDir: string;
  baseUrl = 'https://softpak.info/download/app_update/proterm2/';
  manifestUrl: string;
  devUrl: string;
  finished = false;
  forceUpdate;
  assetEntry;
  loadingStatus: String;

  get status() {
    return this._status;
  }

  set status(newStatus) {
    if (this._status) {
      this.statusHistory.push(this._status);
    }
    this._status = newStatus;
  }

  constructor(private http: HttpClient,
    private sessionService: SessionService,
    private location: Location,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private cordovaService: CordovaService
    ) {
  }

  ngOnInit() {

    if (this.cordovaService.cordova.platformId === 'android') {
      this.assetEntry = 'androidfile';
    } else {
      this.assetEntry = 'iosfile';
    }

    // Other update server configured for development?
    this.devUrl = SettingsService.getSetting('updateServer');
    if (this.devUrl) {
      this.baseUrl = this.devUrl + '/';
    }

    this.forceUpdate = this.route.snapshot.paramMap.get('force');

    // The directory to store data
    this.fileTransfer = new window['FileTransfer']();

    this.appDir = window['cordova'].file.applicationDirectory;
    this.store = window['cordova'].file.dataDirectory;

    this.settingsService.brandCode.then(brandcode => {

      if (brandcode !== 'SOFTPAK') {
        this.baseUrl = this.baseUrl + brandcode + '/';
      }

      this.manifestUrl = this.baseUrl + 'manifest.json';

      // If there is a newer version on the system, then load this version first.
      const installedUpdate = (parseInt(SettingsService.getSetting('installedUpdate'), 10) || 0);
      // Only when on the initial install dir to prevent endless loop
      if (installedUpdate > this.sessionService.build && window.location.href.startsWith(this.appDir)) {
        this.runUpdate();
      } else {
        this.getUpdate();
      }
    });
  }

  getUpdate() {

    this.status = 'Checking for update';
    if (this.devUrl) { this.status = 'Using development server: ' + this.devUrl; }

    // Read manifest to get version and filename
    this.getManifest()
      .subscribe((manifest) => {
        if (manifest['version']) {

          const version = manifest['version'];

          this.status = 'Current Version: ' + this.sessionService.build + ' last Version: ' + version;
          if (version > this.sessionService.build || this.forceUpdate) {
            const fileName = 'update.zip';

            const assetURL = this.baseUrl + manifest[this.assetEntry];

            this.status = 'Downloading version:' + version;

            this.fileTransfer.onprogress = progressEvent => {
              if (progressEvent.lengthComputable) {
                  this.loadingStatus = Math.floor(progressEvent.loaded / progressEvent.total * 100) + ' %';
              } else {
                  this.loadingStatus = progressEvent.loaded;
              }
            };
            this.fileTransfer.download(assetURL, this.store + fileName,
              entry => {
                this.status = 'Download completed';
                this.loadingStatus = '';

                this.deleteCurrentUpdateDir().then(() => {
                  this.unzipUpdate(this.store + fileName, version);
                });

              },
              err => {
                alert(JSON.stringify(err));
                this.done();

              }
            );
          } else {
            this.done();
          }

        } else {
          this.done();
        }
    });
  }

  unzipUpdate(file, version) {
    this.status = 'Installing version ' + version;
    const zip = window['zip'];

    zip.unzip(file, this.store + 'update', result => {
      if (result === 0) {
        SettingsService.setSetting('installedUpdate', version);
        this.runUpdate();
      }
    });
  }

  deleteCurrentUpdateDir() {
    return new Promise((resolve, reject) => {
      window['resolveLocalFileSystemURL'](this.store + 'update/www', storeDir => {
        storeDir.removeRecursively(() => {
          resolve();
        }, error => {
           resolve();
        });
      }, () => {
        resolve();
      });
    });
  }

  getManifest() {
    // Prevent cached result;
    const timeStamp = +new Date();
    const uniqueUrl = this.manifestUrl + '?tsp=' + timeStamp;

    return this.http.get(uniqueUrl)
      .pipe(
        timeout(2000),
        catchError(this.handleHttpError())
      );
  }

  private handleHttpError () {
    return (error: any): Observable<any> => {

      this.status = 'Error while checking for patch';
      console.error(error); // log to console instead
      // alert(JSON.stringify(error));

      // Let the app keep running by returning an empty result.
     return of([]);
    };
  }

  done() {
    if (this.devUrl) {
      this.finished = true;
    } else {
      this.onConfirmFinished();
    }
  }

  onConfirmFinished() {
    this.location.back();
  }

  runUpdate() {
    this.cordovaService.splashscreen.show();
    window.location.assign(this.store + 'update' + '/www/index.html');
  }

}
