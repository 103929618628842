import { Injectable } from '@angular/core';

import { progress } from '../../progress/progress.jsdo';
import { JsdoSessionService } from '../jsdo-session.service';


@Injectable({
    providedIn: 'root'
})
export class BookingService {

    private dataSource: any;
    private dataSourceReady;

    constructor(private jsdoSessionService: JsdoSessionService) {
        this.dataSourceReady = new Promise((resolve, reject) => {
            this.jsdoSessionService.sessionReady.then(() => {
                this.dataSource = new progress.data.JSDO({
                    name: 'DP.DeliveryInfo'
                });
                resolve();
            });
        });
    }

    getBooking(bookingNumber) {
        const t = this;
        const filter = {
            logic: 'and',
            filters: [
                {
                    field: 'deliverynumber',
                    operator: 'eq',
                    value: bookingNumber
                },
            ]
        };

        return new Promise((resolve, reject) => {
            t.dataSourceReady.then(() => {
                bookingNumber = bookingNumber.replace(/\s/g, '');

                t.dataSource.fill(JSON.stringify(filter)).done((jsdo, success, request) => {
                    if ((t.dataSource.getData() as any[]).length === 0) {
                        reject({
                            errorType: 'BOOKING_NOT_FOUND',
                            message: `Booking '${bookingNumber.toUpperCase()}' was not found.`
                        });
                    }
                    // tslint:disable-next-line: no-unsafe-any
                    resolve(t.dataSource.getData()
                        .map((item: any): any => {
                            return item;
                        })
                    );
                });
            });
        });

    }
}

