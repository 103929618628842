import * as actions from './storage.actions';
import { AppState } from '..';
import { createReducer, createSelector, on } from '@ngrx/store';
import { FromStorage } from 'src/app/utils/from-storage';
import {
    StorRecentDamageTypeInterface,
    StorRecentEquipmentInterface,
    StorRecentEquipmentTypeInterface,
    StorRecentMachineryManufacturerInterface,
    StorRecentMachineryModelInterface,
    StorRecentManufacturerTypeInterface,
    StorRecentTemplateNumberInterface
} from '../../models/storage-interfaces';
import { ToStorage } from 'src/app/utils/to-storage';

const thisReducer = (state: AppState): StorageState => state.storage;

export interface StorageState {
    current_depot: {
        depotCode: string,
        depotName: string
    };
    recent_damage_types: StorRecentDamageTypeInterface[];
    recent_equipment_numbers: StorRecentEquipmentInterface[];
    recent_equipment_types: StorRecentEquipmentTypeInterface[];
    recent_machinery_manufacturers: StorRecentMachineryManufacturerInterface[];
    recent_machinery_models: StorRecentMachineryModelInterface[];
    recent_manufacturers: StorRecentManufacturerTypeInterface[];
    recent_template_numbers: StorRecentTemplateNumberInterface[];
}

export const initialState: StorageState = {
    current_depot: null,
    recent_damage_types: [],
    recent_equipment_numbers: [],
    recent_equipment_types: [],
    recent_machinery_manufacturers: [],
    recent_machinery_models: [],
    recent_manufacturers: [],
    recent_template_numbers: [],
};

const handleReducer = createReducer(
    initialState,
    on(actions.LOAD_CURRENT_DEPOT, (state: StorageState): StorageState => {
        return { ...state, current_depot: FromStorage.currentDepot() };
    }),
    on(actions.LOAD_RECENT_DAMAGE_TYPES, (state: StorageState): StorageState => {
        return { ...state, recent_damage_types: FromStorage.recentDamageTypes() };
    }),
    on(actions.LOAD_RECENT_EQUIPMENT_NUMBERS, (state: StorageState): StorageState => {
        return { ...state, recent_equipment_numbers: FromStorage.recentEquipmentNumbers() };
    }),
    on(actions.LOAD_RECENT_EQUIPMENT_TYPES, (state: StorageState): StorageState => {
        return { ...state, recent_equipment_types: FromStorage.recentEquipmentTypes() };
    }),
    on(actions.LOAD_RECENT_MACHINERY_MANUFACTURERS, (state: StorageState): StorageState => {
        return { ...state, recent_machinery_manufacturers: FromStorage.recentMachineryManufacturers() };
    }),
    on(actions.LOAD_RECENT_MACHINERY_MODELS, (state: StorageState): StorageState => {
        return { ...state, recent_machinery_models: FromStorage.recentMachineryModels() };
    }),
    on(actions.LOAD_RECENT_MANUFACTURERS, (state: StorageState): StorageState => {
        return { ...state, recent_manufacturers: FromStorage.recentManufacturers() };
    }),
    on(actions.LOAD_RECENT_TEMPLATE_NUMBERS, (state: StorageState): StorageState => {
        return { ...state, recent_template_numbers: FromStorage.recentTemplateNumbers() };
    }),
    on(actions.SAVE_CURRENT_DEPOT, (state: StorageState, { depotCode, depotName }): StorageState => {
        ToStorage.currentDepot({ depotCode, depotName });
        return { ...state, current_depot: { depotCode, depotName } };
    }),
    on(actions.SAVE_RECENT_DAMAGE_TYPE, (state: StorageState, { item }): StorageState => {
        ToStorage.recentDamageType(item);
        return { ...state, recent_damage_types: FromStorage.recentDamageTypes() };
    }),
    on(actions.SAVE_RECENT_EQUIPMENT_NUMBER, (state: StorageState, { item }): StorageState => {
        ToStorage.recentEquipmentNumber(item);
        return { ...state, recent_equipment_numbers: FromStorage.recentEquipmentNumbers() };
    }),
    on(actions.SAVE_RECENT_EQUIPMENT_TYPES, (state: StorageState, { item }): StorageState => {
        ToStorage.recentEquipmentType(item);
        return { ...state, recent_equipment_types: FromStorage.recentEquipmentTypes() };
    }),
    on(actions.SAVE_RECENT_MACHINERY_MANUFACTURER, (state: StorageState, { item }): StorageState => {
        ToStorage.recentMachineryManufacturer(item);
        return { ...state, recent_machinery_manufacturers: FromStorage.recentMachineryManufacturers() };
    }),
    on(actions.SAVE_RECENT_MACHINERY_MODEL, (state: StorageState, { item }): StorageState => {
        ToStorage.recentMachineryModel(item);
        return { ...state, recent_machinery_models: FromStorage.recentMachineryModels() };
    }),
    on(actions.SAVE_RECENT_MANUFACTURER, (state: StorageState, { item }): StorageState => {
        ToStorage.recentManufacturer(item);
        return { ...state, recent_manufacturers: FromStorage.recentManufacturers() };
    }),
    on(actions.SAVE_RECENT_TEMPLATE_NUMBER, (state: StorageState, { item }): StorageState => {
        ToStorage.recentTemplateNumber(item);
        return { ...state, recent_template_numbers: FromStorage.recentTemplateNumbers() };
    })
);

export function reducer(state: StorageState, action: actions.StorageActions): StorageState {
    return handleReducer(state, action);
}

export const selectCurrentDepot = createSelector(
    thisReducer, (state: StorageState) => state.current_depot
);

export const selectRecentDamageTypes = createSelector(
    thisReducer, (state: StorageState) => state.recent_damage_types
);

export const selectRecentEquipmentNumbers = createSelector(
    thisReducer, (state: StorageState) => state.recent_equipment_numbers
);

export const selectRecentEquipmentTypes = createSelector(
    thisReducer, (state: StorageState) => state.recent_equipment_types
);

export const selectRecentMachineryManufacturer = createSelector(
    thisReducer, (state: StorageState) => state.recent_machinery_manufacturers
);

export const selectRecentMachineryModel = createSelector(
    thisReducer, (state: StorageState) => state.recent_machinery_models
);

export const selectRecentManufacturers = createSelector(
    thisReducer, (state: StorageState) => state.recent_manufacturers
);

export const selectRecentTemplateNumbers = createSelector(
    thisReducer, (state: StorageState) => state.recent_template_numbers
);
