import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../services/jsdo-data/container.service';
import { ContainerInfoService} from '../services/jsdo-data/container-info.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  containers = null;

  constructor(private containerService: ContainerService,
              private containerInfoService: ContainerInfoService) { }

  ngOnInit() {
    // this.getContainers();
    this.testXref();
  }

  getContainers() {
    const filter = {'logic': 'and', 'filters': [
      {'field': 'recordtype', 'operator': 'eq', 'value': '30'}
    ]};

    this.containerService.getData(filter).then((records: Array<any>) => {
      this.containers = records;
    });
  }

  testXref() {
    this.containerInfoService.getCodeList().then((records: Array<any>) => {
    });
  }

}
