import * as actions from './suggestions.actions';
import { createReducer, createSelector, on } from '@ngrx/store';
import { SuggestionInterface } from 'src/app/models/general/suggestion.interface';
import { AppState } from '..';

const thisReducer = (state: AppState) => state.suggestions;

export interface SuggestionsState {
    assemblyCodes: SuggestionInterface[];
    billingTypes: SuggestionInterface[];
    bodyManufacturers: SuggestionInterface[];
    categories: SuggestionInterface[];
    components: SuggestionInterface[];
    componentLocations: SuggestionInterface[];
    currencies: SuggestionInterface[];
    customers: SuggestionInterface[];
    damageCodes: SuggestionInterface[];
    damageTypes: SuggestionInterface[];
    debtors: SuggestionInterface[];
    depots: SuggestionInterface[];
    dppTypes: SuggestionInterface[];
    estimateCodes: SuggestionInterface[];
    estimators: SuggestionInterface[];
    equipmentNumbers: SuggestionInterface[];
    equipmentTypes: SuggestionInterface[];
    lessees: SuggestionInterface[];
    machineryManufacturers: SuggestionInterface[];
    machineryModels: SuggestionInterface[];
    materials: SuggestionInterface[];
    repairDepots: SuggestionInterface[];
    repairMethods: SuggestionInterface[];
    repairSites: SuggestionInterface[];
    serviceTypes: SuggestionInterface[];
    stackCodes: SuggestionInterface[];
    stackLocations: SuggestionInterface[];
    templateNumbers: SuggestionInterface[];
    tonsTested: SuggestionInterface[];
    transportCompanies: SuggestionInterface[];
}

export const initialState: SuggestionsState = {
    assemblyCodes: [],
    billingTypes: [],
    bodyManufacturers: [],
    categories: [],
    components: [],
    componentLocations: [],
    currencies: [],
    customers: [],
    damageCodes: [],
    damageTypes: [],
    debtors: [],
    depots: [],
    dppTypes: [],
    estimateCodes: [],
    estimators: [],
    equipmentNumbers: [],
    equipmentTypes: [],
    lessees: [],
    machineryManufacturers: [],
    machineryModels: [],
    materials: [],
    repairDepots: [],
    repairMethods: [],
    repairSites: [],
    serviceTypes: [],
    stackCodes: [],
    stackLocations: [],
    templateNumbers: [],
    tonsTested: [],
    transportCompanies: [],
};

const handleReducer = createReducer(
    initialState,
    on(actions.CLEAR_ALL, (state: SuggestionsState, {}): SuggestionsState => {
        return {
            ...state,
            assemblyCodes: [],
            billingTypes: [],
            bodyManufacturers: [],
            categories: [],
            components: [],
            componentLocations: [],
            currencies: [],
            customers: [],
            damageCodes: [],
            damageTypes: [],
            debtors: [],
            depots: [],
            dppTypes: [],
            estimateCodes: [],
            estimators: [],
            equipmentNumbers: [],
            equipmentTypes: [],
            lessees: [],
            machineryManufacturers: [],
            machineryModels: [],
            materials: [],
            repairDepots: [],
            repairMethods: [],
            repairSites: [],
            serviceTypes: [],
            stackCodes: [],
            stackLocations: [],
            templateNumbers: [],
            tonsTested: [],
            transportCompanies: []
        };
    }),
    on(actions.LOADED_ASSEMBLY_CODES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, assemblyCodes: items };
    }),
    on(actions.LOADED_BILLING_TYPES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, billingTypes: items };
    }),
    on(actions.LOADED_BODY_MANUFACTURERS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, bodyManufacturers: items };
    }),
    on(actions.LOADED_CATEGORIES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, categories: items };
    }),
    on(actions.LOADED_COMPONENTS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, components: items };
    }),
    on(actions.LOADED_COMPONENT_LOCATIONS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, componentLocations: items };
    }),
    on(actions.LOADED_CURRENCIES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, currencies: items };
    }),
    on(actions.LOADED_CUSTOMERS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, customers: items };
    }),
    on(actions.LOADED_DAMAGE_CODES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, damageCodes: items };
    }),
    on(actions.LOADED_DAMAGE_TYPES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, damageTypes: items };
    }),
    on(actions.LOADED_DEBTORS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, debtors: items };
    }),
    on(actions.LOADED_DEPOTS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, depots: items };
    }),
    on(actions.LOADED_DPP_TYPES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, dppTypes: items };
    }),
    on(actions.LOADED_ESTIMATE_CODES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, estimateCodes: items };
    }),
    on(actions.LOADED_ESTIMATORS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, estimators: items };
    }),
    on(actions.LOADED_EQUIPMENT_NUMBERS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, equipmentNumbers: items };
    }),
    on(actions.LOADED_EQUIPMENT_TYPES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, equipmentTypes: items };
    }),
    on(actions.LOADED_LESSEES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, lessees: items };
    }),
    on(actions.LOADED_MACHINERY_MANUFACTURERS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, machineryManufacturers: items };
    }),
    on(actions.LOADED_MACHINERY_MODELS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, machineryModels: items };
    }),
    on(actions.LOADED_MATERIALS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, materials: items };
    }),
    on(actions.LOADED_REPAIR_DEPOTS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, repairDepots: items };
    }),
    on(actions.LOADED_REPAIR_METHODS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, repairMethods: items };
    }),
    on(actions.LOADED_REPAIR_SITES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, repairSites: items };
    }),
    on(actions.LOADED_SERVICE_TYPES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, serviceTypes: items };
    }),
    on(actions.LOADED_STACK_CODES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, stackCodes: items };
    }),
    on(actions.LOADED_STACK_LOCATIONS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, stackLocations: items };
    }),
    on(actions.LOADED_TEMPLATE_NUMBERS, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, templateNumbers: items };
    }),
    on(actions.LOADED_TONS_TESTED, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, tonsTested: items };
    }),
    on(actions.LOADED_TRANSPORT_COMPANIES, (state: SuggestionsState, { items }): SuggestionsState => {
        return { ...state, transportCompanies: items };
    }),
);

export function reducer(state: SuggestionsState, action: actions.SuggestionActions): SuggestionsState {
    return handleReducer(state, action);
}

export const selectAssemblyCodes = createSelector(
    thisReducer, (state: SuggestionsState) => state.assemblyCodes
);

export const selectBillingTypes = createSelector(
    thisReducer, (state: SuggestionsState) => state.billingTypes
);

export const selectBodyManufacturers = createSelector(
    thisReducer, (state: SuggestionsState) => state.bodyManufacturers
);

export const selectCategory = createSelector(
    thisReducer, (state: SuggestionsState) => state.categories
);

export const selectComponents = createSelector(
    thisReducer, (state: SuggestionsState) => state.components
);

export const selectComponentLocations = createSelector(
    thisReducer, (state: SuggestionsState) => state.componentLocations
);

export const selectCurrencies = createSelector(
    thisReducer, (state: SuggestionsState) => state.currencies
);

export const selectCustomers = createSelector(
    thisReducer, (state: SuggestionsState) => state.customers
);

export const selectDamageCodes = createSelector(
    thisReducer, (state: SuggestionsState) => state.damageCodes
);

export const selectDamageTypes = createSelector(
    thisReducer, (state: SuggestionsState) => state.damageTypes
);

export const selectDebtors = createSelector(
    thisReducer, (state: SuggestionsState) => state.debtors
);

export const selectDepots = createSelector(
    thisReducer, (state: SuggestionsState) => state.depots
);

export const selectDPPTypes = createSelector(
    thisReducer, (state: SuggestionsState) => state.dppTypes
);

export const selectEstimateCodes = createSelector(
    thisReducer, (state: SuggestionsState) => state.estimateCodes
);

export const selectEstimators = createSelector(
    thisReducer, (state: SuggestionsState) => state.estimators
);

export const selectEquipmentNumbers = createSelector(
    thisReducer, (state: SuggestionsState) => state.equipmentNumbers
);

export const selectEquipmentTypes = createSelector(
    thisReducer, (state: SuggestionsState) => state.equipmentTypes
);

export const selectLessees = createSelector(
    thisReducer, (state: SuggestionsState) => state.lessees
);

export const selectMachineryManufacturers = createSelector(
    thisReducer, (state: SuggestionsState) => state.machineryManufacturers
);

export const selectMachineryModels = createSelector(
    thisReducer, (state: SuggestionsState) => state.machineryModels
);

export const selectMaterials = createSelector(
    thisReducer, (state: SuggestionsState) => state.materials
);

export const selectRepairDepots = createSelector(
    thisReducer, (state: SuggestionsState) => state.repairDepots
);

export const selectRepairMethods = createSelector(
    thisReducer, (state: SuggestionsState) => state.repairMethods
);

export const selectrepairSites = createSelector(
    thisReducer, (state: SuggestionsState) => state.repairSites
);

export const selectServiceTypes = createSelector(
    thisReducer, (state: SuggestionsState) => state.serviceTypes
);

export const selectStackCodes = createSelector(
    thisReducer, (state: SuggestionsState) => state.stackCodes
);

export const selectStackLocations = createSelector(
    thisReducer, (state: SuggestionsState) => state.stackLocations
);

export const selectTemplateNumbers = createSelector(
    thisReducer, (state: SuggestionsState) => state.templateNumbers
);

export const selectTonsTested = createSelector(
    thisReducer, (state: SuggestionsState) => state.tonsTested
);

export const selectTransportCompanies = createSelector(
    thisReducer, (state: SuggestionsState) => state.transportCompanies
);
