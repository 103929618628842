import { Injectable } from '@angular/core';
import { ChartColorInterface } from 'src/app/softpak/charts/models/chart-color.interface';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  public static Colors = {
    DEFAULT: [
      // 1. Lightest orange
      {
        dark: '#fccf55',
        light: '#f4e1b5',
        lightAlternative: '#ead7b0',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 3. Orange
      {
        dark: '#f47d52',
        light: '#f4cbc1',
        lightAlternative: '#e5beb7',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 6. Lightest green
      {
        dark: '#c2d366',
        light: '#dbddc5',
        lightAlternative: '#d0d2be',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 8. Green
      {
        dark: '#58bc6e',
        light: '#bfd9c3',
        lightAlternative: '#b6cbb8',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 11. Mint green
      {
        dark: '#57c3ba',
        light: '#c7e2de',
        lightAlternative: '#b9d1cd',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 13. Blue
      {
        dark: '#6699d1',
        light: '#aec2d2',
        lightAlternative: '#a5b8c4',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 16. Light Pink
      {
        dark: '#e988b8',
        light: '#e6d0e2',
        lightAlternative: '#d2c2cd',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 18. Pink/Red
      {
        dark: '#cd5670',
        light: '#e2aabf',
        lightAlternative: '#d3a1b6',
        textColor: 'rgba(30, 30, 30, .9)',
      },


      // 2. Light orange
      {
        dark: '#fbb254',
        light: '#f8ddbd',
        lightAlternative: '#ead7b0',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 5. Red
      {
        dark: '#d9434e',
        light: '#eabbc0',
        lightAlternative: '#dbafb5',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 7. Light green
      {
        dark: '#9bcd68',
        light: '#d1dbc3',
        lightAlternative: '#c4ccb7',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 10. Darkest green
      {
        dark: '#2a7038',
        light: '#9bbca0',
        lightAlternative: '#90ac92',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 12. Light blue
      {
        dark: '#3cb1d8',
        light: '#b8d5dd',
        lightAlternative: '#b0cad1',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 15. Light Purple
      {
        dark: '#bc96c5',
        light: '#d2bfd6',
        lightAlternative: '#c2b3c6',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 17. Pink
      {
        dark: '#d870ac',
        light: '#e2bbd6',
        lightAlternative: '#c4b7c1',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 20. Light brown
      {
        dark: '#d2633c',
        light: '#d1a790',
        lightAlternative: '#c09a86',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 4. Light Red
      {
        dark: '#ed6056',
        light: '#f1bbba',
        lightAlternative: '#e2b0b0',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 9. Dark green
      {
        dark: '#37a04e',
        light: '#a5c4ab',
        lightAlternative: '#97b29c',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 14. Purple
      {
        dark: '#9697ca',
        light: '#c1c2e2',
        lightAlternative: '#b7b9d3',
        textColor: 'rgba(30, 30, 30, .9)',
      },
      // 19. Red/brown
      {
        dark: '#b24528',
        light: '#bf9488',
        lightAlternative: '#b18a7f',
        textColor: 'rgba(30, 30, 30, .9)',
      },
    ],
    // Other colors
    DROP_OFF: {
      dark: '#82d2e6',
      light: '#d1ecea',
      lightAlternative: '#b4e0e0',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    DROP_OFF_EMPTY: {
      dark: '#aadee7',
      light: '#e0f1ef',
      lightAlternative: '#d4e7e8',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    DROP_OFF_FULL: {
      dark: '#82d2e6',
      light: '#d1ecea',
      lightAlternative: '#b4e0e0',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    PICK_UP: {
      dark: '#81cdbf',
      light: '#c1e5e0',
      lightAlternative: '#b0ded7',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    PICK_UP_EMPTY: {
      dark: '#b8e1d8',
      light: '#e1f2ef',
      lightAlternative: '#c9e8e2',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    PICK_UP_FULL: {
      dark: '#81cdbf',
      light: '#c1e5e0',
      lightAlternative: '#b0ded7',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    EMPTY: {
      dark: '#c6cad3',
      light: '#eaedf3',
      lightAlternative: '#dfe1e5',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    FULL: {
      dark: '#a5adb8',
      light: '#ced5dd',
      lightAlternative: '#c5cbd1',
      textColor: 'rgba(30, 30, 30, .9)',
    },
    TOTAL: {
      dark: '#82878e',
      light: '#c2c7ce',
      lightAlternative: '#b6babf',
      textColor: 'rgba(30, 30, 30, .9)',
    }
  };

  constructor() { }

  public static determineScaleMaximum(totals: number[]): number {
    const highest = totals ? Math.max.apply(Math, totals) : 2;
    return highest > 2 ? highest : 2;
  }

  public static getColorByIndex(index: number): ChartColorInterface {
    return this.Colors.DEFAULT[index % 15];
  }

  public static determineStepSize(range: number, highestValue: number, desiredNumberOfSteps: number): number {
    return this.calculateScaleStepSize(range, desiredNumberOfSteps, highestValue).stepSize;
  }

  public static determineNumberOfSteps(range: number, highestValue: number, desiredNumberOfSteps: number): number {
    return this.calculateScaleStepSize(range, desiredNumberOfSteps, highestValue).numberOfSteps;
  }

  public static generateStepLines(numberOfSteps: number): number[] {
    if (numberOfSteps > 0) {
      return Array(numberOfSteps).fill(null).map((x, i) => i);
    } else {
      return [0, 1];
    }
  }

  private static calculateScaleStepSize(range: number, targetSteps: number = 4, highestValue: number
    ): { numberOfSteps: number, stepSize: number } {
    const tempStep = range / targetSteps;
    const mag = Math.floor(Math.log10(tempStep));
    const magPow = Math.pow(10, mag);
    let magMsd = parseInt((tempStep / magPow + 0.5).toString(), 10);

    if (magMsd > 5) {
      magMsd = 10;
    } else if (magMsd > 2) {
      magMsd = 5;
    } else if (magMsd > 1) {
      magMsd = 2;
    }
    const stepSize = magMsd * magPow;
    const numberOfSteps = Math.ceil(highestValue / stepSize);
    return { stepSize, numberOfSteps };
  }
}
