import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  backButton: Boolean;
  hide: Boolean = false;

  constructor(public navigationService: NavigationService,
              private location: Location) {

    this.navigationService.backButton.subscribe(enableState => {
      this.backButton = enableState;
    });
    this.navigationService.hideNavigation.subscribe(hide => {
      this.hide = hide;
    });
  }

  ngOnInit() {}

  navBack() {
    this.location.back();
  }
}
