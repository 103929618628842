import { createAction, props, union } from '@ngrx/store';
// tslint:disable: max-line-length

export const ERROR = createAction('[Container Lookup] Error', props<{ errorMessage: string, errorType: string }>());
export const LOAD_CONTAINER_DATA = createAction('[Container Lookup] Load Container Data', props<{ containerNumber: string }>());
export const RECEIVED_CONTAINER_DATA = createAction('[ContainerData] Received Container Data', props<{ containerData: unknown }>());
export const RESET = createAction('[Container Lookup] Reset', props<{}>());

const all = union({
    ERROR,
    LOAD_CONTAINER_DATA,
    RECEIVED_CONTAINER_DATA,
    RESET,
});
export type ContainerLookupActions = typeof all;
