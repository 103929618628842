import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { NavigationService } from 'src/app/services/navigation.service';
import { ReeferMonitoringService } from 'src/app/services/jsdo-data/reefer-monitoring.service';


@Component({
  selector: 'app-reefer-monitoring-select',
  templateUrl: './reefer-monitoring-select.component.html',
  styleUrls: ['./reefer-monitoring-select.component.scss']
})
export class ReeferMonitoringSelectComponent implements OnInit {

  stack: any;
  data: any;
  loadingState = 'INIT';

  displayedColumns = ['container', 'stacklocation'];
  dataSource = new MatTableDataSource();

  constructor(private navigationService: NavigationService,
              private reeferMonitoringService: ReeferMonitoringService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.navigationService.enableBackButton(true);
    this.route.paramMap.subscribe(params => {
      this.loadingState = 'LOADING';
      this.stack = params.get('stack');
      this.reeferMonitoringService.getDataByStack(this.stack).then(data => {
        this.loadingState = 'READY';
        this.data = data;
        this.dataSource.data = this.data;
      });
    });
  }

}
