import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as equipmentStatsActions from '../../../store/equipment-stats/equipment-stats.actions';
import * as fromRoot from '../../../store/index';
import * as fromEquipmentStats from '../../../store/equipment-stats/equipment-stats.reducer';
import { unsubscribeSubscriptions } from 'src/app/softpak/functions/unsubscribe-subscriptions';

@Component({
  selector: 'app-equipment-stats-home',
  templateUrl: './equipment-stats-home.component.html',
  styleUrls: ['./equipment-stats-home.component.scss']
})
export class EquipmentStatsHomeComponent implements OnDestroy, OnInit {
  public loading: boolean;
  public receiveTime: string;
  public showFilters = false;
  private readonly subscriptions: {
    selectAll?: Subscription;
  } = {};
  public availableDepots: { depotCode: string; depotName: string; }[] = [];

  constructor(private readonly appStore: Store<fromRoot.AppState>,
              private navigationService: NavigationService) {
    this.navigationService.setLabel('Equipment Stats');
    this.navigationService.enableBackButton(true);
  }

  public ngOnInit(): void {
    this.subscribeToStats();
  }

  public ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

  public onClickRefresh(): void {
    this.appStore.dispatch(equipmentStatsActions.LOAD_EQUIPMENT_STATS({}));
  }

  public onToggleFilters(showFilters: boolean): void {
    this.showFilters = showFilters;
  }

  private subscribeToStats(): void {
    this.subscriptions.selectAll = this.appStore.pipe(select(fromEquipmentStats.selectAll))
      .subscribe((all) => {
        this.loading = all.loading;
        this.receiveTime = all.receiveTime;
        this.availableDepots = this.fillDepots(all.data);
      });
  }

  private fillDepots(equipmentGroups: any[]): { depotCode: string; depotName: string; }[] {
    const depots = [];
    if (equipmentGroups && equipmentGroups.length) {
      equipmentGroups.forEach(equipmentGroup => {
        equipmentGroup.equipments.forEach(equipment => {
          if (depots.filter(item => item.depotCode === equipment.depotCode).length === 0) {
            depots.push({
              depotCode: equipment.depotCode,
              depotName: equipment.depotCode
            });
          }
        });
      });
      return depots;
    }
  }
}
