import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { progress } from '../progress/progress.jsdo';
import { Observable, BehaviorSubject, from} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SettingsService } from './settings.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { ConnectionService } from './connection.service';
import { HttpClient, HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class JsdoSessionService {

  public userSource = new BehaviorSubject<any>('');
  public user$ = this.userSource.asObservable();

  public jsdoSession: any;
  public loggedIn;
  public sessionCreated;

  private _sessionReady: Promise<any>;
  private sessionCreatedResolve;
  private sessionCreatedReject;
  private sessionReadyResolve;
  private sessionReadyReject;
  private sessionReadyFailed;
  private catalogsLoaded;
  private companyCode;

  private jsdoSettings;

  constructor(private settingsService: SettingsService,
              private sessionService: SessionService,
              private router: Router,
              private snackbar: MatSnackBar,
              private connectionService: ConnectionService,
              private http: HttpClient ) {
    this.sessionCreated = new Promise((resolve, reject) => {
      this.sessionCreatedResolve = resolve;
      this.sessionCreatedReject = reject;
    });

    this._sessionReady = new Promise((resolve, reject) => {
      this.sessionReadyResolve = resolve;
      this.sessionReadyReject = reject;
    });

    this.addFilterPlugin();

    this.sessionService.company$.subscribe(company => {
      if (company) {
        if (this.jsdoSession) {
          window.location.assign(window['initialHref']);
        } else {
          /* Check if catalog is on 117 location in WAR */
          const connectionName = this.sessionService.company;
          const connection = this.connectionService.getConnection(connectionName);
          // tslint:disable-next-line:max-line-length
          if (connectionName === 'POC' || connectionName === 'DFT' || connectionName === 'DFT-TEST' || connectionName === 'SOFTPAK' || connectionName === 'SOFTPAK-local') {
            this.createSession(true);
          } else {
            const url = connection.serviceURI + 'static/DpApp.json';
            this.http.get(url)
            .pipe(catchError((err, caught) => {
              return from(['old']);
            }))
            .subscribe(response => {
              console.log('the response: ', response);
              if (response === 'old') {
                this.createSession(false);
              } else {
                this.createSession(true);
              }
            });
          }
        }
      }
    });
  }

  createSession(location117) {

    const connectionName = this.sessionService.company;
    const connection = this.connectionService.getConnection(connectionName);
    this.companyCode = connection.companycode;

    let catalogLoc;
    if (location117) {
      catalogLoc = 'static/DpApp.json';
    } else {
      catalogLoc = 'static/mobile/DpApp.json';
    }

    this.jsdoSettings = {
      serviceURI:  connection.serviceURI,
      catalogURIs: connection.serviceURI + catalogLoc,
      authenticationModel: 'form'
    };

    progress.util.jsdoSettingsProcessor(this.jsdoSettings);
    this.jsdoSession = new progress.data.JSDOSession(this.jsdoSettings);

    this.login();
  }

  loginAnonymous() {
    return new Promise((resolve, reject) => {
      this.jsdoLogin('anonymous', 'softpak').then(() => {
        resolve();
      }, () => reject());
    });
  }

  login(login?, password?) {

    if (!login) {
      login = this.settingsService.getCompanySetting('login');
      password = this.settingsService.getCompanySetting('password');
    } else {
      this.settingsService.setCompanySetting('login', login);
      this.settingsService.setCompanySetting('password', password);
    }

    return new Promise((resolve, reject) => {
      if (!login) {
        this.loginAnonymous();
        resolve();
      } else {
        this.jsdoLogin(login, password).then(() => {
          resolve();
        }, (result) => {
          this.loginAnonymous();
          reject(result);
        });
      }
    });
  }

  logout() {
    this.jsdoLogout().then(() => {
      this.jsdoLogin('anonymous', 'softpak');
    });
  }

  jsdoLogin(login, password) {
    this.snackbar.open('Logging in to server', '', {});

    // Zucht....
    login = login.replace('@', '<at>');

    // Add companycode
    const backendLogin = login + '|' + this.companyCode;

    return new Promise((resolve, reject) => {

      this.jsdoLogout().then( () => {
        this.jsdoSession.login(backendLogin, password).done(() => {
          if (!this.catalogsLoaded) {
            this.loadCatalogs();
          }
          this.snackbar.open('Connected to server', '', {duration: 1000});
          this.userSource.next(login);
          resolve();
        }).fail((session, result, err) => {
          console.log(err);
          if (result === progress.data.Session.AUTHENTICATION_FAILURE) {
            this.snackbar.open('Authentication failed', '', {duration: 1000});
          } else if (result === progress.data.Session.GENERAL_FAILURE) {
            this.snackbar.open('Connection to server failed', '', {duration: 1000});
          }
          this.sessionReadyFailed = true;
          reject(result);
        });
      });

    });
  }

  jsdoLogout() {
    return new Promise((resolve, reject) => {
      if (this.jsdoSession.connected && this.jsdoSession.userName) {
        this.jsdoSession.logout().done(() => {
          this.userSource.next('');
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  get sessionReady(): Promise<any> {
    // console.log('SessionReadyFailed', this.sessionReadyFailed);
    if (this.sessionReadyFailed) {
      this.sessionReadyFailed = null;
      this.login();
    }
    return this._sessionReady;
  }


  loadCatalogs() {
    this.catalogsLoaded = true;
    this.jsdoSession.addCatalog(this.jsdoSettings.catalogURIs).done(() => {
          this.sessionReadyResolve();
    }).fail(() => {
      this.sessionReadyFailed = true;
      // this.sessionReadyReject('addcatalog NOK');
    });

  }



  addFilterPlugin() {

    progress.data.PluginManager.addPlugin('MYJFP', {
      requestMapping: function(jsdo, params, info) {
        let sortFields,
        field,
        ablFilter,
        properties,
        capabilities;
        const reqCapabilities = 'ablFilter,top,skip,id,orderBy';

        if (info.operation === 'read') {
          properties = jsdo.getMethodProperties(info.operation);
          capabilities = properties.capabilities;
          if (capabilities) {
            capabilities = capabilities.replace(/\s/g, '');
          }
          if (capabilities !== reqCapabilities) {
            throw new Error('JSDO: Capabilities property must be set to \'' + reqCapabilities + '\'.');
          }
          if (params.orderBy) {
            sortFields = '';
            for (let i = 0; i < params.orderBy.length; i += 1) {
              field = params.orderBy[i].field;
              if (params.orderBy[i].dir === 'desc') {
                field += ' DESC';
              }
              sortFields += field;
              if (i < params.orderBy.length - 1) {
                sortFields += ',';
              }
            }
          }

          if (params.filter) {
            if (jsdo._defaultTableRef && params.tableRef === undefined) {
              params.tableRef = jsdo._defaultTableRef._name;
            }
            if (params.tableRef) {
              ablFilter = progress.util._convertToABLWhereString(
                jsdo._buffers[params.tableRef], params.filter);
            } else {
              throw new Error(progress.msg.getMsgText('jsdoMSG044', 'fill()', 'params', 'tableRef'));
            }
          }

          const filter = {
            ablFilter: ablFilter,
            orderBy: sortFields,
            skip: params.skip,
            top: params.top
            };

          // console.log("DEBUG: " + jsdo.mydata);
          if (jsdo.mydata) {
            for (const prop in jsdo.mydata) {
              if (jsdo.mydata.hasOwnProperty(prop)) {
                filter[prop] = jsdo.mydata[prop];
              }
            }
          }

          const stringFilter = JSON.stringify(filter);

          params = {filter: stringFilter};
        }
        return params;
      }
    });

  }
}

