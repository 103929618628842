import * as accessRightsActions from '../store/access-rights/access-rights.actions';
import * as fromAccessRights from '../store/access-rights/access-rights.reducer';
import * as fromRoot from '../store/index';
import {
  ACCESS_CODE_ESTIMATE,
  ACCESS_CODE_INSPECTION,
  ACCESS_CODE_PHOTO,
  ACCESS_CODE_REDELIVERY
  } from '../softpak/access-rights/access-rights.const';
import { ActivatedRoute } from '@angular/router';
import { AttachmentComponent } from '../attachment/attachment.component';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild
  } from '@angular/core';
import { ContainerInfoService } from '../services/jsdo-data/container-info.service';
import { DetailBlockStatus } from '../softpak/detail-block/detail-block-status.enum';
import { DevicePushService } from '../services/jsdo-data/device-push.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from '../services/navigation.service';
import { select, Store } from '@ngrx/store';
import { SettingsService } from '../services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-container-info',
  templateUrl: './container-info.component.html',
  styleUrls: ['./container-info.component.scss']
})
export class ContainerInfoComponent implements OnDestroy, OnInit {
  private readonly subscriptions: {
    accessRights?: Subscription;
  } = {};
  private accessRights: string[];
  container: any;
  registrationInProgress: Boolean;
  registeredForPush: Boolean;
  public get hasAccessEstimate(): boolean { return this.accessRights.includes(ACCESS_CODE_ESTIMATE); }
  public get hasAccessInspection(): boolean { return this.accessRights.includes(ACCESS_CODE_INSPECTION); }
  public get hasAccessPhoto(): boolean { return this.accessRights.includes(ACCESS_CODE_PHOTO); }
  public get hasAccessRedelivery(): boolean { return this.accessRights.includes(ACCESS_CODE_REDELIVERY); }

  @ViewChild('appAtt', { static: false }) private appAtt: AttachmentComponent;

  constructor(private readonly appStore: Store<fromRoot.AppState>,
              private readonly route: ActivatedRoute,
              private readonly containerService: ContainerInfoService,
              private readonly navigationService: NavigationService,
              private readonly devicePushService: DevicePushService,
              private readonly settingsService: SettingsService,
              public readonly snackBar: MatSnackBar) { }

  ngOnInit() {
    this.container = this.route.snapshot.data['container'];
    this.registeredForPush = this.devicePushService.isContainerPushRegistered(this.container.seqnr);

    this.navigationService.setLabel('Container info');

    this.appStore.dispatch(accessRightsActions.CHECK_ACCESS_RIGHTS({ key: 'photo' }));
    this.subscribeToAccessRights();
  }

  ngOnDestroy() {
    this.unsubscribeFromSubs();
  }

  onSubscribe() {
    // set sync icon
    this.registrationInProgress = true;
    this.devicePushService.registerContainerPush(this.container.seqnr).then(() => {
      this.registrationInProgress = false;
      this.registeredForPush = true;
    });
  }

  subscribeToAccessRights() {
    this.subscriptions.accessRights = this.appStore.pipe(select(fromAccessRights.selectAccessRights)).subscribe(accessRights => {
      this.accessRights = accessRights;
    });
  }

  onTakePicture() {
    this.settingsService.addPhoto().then(imageData => {
      this.snackBar.open('Uploading image!', '', {duration: 750});
      this.containerService.addPhoto(this.container.seqnr, imageData).then(() => {
        this.snackBar.open('Image added!', '', {duration: 750});
        this.appAtt.ngOnInit();
      });
    }, () => {
      this.snackBar.open('Adding picture failed!', '', {duration: 1000});
    });
  }

  convertStatusCode(color: string): DetailBlockStatus {
    switch (color) {
      case 'BLUE': return DetailBlockStatus.AWAITING;
      case 'GREEN': return DetailBlockStatus.OK;
      case 'GREY': return DetailBlockStatus.CLOSED;
      case 'RED': return DetailBlockStatus.NOK;
    }
  }

  private unsubscribeFromSubs(): void {
    for (const subKey in this.subscriptions) {
      if (this.subscriptions[subKey]) {
        const subscription = this.subscriptions[subKey] as Subscription;
        subscription.unsubscribe();
      }
    }
  }

}
