import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as bookingStatsActions from '../../../store/booking-stats/booking-stats.actions';
import * as fromBookingStats from '../../../store/booking-stats/booking-stats.reducer';
import * as fromSettings from '../../../store/settings/settings.reducer';
import * as fromRoot from '../../../store/index';
import { Subscription } from 'rxjs/internal/Subscription';
import { BookingStatsDay } from '../../models/booking-stats-day.interface';
import { NavigationService } from 'src/app/services/navigation.service';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';
import { BookingStatsFiltersInterface } from '../../models/booking-stats-filters.interface';
import * as moment from 'moment';
import { BookingStatsPeriod } from '../../models/booking-stats-period.interface';
import { GraphService } from '../../../softpak/charts/services/graph.service';
import { unsubscribeSubscriptions } from 'src/app/softpak/functions/unsubscribe-subscriptions';

@Component({
  selector: 'app-booking-stats-home',
  templateUrl: './booking-stats-home.component.html',
  styleUrls: ['./booking-stats-home.component.scss']
})
export class BookingStatsHomeComponent implements OnDestroy, OnInit {
  public currentDepot: string;
  public bookingStatsDays: BookingStatsDay[];
  public bookingStatsLoading: boolean;
  public bookingStatsReceiveTime: string;
  public filters: BookingStatsFiltersInterface;
  public showFilters = false;
  private readonly subscriptions: {
    selectBookingStatsData?: Subscription;
    selectBookingStatsDepot?: Subscription;
    selectBookingStatsFilters?: Subscription;
  } = {};
  public highestValue: number;
  public stepSize: number;
  public numberOfSteps: number;
  public stepLines: number[];
  public availableDepots: { depotCode: string; depotName: string; }[] = [];

  constructor(private readonly appStore: Store<fromRoot.AppState>,
              private navigationService: NavigationService) {
    this.navigationService.setLabel('Appointment Stats');
    this.navigationService.enableBackButton(true);
  }

  public ngOnInit(): void {
    this.subscribeToBookingStats();
    this.subscribeToFavouriteDepot();
  }

  public ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

  private subscribeToBookingStats(): void {
    this.subscriptions.selectBookingStatsData = this.appStore.pipe(select(fromBookingStats.selectAll))
      .subscribe((all) => {
        this.availableDepots = this.fillDepots(all.bookingStatsData as BookingStatsDay[]);
        this.bookingStatsDays = all.bookingStatsData as BookingStatsDay[];
        this.bookingStatsLoading = all.bookingStatsLoading;
        this.bookingStatsReceiveTime = all.bookingStatsReceiveTime;
        this.filters = all.bookingStatsFilters;
        this.generateGraphs();
      });
  }

  private subscribeToFavouriteDepot(): void {
    this.currentDepot = DepotSettingsService.getFavouriteDepot();
    this.subscriptions.selectBookingStatsDepot = this.appStore.pipe(select(fromSettings.selectDepot))
      .subscribe(() => {
        this.currentDepot = DepotSettingsService.getFavouriteDepot();
      });
  }

  public onClickRefresh(): void {
    this.appStore.dispatch(bookingStatsActions.LOAD_BOOKING_STATS({}));
  }

  public onToggleFilters(showFilters: boolean): void {
    this.showFilters = showFilters;
  }

  public dayIsTodayOrInFuture(bookingStatsDay: BookingStatsDay): boolean {
    return moment(bookingStatsDay.periods[0].dateTimeSlot).isAfter(moment().startOf('day').subtract(1, 'second'));
  }

  private generateGraphs(): void {
    const totals: number[] = [];
    if (this.bookingStatsDays) {
      this.bookingStatsDays.forEach(bookingStatsDay => {
        bookingStatsDay.periods.forEach((period: BookingStatsPeriod) => {
          totals.push(
            period.dropOffEmpty * (this.filters.showExportEmpty ? 1 : 0) +
            period.dropOffFull * (this.filters.showExportFull ? 1 : 0) +
            period.pickupEmpty * (this.filters.showImportEmpty ? 1 : 0) +
            period.pickupFull * (this.filters.showImportFull ? 1 : 0)
          );
        });
      });
      this.highestValue = GraphService.determineScaleMaximum(totals);
      this.stepSize = GraphService.determineStepSize(this.highestValue, this.highestValue, 4);
      this.numberOfSteps = GraphService.determineNumberOfSteps(this.highestValue, this.highestValue, 4);
      this.stepLines = GraphService.generateStepLines(this.numberOfSteps);
    }
  }

  private fillDepots(bookingStatsData: any[]): { depotCode: string; depotName: string; }[] {
    const depots = [];
    if (bookingStatsData) {
      bookingStatsData.forEach(data => {
        if (depots.filter(item => item.depotCode === data.periods[0].depotCode).length === 0) {
          depots.push({ depotCode: data.periods[0].depotCode, depotName: data.periods[0].depotCode });
        }
      });
      return depots;
    }
  }
}
