import * as actions from './access-rights.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContainerInfoService } from 'src/app/services/jsdo-data/container-info.service';

@Injectable()
export class AccessRightsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly containerService: ContainerInfoService
    ) {}

    checkAccessRights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.CHECK_ACCESS_RIGHTS),
            mergeMap(action => from(this.containerService.hasAccess(action.key))
                .pipe(
                    map(hasAccess => {
                        if (hasAccess) {
                            return actions.ADD_SINGLE({ key: action.key });
                        } else {
                            return actions.REMOVE_SINGLE({ key: action.key });
                        }
                    }),
                    catchError((err) => of(this._errorHandler(err)))
                )
        ))
    );

    private _errorHandler(payload: any) {
        console.log(payload);
        return actions.ERROR({ errorType: 'UNKNOWN', errorMessage: 'An unknown error happened' });
    }
}
