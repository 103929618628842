import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefreshBarComponent } from './refresh-bar.component';
import { ButtonModuleV1 } from 'softpak-frontend-button';

@NgModule({
  declarations: [
    RefreshBarComponent
  ],
  imports: [
    ButtonModuleV1,
    CommonModule
  ],
  exports: [
    RefreshBarComponent
  ]
})
export class RefreshBarModule { }
