import { Component, OnInit, OnDestroy } from '@angular/core';
import { BookingStatsBar } from 'src/app/booking-stats/models/booking-stats-bar.class';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../store/index';
import * as fromEquipmentStats from '../../../store/equipment-stats/equipment-stats.reducer';
import * as fromEquipmentStatsDetail from '../../../store/equipment-stats-detail/equipment-stats-detail.reducer';
import * as fromSettings from '../../../store/settings/settings.reducer';
import { DepotSettingsService } from 'src/app/services/settings-depot.service';
import { GraphService } from 'src/app/softpak/charts/services/graph.service';
import { ActivatedRoute } from '@angular/router';
import { EquipmentStatsFiltersInterface } from '../../models/equipment-stats-filters.interface';
import { unsubscribeSubscriptions } from 'src/app/softpak/functions/unsubscribe-subscriptions';
import { ImportExportFullEmptyBarsService } from 'src/app/softpak/charts/services/import-export-full-empty-bars.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-equipment-stats-single-graph',
  templateUrl: './equipment-stats-single-graph.component.html',
  styleUrls: ['./equipment-stats-single-graph.component.scss']
})
export class EquipmentStatsSingleGraphComponent implements OnDestroy, OnInit {
  public selected: {
    depot?: string;
    equipment?: string;
    equipmentGroup?: string;
  } = {};

  public chart: {
    bars: BookingStatsBar[];
    labels: string[];
    numberOfSteps?: number;
    stepSize?: number;
    stepLines: number[];
  } = {
    bars: [],
    labels: [],
    stepLines: [],
  };

  private filters: EquipmentStatsFiltersInterface;
  public statsPerDays: any;
  private statsForAllDepots: any[] = [];

  private readonly subscriptions: {
    selectAll?: Subscription;
    selectDepot?: Subscription;
  } = {};

  constructor(
    private readonly appStore: Store<fromRoot.AppState>,
    private readonly activtatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    ) {
      this.activtatedRoute.params.subscribe( params => {
        this.selected.equipment = params['equipment'];
        this.selected.equipmentGroup = params['equipmentGroup'];
        this.navigationService.setLabel(this.selected.equipmentGroup + ' / ' + this.selected.equipment);
      });
    }

  ngOnInit(): void {
    this.subscribeToDepot();
    this.subscribeToStats();
    this.subscribeToFilters();
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subscriptions);
  }

  // Listen to filters
  private subscribeToFilters(): void {
    this.subscriptions.selectAll = this.appStore.pipe(select(fromEquipmentStats.selectAll)).subscribe((all) => {
      this.filters = all.filters;
      this.recalculate();
    });
  }

  // Listen to depot
  private subscribeToDepot(): void {
    this.selected.depot = DepotSettingsService.getFavouriteDepot();
    this.subscriptions.selectDepot = this.appStore.pipe(select(fromSettings.selectDepot)).subscribe(() => {
      this.selected.depot = DepotSettingsService.getFavouriteDepot();
      this.recalculate();
    });
  }

  // Listen to stats
  private subscribeToStats(): void {
    this.subscriptions.selectAll = this.appStore.pipe(select(fromEquipmentStatsDetail.selectAll)).subscribe((all) => {
      this.statsForAllDepots = all.data;
      this.recalculate();
    });
  }

  // Recalculate if filter, data or depot changes.
  private recalculate(): void {
    this.chart.bars = ImportExportFullEmptyBarsService.determineBars(
      this.filters ? this.filters.splitFullEmpty : true,
      this.filters ? this.filters.splitImportExport : true,
    );
    this.statsPerDays = this.filterStats(this.statsForAllDepots);
    this.calculateChartParameters();
  }

  private calculateChartParameters(): void {
    const highestValue = Math.max.apply(Math, this.statsPerDays.map(day => {
      return Math.max.apply(Math, day.convertedValues.map(statsForTimeslot => {
        return statsForTimeslot.reduce((sum, column) => sum + column.value, 0);
      }));
    }));
    this.chart.stepSize = GraphService.determineStepSize(highestValue, highestValue, 4);
    this.chart.numberOfSteps = GraphService.determineNumberOfSteps(highestValue, highestValue, 4);
    this.chart.stepLines = GraphService.generateStepLines(this.chart.numberOfSteps);
  }

  private filterStats(statsForDepots: any[]): any[] {
    // 1. Filter Depot
    const results = statsForDepots.filter(statsForDepot => {
      return this.selected.depot === statsForDepot[0][0].unconvertedValues[0].depotCode;
    });
    let stats = results.length === 1 ? results[0] : [];
    // 2. Filter Equipment Group
    stats = stats.map(statsForDepot => {
      return statsForDepot.filter(equipmentGroup => {
        return equipmentGroup.unconvertedValues[0].equipmentGroupcode.toUpperCase() === this.selected.equipmentGroup.toUpperCase();
      });
    }).filter(x => {
      return x.length > 0;
    });
    // 3. Filter Equipment
    stats = stats.map(equipmentGroup => {
      return equipmentGroup.map(day => {
        let equipmentIndex: number;
        const equipments = day.equipments.filter((equipment, index) => {
          if (equipment === this.selected.equipment) {
            equipmentIndex = index;
          }
          return equipment === this.selected.equipment;
        });
        return {
          equipments,
          timeslots: day.timeslots,
          unconvertedValues: day.unconvertedValues.filter(item => {
            return item.equipmentCode === this.selected.equipment;
          }),
          convertedValues: ImportExportFullEmptyBarsService.convertRowsToValues(
            equipmentIndex !== undefined ? day.values.map(value => {
              return value.filter((v, index) => index === equipmentIndex)[0];
            }) : day.values.map(() => null), this.chart.bars),
          bars: ImportExportFullEmptyBarsService.determineBars(
            this.filters ? this.filters.splitFullEmpty : true,
            this.filters ? this.filters.splitImportExport : true,
          ),
          date: day.date
        };
      });
    });
    return stats[0] ? stats[0] : [];
  }
}
