import { Component, Input, OnInit } from '@angular/core';
import { ChartTotalWithIcon } from '../models/chart-total-with-icon';
import { ChartColumnInterface } from '../models/chart-column.interface';

@Component({
  selector: 'sp-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() columns: ChartColumnInterface[][];
  @Input() labels: string[];
  @Input() numberOfSteps: number;
  @Input() stepSize: number;
  @Input() stepLines: number[];
  @Input() totals: ChartTotalWithIcon[][] = [];

  constructor() { }

  ngOnInit() {
  }
}
