import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { TimeslotService } from '../services/jsdo-data/timeslot.service';
import { NavigationService } from '../services/navigation.service';
import { JsdoSessionService } from '../services/jsdo-session.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-timeslots',
  templateUrl: './timeslots.component.html',
  styleUrls: ['./timeslots.component.scss']
})
export class TimeslotsComponent implements OnInit {

  public timeslots: any;
  constructor(private timeslotService: TimeslotService,
              private navigationService: NavigationService,
              private router: Router,
              private route: ActivatedRoute,
              private jsdoSessionService: JsdoSessionService,
              private dialogService: DialogService ) { }

  ngOnInit() {
    this.navigationService.setLabel('Timeslots');
    this.navigationService.enableBackButton(true);

    this.route.params.subscribe((params: Params) => {
      this.timeslotService.getTimeSlots(params['depotcode']).then(timeslots => {
        this.timeslots = timeslots;
      });
    });

    /* this.timeslotService.getTimeSlots().then(timeslots => {
      this.timeslots = timeslots;
    }); */

  }

  gotoTimeslotDetails(timeslotSeqnr) {
    this.router.navigate(['timeslotDetails', timeslotSeqnr]);
  }

}


